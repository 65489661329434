import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Modal,
	styled,
	TextField,
	Typography,
} from '@mui/material';

import {
	deleteVendorExhibit,
	getVendorExhibit,
	postChangeBooth,
} from '../services/VendorExhibitsService';
import AddressDisplay from '../../utils/AddressDisplay';
import { Edit, Save } from '@mui/icons-material';
import { modalContainer } from '../../form/components/ModalContainer.styles';
import ContactDisplay from '../../utils/ContactDisplay';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { Link } from 'react-router-dom';

const Entry = styled(Box)({
	height: 40,
	display: 'flex',
	alignItems: 'center',
});

export default ({ open, exhibitId, onClose, refetchExhibits }) => {
	const [exhibit, setExhibit] = useState();
	const [editBoothNumber, setEditBoothNumber] = useState(false);
	const [boothNumber, setBoothNumber] = useState();
	const [showRemoveDialog, setShowRemoveDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	console.log('exhibit', exhibit);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(async () => {
		if (exhibitId === null || exhibitId === undefined) return;
		const res = await getVendorExhibit(exhibitId);
		setExhibit(res);
		setBoothNumber(res.boothNumber);
	}, [exhibitId, setExhibit, setBoothNumber]);

	useEffect(() => {
		fetchData().then();
	}, [exhibitId, fetchData]);
	let content = <CircularProgress />;

	const handleRemoveExhibitor = useCallback(async () => {
		setLoading(true);
		const result = await deleteVendorExhibit(exhibitId);
		if (result.status === 'success') {
			showSnackbar({
				message: 'Exhibitor removed!',
				type: 'success',
			});
			refetchExhibits();
			onClose();
		} else
			showSnackbar({
				message:
					'There was an error removing exhibitor, please try again later',
				type: 'error',
			});
		setLoading(false);
		setShowRemoveDialog(false);
	}, [exhibitId, setLoading, showSnackbar, refetchExhibits, onClose]);

	if (exhibit) {
		const changeBooth = async boothNumber => {
			const result = await postChangeBooth(exhibitId, boothNumber);
			if (result.status === 'success') {
				await fetchData();
				showSnackbar({
					message: 'Exhibitor updated!',
					type: 'success',
				});
				setEditBoothNumber(false);
				refetchExhibits();
			} else {
				showSnackbar({
					message:
						'There was an error updating booth number, please try again later',
					type: 'error',
				});
			}
		};

		const boothElement = (
			<Entry>
				Booth:
				{editBoothNumber ? (
					<>
						<TextField
							value={boothNumber}
							sx={{
								width: 25,
								marginLeft: 1,
							}}
							onChange={event => setBoothNumber(event.target.value)}
							variant="standard"
							size="small"
						/>
						<Save onClick={() => changeBooth(boothNumber)} />
					</>
				) : (
					<Box
						sx={{
							marginLeft: 1,
							display: 'flex',
							alignItems: 'center',
						}}>
						{exhibit.boothNumber}
						<Edit
							fontSize={'small'}
							sx={{
								marginLeft: 2,
							}}
							onClick={() => {
								setEditBoothNumber(true);
							}}
						/>
					</Box>
				)}
			</Entry>
		);

		content = (
			<>
				<Typography variant="h6" component="h2">
					{exhibit.vendor.name}
				</Typography>
				{boothElement}
				<Divider />
				<Entry>Confirmation: {exhibit.confirmationCode}</Entry>
				<Divider />
				<Entry>Email: {exhibit.exhibitInfo?.email}</Entry>
				<Divider />
				<Entry>Phone: {exhibit.exhibitInfo?.phone}</Entry>
				<Divider />

				{exhibit.exhibitInfo?.address && (
					<AddressDisplay address={exhibit.exhibitInfo.address} />
				)}
				<Divider />

				{exhibit.vendor.contact && (
					<>
						<Typography>Public Contact:</Typography>
						<ContactDisplay contact={exhibit.vendor.contact} />
					</>
				)}
				<Divider />
				<Box mt={1} display="flex" justifyContent="space-between">
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							setShowRemoveDialog(true);
						}}>
						Remove Exhibitor
					</Button>
					{exhibit.exhibitChannel && (
						<Button
							variant="contained"
							component={Link}
							to={`/messages/${exhibit.exhibitChannel.messageChannelId}`}>
							Message
						</Button>
					)}
				</Box>
			</>
		);
	}

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box sx={modalContainer}>
					<Card>
						<CardContent>{content}</CardContent>
					</Card>
				</Box>
			</Modal>
			<Dialog
				open={showRemoveDialog}
				onClose={() => {
					setShowRemoveDialog(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Remove Vendor</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Vendor will be notified they have been removed if they have an
						account
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading}>Cancel</Button>
					<Button onClick={handleRemoveExhibitor} disabled={loading}>
						Remove
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
