import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
	conBrandButton,
	formContainer,
} from '../../cons/components/ConBrandForm.styles';
import FormTextField from '../../form/FormTextField';
import { createConEventAddOn } from '../services/ConService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({
	name: Yup.string().required('name is required'),
});

const ConEventAddOnForm = () => {
	const { id: conEventId, conBrandId } = useParams();

	const { showSnackbar } = useContext(SnackbarContext);
	const navigate = useNavigate();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: { conEventId },
		resolver: yupResolver(formSchema),
	});
	const handleCreateAddOn = async data => {
		try {
			await createConEventAddOn(data);
			navigate(`/con-brand/${conBrandId}/con-event/${conEventId}`);
			showSnackbar({ message: 'Addon created!', type: 'success' });
		} catch (e) {
			showSnackbar({
				message: 'Failed creating addon, please try again later',
				type: 'error',
			});
		}
	};

	return (
		<Card sx={formContainer}>
			<h1>Create add on</h1>
			<form onSubmit={handleSubmit(handleCreateAddOn)}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Name *"
								name="name"
								error={!!errors.name}
								helperText={errors.name?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField control={control} label="price" name="price" />
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="description"
								name="description"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField control={control} label="limit" name="limit" />
						</Grid>
						<Grid item xs={12}>
							<Button type="submit" variant="contained" sx={conBrandButton}>
								Create Add on
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Card>
	);
};

export default ConEventAddOnForm;
