import { decamelizeKeys } from 'humps';
import { authDelete } from '../../auth/helpers/AuthRequests';

export const postEmailUnsubscribe = async (token, key) => {
	await fetch('/api/preferences/email_unsubscribe', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(
			decamelizeKeys({
				token,
				preference_key: key,
			}),
		),
	});
};

export const postDeleteUser = async () => {
	return await authDelete('/api/users/', {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	});
};
