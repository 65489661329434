import React from 'react';
import { Typography } from '@material-ui/core';

const AddressDisplay = ({ address }) => {
	return (
		<>
			<Typography>
				{address.street1}, {address.street2}
			</Typography>
			<Typography component="span" variant="body1">
				{address.city}, {address.state} {address.postalCode}
			</Typography>
		</>
	);
};

export default AddressDisplay;
