import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import PageContainer from '../../common/components/PageContainer';
import ConBrandSubNav from '../components/ConBrandSubNav';
import { getVenue } from '../services/VenuesService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import VenueMapImage from '../components/VenueMapImage';
import VenueInfoCard from '../components/VenueInfoCard';

const VenuePage = () => {
	const { venueId, conBrandId } = useParams();

	const [venue, setVenue] = useState();
	const [loading, setLoading] = useState(true);

	const { showSnackbar } = useContext(SnackbarContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				const resp = await getVenue(venueId);
				if (resp.status === 'success') {
					setVenue(resp.venue);
					setLoading(false);
				} else {
					showSnackbar({ message: 'Try again later', type: 'error' });
				}
			} catch (e) {
				showSnackbar({ message: 'Try again later', type: 'error' });
			}
		};
		fetchData().then();
	}, [venueId, showSnackbar]);
	if (!venue) return null;

	return (
		<PageContainer title={venue.name}>
			<ConBrandSubNav value="venues" conBrandId={conBrandId} />
			<Container>
				{loading ? (
					<CircularProgress />
				) : (
					<Grid container spacing={2}>
						<Typography variant="h2">{venue.name}</Typography>
						<Grid item xs={12}>
							<VenueInfoCard venue={venue} />
						</Grid>
						<Grid item xs={12}>
							<VenueMapImage
								venueId={venueId}
								existingImage={venue.mapImage}
								setVenue={setVenue}
							/>
						</Grid>
					</Grid>
				)}
			</Container>
		</PageContainer>
	);
};

export default VenuePage;
