import React, { useContext, useEffect, useState } from 'react';
import { Card, CardContent, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import VendorPublicInfoForm from '../components/VendorPublicInfoForm';
import VendorSubNav from '../components/VendorSubNav';
import VendorContext from '../context/VendorContext';

export default () => {
	const { vendorId } = useParams();
	const [vendor, setVendor] = useState();

	const { vendors } = useContext(VendorContext);

	useEffect(() => {
		const foundVendor = vendors.find(v => v.id.toString() === vendorId);
		setVendor(foundVendor);
	}, [vendorId, vendors]);

	const content = (
		<Card>
			<CardContent>
				<h1>Public Info</h1>

				<VendorPublicInfoForm vendor={vendor} editing />
			</CardContent>
		</Card>
	);

	return (
		<PageContainer title={`Edit ${vendor?.name || 'Vendor'} Public Info`}>
			<VendorSubNav value="editPublicInfo" vendorId={vendorId} />
			<Container>{content}</Container>
		</PageContainer>
	);
};
