import humps, { camelizeKeys, decamelizeKeys } from 'humps';

import { getConfig } from '../../../config/config';

import AwaitLock from 'await-lock';
import { apiGet } from '../../utils/Requests';
const refreshLock = new AwaitLock();

const config = getConfig();
export const authGet = async (
	url,
	{ forceAccessToken, searchParams, allowUnauthed } = {},
) => {
	await refreshLock.acquireAsync();
	refreshLock.release();

	const persistedAccessToken = localStorage.getItem('accessToken');

	const accessToken = forceAccessToken || persistedAccessToken;

	if (allowUnauthed && !accessToken) {
		return await apiGet(url, { searchParams });
	}

	if (!accessToken) {
		console.error('tried to send auth get without tokens');
		return;
	}
	if (searchParams) {
		for (let key in searchParams) {
			if (searchParams[key] === undefined) delete searchParams[key];
		}
	}

	const params = new URLSearchParams(decamelizeKeys(searchParams));
	const finalUrl = searchParams
		? `${config.apiHost}${url}?${params.toString()}`
		: `${config.apiHost}${url}`;

	const resp = await fetch(finalUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${accessToken}`,
		},
	});

	if (resp.status === 401) {
		//refresh
		try {
			await refreshAccessToken();
			return authGet(url, {
				searchParams,
			});
		} catch (e) {
			console.error('authGet refresh error', e);
			throw new Error('could not refresh token');
		}
	}

	const json = await resp.json();
	const camelizedJson = camelizeKeys(json);

	if (!resp.ok) {
		throw new Error(camelizedJson.statusText);
	}

	return camelizedJson;
};

export const authPost = async (url, data, { forceAccessToken } = {}) => {
	await refreshLock.acquireAsync();
	refreshLock.release();

	const persistedAccessToken = localStorage.getItem('accessToken');

	const accessToken = forceAccessToken || persistedAccessToken;

	if (!accessToken) {
		console.error('tried to send auth post without tokens');
		return;
	}

	const resp = await fetch(`${config.apiHost}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${accessToken}`,
		},
		body: JSON.stringify(humps.decamelizeKeys(data)),
	});

	if (resp.status === 401) {
		//refresh
		try {
			await refreshAccessToken();
			return authPost(url, data);
		} catch (e) {
			throw new Error('auth post refresh error', e);
		}
	}

	const json = await resp.json();
	const camelizedJson = camelizeKeys(json);

	if (!resp.ok) throw new Error(camelizedJson.statusText);

	return camelizedJson;
};

export const authPut = async (url, data, { forceAccessToken } = {}) => {
	await refreshLock.acquireAsync();
	refreshLock.release();

	const persistedAccessToken = localStorage.getItem('accessToken');

	const accessToken = forceAccessToken || persistedAccessToken;

	if (!accessToken) {
		console.error('tried to send auth post without tokens');
		return;
	}

	const resp = await fetch(`${config.apiHost}${url}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${accessToken}`,
		},
		body: JSON.stringify(humps.decamelizeKeys(data)),
	});

	if (resp.status === 401) {
		//refresh
		try {
			await refreshAccessToken();
			return authPut(url, data);
		} catch (e) {
			throw new Error('auth post refresh error', e);
		}
	}

	const json = await resp.json();
	const camelizedJson = camelizeKeys(json);

	if (!resp.ok) throw new Error(camelizedJson.statusText);

	return camelizedJson;
};

export const authDelete = async (url, data = {}, { forceAccessToken } = {}) => {
	await refreshLock.acquireAsync();
	refreshLock.release();

	const persistedAccessToken = localStorage.getItem('accessToken');

	const accessToken = forceAccessToken || persistedAccessToken;

	if (!accessToken) {
		console.error('tried to send auth post without tokens');
		return;
	}

	const resp = await fetch(`${config.apiHost}${url}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${accessToken}`,
		},
		body: JSON.stringify(humps.decamelizeKeys(data)),
	});

	if (resp.status === 401) {
		//refresh
		try {
			await refreshAccessToken();
			return authPost(url, data);
		} catch (e) {
			throw new Error('auth post refresh error', e);
		}
	}

	const json = await resp.json();
	const camelizedJson = camelizeKeys(json);

	if (!resp.ok) throw new Error(camelizedJson.statusText);

	return camelizedJson;
};

export const refreshAccessToken = async () => {
	await refreshLock.acquireAsync();
	const persistedAccessToken = localStorage.getItem('accessToken');

	const persistedRefreshToken = JSON.parse(
		localStorage.getItem('refreshToken'),
	);

	const resp = await fetch(`${config.apiHost}/api/refresh`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${persistedAccessToken}`,
		},
		body: JSON.stringify(
			humps.decamelizeKeys({
				accessToken: persistedAccessToken,
				refreshToken: persistedRefreshToken.cryptedToken,
			}),
		),
	});
	if (resp.status !== 200) {
		clearTokens();
		refreshLock.release();
		throw new Error('Invalid token');
	}
	const respJson = await resp.json();
	const { accessToken, refreshToken } = humps.camelizeKeys(respJson);
	saveTokens({ accessToken, refreshToken });
	refreshLock.release();
	return { accessToken, refreshToken };
};

export const saveTokens = ({ accessToken, refreshToken }) => {
	localStorage.setItem('accessToken', accessToken);
	localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
};

export const clearTokens = () => {
	localStorage.removeItem('accessToken');
	localStorage.removeItem('refreshToken');
};
