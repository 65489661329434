import { useContext, useEffect } from 'react';
import UserContext from '../contexts/UserContext';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

export default ({ children }) => {
	const { user } = useContext(UserContext);
	const location = useLocation();

	const navigate = useNavigate();
	useEffect(() => {
		if (!user) {
			navigate({
				pathname: '/login',
				search: `?${createSearchParams({
					goto: `${location.pathname}${location.search}`,
				})}`,
			});
		}
	}, [user, navigate, location.pathname, location.search]);

	return children;
};
