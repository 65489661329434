import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import ConContext from '../../../cons/contexts/ConContext';
import UpcomingConEventCard from '../../../cons/components/UpcomingConEventCard';
import NoConBrandCard from './NoConBrandCard';
import { getStripeStatus } from '../../../payments/services/StripeServices';
import SetUpPaymentsCard from './SetUpPaymentsCard';

export default () => {
	const [stripeStatus, setStripeStatus] = useState('ready');

	const fetchStripeData = useCallback(async () => {
		const { stripeStatus } = await getStripeStatus();
		setStripeStatus(stripeStatus);
	}, []);

	useEffect(() => {
		fetchStripeData().then();
	}, [fetchStripeData]);

	const { conBrands } = useContext(ConContext);
	return (
		<Grid container spacing={4}>
			{stripeStatus !== 'ready' && conBrands.length > 0 && (
				<Grid item xs={12}>
					<SetUpPaymentsCard />
				</Grid>
			)}
			{conBrands.length === 0 && (
				<Grid item xs={12}>
					{' '}
					<NoConBrandCard />
				</Grid>
			)}
			<Grid item xs={12}>
				<UpcomingConEventCard />
			</Grid>
		</Grid>
	);
};
