import React, { useState } from 'react';
import { Box, Button, Divider, TextField } from '@mui/material';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const NoteMessage = ({ sentAt, message, userName }) => {
	const sentDay = dayjs(sentAt);
	const displayDate =
		dayjs().diff(sentDay, 'day') >= 0
			? sentDay.format('MMM D YYYY, hh:mm a')
			: sentDay.fromNow();

	return (
		<Box>
			<Typography variant="subtitle2" gutterBottom>
				{userName}
			</Typography>
			<Typography>{message}</Typography>
			<Typography
				variant="caption"
				style={{
					color: 'gray',
				}}
				gutterBottom>
				{displayDate}
			</Typography>

			<Divider />
		</Box>
	);
};

export default ({
	vendorApplication: { notes, userNames },
	onAddNote,
	loading,
}) => {
	const [message, setMessage] = useState('');

	const messages = (notes || []).map(note => (
		<NoteMessage
			key={note.sentAt + Math.random()}
			userName={userNames.find(un => un.id === note.userId)?.name}
			sentAt={note.sentAt}
			message={note.message}
		/>
	));

	return (
		<Box>
			{/*<Typography variant="h6">Notes</Typography>*/}
			{messages}
			<Box display="flex" flexDirection="column" gap={1}>
				<TextField
					value={message}
					onChange={e => {
						setMessage(e.target.value);
					}}
					multiline
					minRows={2}
				/>
				<Button
					disabled={loading}
					variant="outlined"
					onClick={() => {
						if (message) onAddNote(message);
					}}>
					Add Note
				</Button>
			</Box>
		</Box>
	);
};
