import React, { useContext } from 'react';
import { CircularProgress, Container } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

import VendorContext from '../context/VendorContext';
import VendorForm from '../components/VendorForm';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const { vendors, loading } = useContext(VendorContext);
	const { id } = useParams();

	const vendor = vendors.find(v => v.id.toString() === id);
	if (!vendor) {
		if (loading) return null;
		return <Navigate to="/" />;
	}

	const content = loading ? (
		<CircularProgress />
	) : (
		<VendorForm vendor={vendor} editing />
	);
	return (
		<PageContainer title={`Edit ${vendor?.name || 'Vendor'}`}>
			<Container>{content}</Container>
		</PageContainer>
	);
};
