import { decamelizeKeys, camelizeKeys } from 'humps';

import { getConfig } from '../../../config/config';

const config = getConfig();

export const registerUser = async data => {
	const resp = await fetch(`${config.apiHost}/api/register`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(decamelizeKeys(data)),
	});
	if (resp.status !== 200) {
		throw new Error('register failed');
	}
	const json = await resp.json();

	if (json.status !== 'success') {
		throw new Error('register failed');
	}

	return camelizeKeys(json);
};

export const loginUser = async data => {
	const resp = await fetch(`${config.apiHost}/api/signin`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(decamelizeKeys(data)),
	});
	if (resp.status !== 200) {
		throw new Error('login failed');
	}
	const json = await resp.json();

	if (json.status !== 'success') {
		throw new Error('login failed');
	}

	return camelizeKeys(json);
};

export const postRequestPasswordReset = async email => {
	const resp = await fetch(`${config.apiHost}/api/request_password_reset`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(decamelizeKeys({ email })),
	});

	if (!resp.ok) return false;
	const json = await resp.json();

	if (json.status !== 'success') return false;

	return true;
};

export const postResetPassword = async data => {
	const resp = await fetch(`${config.apiHost}/api/reset_password`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(decamelizeKeys(data)),
	});

	if (!resp.ok) return false;
	const json = await resp.json();

	if (json.status !== 'success') return false;

	return true;
};
