import React, { useState } from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { Box, Grid, Typography } from '@mui/material';

import { getConfig } from '../../../config/config';
import SponsorModal from './SponsorModal';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

const SponsorItem = ({ sponsor, onClick }) => {
	const img = cld.image(sponsor?.image?.publicId).format('png');

	return (
		<Grid item xs={6}>
			<Box
				onClick={onClick}
				display="flex"
				flexDirection="column"
				justifyContent="center">
				<Typography variant="h6" textAlign="center">
					{sponsor.name}
				</Typography>
				<AdvancedImage
					cldImg={img}
					style={{
						objectFit: 'contain',
						height: 200,
					}}
				/>
			</Box>
		</Grid>
	);
};

export default ({ sponsors }) => {
	const [selectedSponsor, setSelectedSponsor] = useState();

	const items = sponsors.sponsors.map(sponsor => (
		<SponsorItem
			key={sponsor.id}
			sponsor={sponsor}
			onClick={() => setSelectedSponsor(sponsor)}
		/>
	));

	return (
		<Box pt={2} mb={3}>
			<Typography variant="h6" gutterBottom>
				Supported By
			</Typography>
			<Grid container spacing={2}>
				{items}
				<SponsorModal
					sponsor={selectedSponsor}
					onClose={() => setSelectedSponsor(null)}
				/>
			</Grid>
		</Box>
	);
};
