import { authGet, authPost } from '../../auth/helpers/AuthRequests';
import { getConfig } from '../../../config/config';

const config = getConfig();

export const createVenue = async (conBrandId, data) => {
	const resp = await authPost(`/api/cons/con_brands/${conBrandId}/venues`, {
		venue: data,
	});
	return resp;
};

export const getVenues = async conBrandId => {
	return await authGet(`/api/cons/venues/`, { searchParams: { conBrandId } });
};

export const getVenue = async venueId => {
	return await authGet(`/api/cons/venues/${venueId}`);
};

export const postSetMapImage = async (venueId, file) => {
	try {
		const url = `https://api.cloudinary.com/v1_1/${config.cloudinaryCloudName}/upload`;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('upload_preset', 'k1lbllr1');
		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});
		const json = await res.json();
		return await authPost(`/api/cons/venues/${venueId}/set_map_image`, {
			imageUrl: json.secure_url,
			publicId: json.public_id,
		});
	} catch (e) {
		console.log('error', e);
	}
};
