import { authDelete, authGet, authPost } from '../../auth/helpers/AuthRequests';
import { uploadCloudinaryImage } from '../../common/services/CloudinaryService';

export const getGalleryImages = async conEventId => {
	return await authGet(`/api/cons/galleries`, {
		searchParams: { conEventId },
	});
};

export const postAddGalleryImage = async ({ file, conEventId }) => {
	const { url, publicId } = await uploadCloudinaryImage(file);
	return await authPost(`/api/cons/galleries/create_gallery_image`, {
		conEventId,
		clImage: {
			url,
			publicId,
		},
	});
};

export const deleteGalleryImage = async ({
	galleryImageId,
	conEventId,
	destroy,
}) => {
	return await authDelete(`/api/cons/galleries/remove`, {
		galleryImageId,
		conEventId,
		destroy,
	});
};
