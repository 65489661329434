import React from 'react';
import ImageUploading from 'react-images-uploading';
import { Box } from '@mui/material';
import { Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export default ({ control, name, size }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<Box display="flex" mb={2} mt={3}>
						<ImageUploading
							value={[value]}
							onChange={data => {
								onChange({ ...data[0], changed: true });
							}}
							dataURLKey="data_url">
							{({ onImageUpload, dragProps }) => (
								<Box
									width={size || 300}
									height={size || 300}
									display="flex"
									alignItems="center"
									justifyContent="center"
									border={1}
									onClick={onImageUpload}
									{...dragProps}>
									{value ? (
										<>
											<img
												style={{
													width: 'auto',
													height: 'auto',
													maxWidth: '100%',
													maxHeight: '100%',
												}}
												src={value.data_url || value.imageUrl}
												alt="selected"
											/>
										</>
									) : (
										<Box
											sx={{
												flex: 1,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<Typography style={{ userSelect: 'none' }}>
												{' '}
												Click or Drag photo here
											</Typography>
										</Box>
									)}
								</Box>
							)}
						</ImageUploading>
					</Box>
				);
			}}
		/>
	);
};
