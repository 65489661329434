import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import ProfileImageUpload from '../../vendors/components/ProfileImageUpload';
import ConBrandSubNav from '../components/ConBrandSubNav';
import { postSetProfileImage } from '../../cons/services/ConBrandsService';
import ConContext from '../../cons/contexts/ConContext';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const { conBrandId } = useParams();
	const { refreshConBrands } = useContext(ConContext);
	const { showSnackbar } = useContext(SnackbarContext);
	return (
		<PageContainer title="Change Profile Picture">
			<ConBrandSubNav value="editProfileImage" conBrandId={conBrandId} />
			<Container>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center">
					<Grid item xs={10}>
						<ProfileImageUpload
							onSubmit={async image => {
								const res = await postSetProfileImage(conBrandId, image.file);
								if (res.status === 'success') {
									refreshConBrands();
									showSnackbar({
										message: 'Profile Image updated!',
										type: 'success',
									});
								}
							}}
						/>
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
