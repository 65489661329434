import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import UserContext from '../../auth/contexts/UserContext';
import { authGet } from '../../auth/helpers/AuthRequests';

const VendorContext = createContext({});

export const VendorProvider = ({ children }) => {
	const [vendors, setVendors] = useState([]);
	const [loading, setLoading] = useState(true);
	const { user } = useContext(UserContext);
	const refreshVendors = useCallback(() => {
		const fetchVendorBrands = async () => {
			setLoading(true);
			const vendors = await authGet('/api/vendors/vendors');
			setVendors(vendors);
			setLoading(false);
		};
		fetchVendorBrands().then();
	}, []);
	useEffect(() => {
		if (!user?.id) return;
		refreshVendors();
	}, [user?.id, refreshVendors]);

	return (
		<VendorContext.Provider
			value={{
				vendors,
				loading,
				refreshVendors,
			}}>
			{children}
		</VendorContext.Provider>
	);
};

export default VendorContext;
