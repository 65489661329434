import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import * as Yup from 'yup';

import FormCheckbox from '../../form/FormCheckbox';
import { authButton, authForm } from './AuthForms.styles';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import FormTextField from '../../form/FormTextField';
import UserContext from '../contexts/UserContext';
import { registerUser } from '../services/AuthService';
import { saveTokens } from '../helpers/AuthRequests';
const formSchema = Yup.object().shape({
	email: Yup.string().email().required('Email is required'),
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string().oneOf(
		[Yup.ref('password')],
		'Passwords do not match',
	),
});
export default ({ onSuccess }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({ resolver: yupResolver(formSchema) });

	const { fetchUser } = useContext(UserContext);

	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const handleRegister = async data => {
		try {
			setLoading(true);

			const primaryType = data.interestedVendor ? 'vendor' : 'attendee';
			const resp = await registerUser({
				user: {
					...data,
					confirmPassword: undefined,
					interestedVendor: undefined,
					primaryType,
				},
			});

			const { accessToken, refreshToken } = resp;

			saveTokens({
				accessToken,
				refreshToken,
			});

			fetchUser();

			onSuccess();
		} catch (e) {
			console.log('error', e);
			showSnackbar({ message: 'Something went wrong, please try again later' });
		} finally {
			setLoading(false);
		}
	};

	return (
		<form style={authForm} onSubmit={handleSubmit(handleRegister)}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Email"
						name="email"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EmailIcon />
								</InputAdornment>
							),
						}}
						error={!!errors.email}
						helperText={errors.email?.message}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormTextField
						control={control}
						label="First Name"
						name="firstName"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AccountCircle />
								</InputAdornment>
							),
						}}
						error={!!errors.firstName}
						helperText={errors.firstName?.message}
					/>
				</Grid>

				<Grid item xs={6}>
					<FormTextField
						control={control}
						label="Last Name"
						name="lastName"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AccountCircle />
								</InputAdornment>
							),
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					<FormTextField
						control={control}
						label="Password"
						name="password"
						type="password"
						error={!!errors.password}
						helperText={errors.password?.message}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					<FormTextField
						control={control}
						label="Confirm Password"
						name="confirmPassword"
						type="password"
						error={!!errors.confirmPassword}
						helperText={errors.confirmPassword?.message}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormCheckbox
						control={control}
						name="interestedVendor"
						label="I am interested in vending."
					/>
				</Grid>
				<Grid item xs={12}>
					<FormCheckbox
						control={control}
						name="preferences.alertEmailEnabled"
						label="I would like to receive updates via email."
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						type="submit"
						variant="contained"
						sx={authButton}
						disabled={loading}>
						Create Your Account
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
