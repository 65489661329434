import React, { useContext, useRef, useState } from 'react';
import {
	Button,
	Typography,
	Toolbar,
	Box,
	IconButton,
	MenuItem,
	ListSubheader,
	Menu,
} from '@mui/material';
import {
	Add,
	ExpandMore,
	ExpandLess,
	Mail,
	MailOutline,
	Menu as MenuIcon,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

import UserContext from '../../../auth/contexts/UserContext';
import ConContext from '../../../cons/contexts/ConContext';
import VendorContext from '../../../vendors/context/VendorContext';
import { MessageContext } from '../../../messages/contexts/MessageContext';

export default ({ viewContextText }) => {
	const { destroyCredentials } = useContext(UserContext);

	const { conBrands } = useContext(ConContext);
	const { vendors } = useContext(VendorContext);
	const { unreadMessageCount } = useContext(MessageContext);
	const navigate = useNavigate();

	const menuRef = useRef();
	const burgerRef = useRef();

	const [showConBrandsMenu, setShowConBrandsMenu] = useState(false);
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);

	const ExpandComponent = showConBrandsMenu ? ExpandLess : ExpandMore;

	const conBrandMenu = menuRef.current ? (
		<Menu
			id="con-brand-menu"
			anchorEl={menuRef.current}
			open={showConBrandsMenu}
			onClose={() => {
				setShowConBrandsMenu(false);
			}}>
			<ListSubheader>Vendor Brands</ListSubheader>
			{vendors.map(vendor => (
				<MenuItem key={vendor.id} component={Link} to={`/vendor/${vendor.id}`}>
					{vendor.name}
				</MenuItem>
			))}
			<MenuItem key="createVendor" component={Link} to="/vendors/create">
				<Add />
				New Brand
			</MenuItem>
			<ListSubheader>Event Brands</ListSubheader>
			{conBrands.map(conBrand => (
				<MenuItem
					key={conBrand.id}
					component={Link}
					to={`/con-brand/${conBrand.id}`}>
					{conBrand.name}
				</MenuItem>
			))}
			<MenuItem key="createConBrand" component={Link} to="/create-con-brand">
				<Add />
				New Brand
			</MenuItem>
		</Menu>
	) : null;

	const burgerMenu = burgerRef.current ? (
		<Menu
			id="burger-menu"
			anchorEl={burgerRef.current}
			open={showBurgerMenu}
			onClose={() => {
				setShowBurgerMenu(false);
			}}>
			<MenuItem component={Link} to="/">
				Home
			</MenuItem>
			<MenuItem component={Link} to="/user/preferences">
				Account
			</MenuItem>
			<MenuItem
				onClick={() => {
					destroyCredentials();
					navigate('/');
				}}>
				Log Out
			</MenuItem>
		</Menu>
	) : null;

	return (
		<>
			{conBrandMenu}
			{burgerMenu}
			<Toolbar>
				<Button
					ref={menuRef}
					onClick={() => {
						setShowConBrandsMenu(!showConBrandsMenu);
					}}
					sx={{
						minWidth: 160,
						border: 1,
						marginRight: 3,
					}}>
					<Typography style={{ fontWeight: 'bold' }}>
						{viewContextText}
					</Typography>
					<ExpandComponent />
				</Button>
				<Box flex={1} />
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}
					component={Link}
					to="/messages">
					{unreadMessageCount ? <Mail /> : <MailOutline />}
				</IconButton>
				<IconButton
					ref={burgerRef}
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2, justifySelf: 'flex-end' }}
					onClick={() => {
						setShowBurgerMenu(!showBurgerMenu);
					}}>
					<MenuIcon />
				</IconButton>
			</Toolbar>
		</>
	);
};
