import { authPost } from '../../auth/helpers/AuthRequests';

export const followConEvent = async (conEventId, source) => {
	const resp = await authPost('/api/attendees/followings', {
		attendeeFollowingConEvent: { conEventId, source },
	});
	return resp;
};

export const unfollowConEvent = async conEventId => {
	const resp = await authPost('/api/attendees/unfollow', {
		conEventId,
	});
	return resp;
};

export const followConBrand = async (conBrandId, source) => {
	const resp = await authPost('/api/attendees/follow_con_brand', {
		attendeeFollowingConBrand: { conBrandId, source },
	});
	return resp;
};

export const unfollowConBrand = async conBrandId => {
	const resp = await authPost('/api/attendees/unfollow_con_brand', {
		conBrandId,
	});
	return resp;
};
