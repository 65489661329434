import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default ({ conBrandId, conEventId, value }) => {
	const navigate = useNavigate();

	const handleChange = (_, value) => {
		switch (value) {
			case 'overview':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}`);
				break;
			case 'applications':
				navigate(
					`/con-brand/${conBrandId}/con-event/${conEventId}/applications`,
				);
				break;
			case 'exhibitors':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/exhibitors`);
				break;
			case 'editPublicInfo':
				navigate(
					`/con-brand/${conBrandId}/con-event/${conEventId}/edit-public-info`,
				);
				break;
			case 'invoices':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/invoices`);
				break;
			default:
				break;
		}
	};
	return (
		<Box display="flex" justifyContent="center" mb={1}>
			<Tabs value={value} aria-label="Event navigation" onChange={handleChange}>
				<Tab value="overview" label="Overview" />
				<Tab value="applications" label="Applications" />
				<Tab value="exhibitors" label="Exhibitors" />
				<Tab value="invoices" label="Invoices" />
				<Tab value="editPublicInfo" label="Public Info" />
			</Tabs>
		</Box>
	);
};
