import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import LoginForm from '../LoginForm';
import { fullViewHeight } from './LoginPage.styles';

export default () => {
	useEffect(() => {
		document.title = 'Konvene | Login';
	}, []);

	return (
		<Grid container sx={fullViewHeight}>
			<LoginForm />
		</Grid>
	);
};
