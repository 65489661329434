import React, { useContext } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import ConBrandForm from '../../cons/components/ConBrandForm';
import ConContext from '../../cons/contexts/ConContext';
import ConBrandSubNav from '../components/ConBrandSubNav';

export default () => {
	const { conBrands } = useContext(ConContext);
	const { id } = useParams();

	const conBrand = conBrands.find(cb => cb.id.toString() === id);
	return (
		<PageContainer title={`Edit ${conBrand?.name || 'Brand'}`}>
			<ConBrandSubNav value="info" conBrandId={id} />
			<Container>
				<ConBrandForm editing conBrand={conBrand} />
			</Container>
		</PageContainer>
	);
};
