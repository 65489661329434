import Grid from '@mui/material/Grid';
import FormTextField from '../../form/FormTextField';
import { Button, FormHelperText } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { useForm } from 'react-hook-form';
import { updateVendorInfo } from '../services/VendorsService';

export default ({ vendor }) => {
	const [loading, setLoading] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	const { handleSubmit, control, reset } = useForm({
		defaultValues: vendor?.publicInfo,
	});

	useEffect(() => {
		reset(vendor?.publicInfo);
	}, [vendor, reset]);

	const handleUpdatePublicInfo = useCallback(
		async data => {
			setLoading(true);
			try {
				const { status } = await updateVendorInfo(vendor.id, {
					...vendor,
					publicInfo: data,
				});

				if (status === 'success') {
					showSnackbar({
						message: `Public info successfully saved!`,
						type: 'success',
					});
				} else {
					showSnackbar({
						message: `Failed saving. Please try again later`,
						type: 'error',
					});
				}
			} catch (e) {
				console.log('err', e);
				showSnackbar({
					message: `Failed saving. Please try again later`,
					type: 'error',
				});
			} finally {
				setLoading(false);
			}
		},
		[vendor, showSnackbar, setLoading],
	);

	return (
		<form onSubmit={handleSubmit(handleUpdatePublicInfo)}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormTextField control={control} label="Tagline" name="tagline" />
					<FormHelperText>Describe what you sell to attendees</FormHelperText>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Description"
						name="description"
						multiline
						minRows={3}
					/>
				</Grid>

				<Grid item xs={12}>
					<Button type="submit" variant="contained" disabled={loading}>
						Save
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
