import React from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { Box } from '@mui/material';

import { getConfig } from '../../../../config/config';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

export default ({ galleryImage, onClick }) => {
	const img = cld.image(galleryImage?.clImage?.publicId).format('png');

	return (
		<Box onClick={() => onClick(galleryImage)}>
			<AdvancedImage
				cldImg={img}
				style={{
					maxWidth: '100%',
					maxHeight: '100%',
					width: 'auto',
					height: 'auto',
				}}
			/>
		</Box>
	);
};
