import React, { Fragment, useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import {
	Button,
	Box,
	Card,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import FormTextField from '../../form/FormTextField';
import {
	conBrandButton,
	formContainer,
} from '../../cons/components/ConBrandForm.styles';
import {
	createVendorApplication,
	fetchVendorApplicationOptions,
} from '../services/VendorsService';
import FormSelect from '../../form/FormSelect';
import VendorContext from '../context/VendorContext';
import { formatUSD } from '../../utils/format';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({
	vendorId: Yup.number()
		.transform(v => (isNaN(v) ? undefined : v))
		.required('Please select a vendor'),
	applicationInfo: Yup.object().shape({
		name: Yup.string().required('Please enter a vendor name'),
		email: Yup.string().email().required('Please enter an email'),
		itemDescription: Yup.string().required('Please describe your items'),
		address: Yup.object().shape({
			street1: Yup.string().required('Please enter your street'),
			city: Yup.string().required('Please enter your city'),
			state: Yup.string().required('Please enter your state'),
			postalCode: Yup.string().required('Please enter your postalCode'),
		}),
	}),
});

export default ({ conEventId }) => {
	const [searchParams] = useSearchParams();
	const vendorTypeId = searchParams.get('vendor_type');

	const { vendors } = useContext(VendorContext);

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {
			conEventId,
			vendorId: vendors[0]?.id,
			conEventVendorTypeId: vendorTypeId,
		},
		resolver: yupResolver(formSchema),
	});

	const watchAll = watch();
	useEffect(() => {
		if (!watchAll.vendorId) return;
		const vendor = vendors.find(v => v.id === watchAll.vendorId);
		if (!watchAll.applicationInfo?.name)
			setValue('applicationInfo.name', vendor.name);
		if (!watchAll.applicationInfo?.email)
			setValue('applicationInfo.email', vendor.email);
		if (!watchAll.applicationInfo?.ein)
			setValue('applicationInfo.ein', vendor.ein);
		if (!watchAll.applicationInfo?.address?.street1)
			setValue('applicationInfo.address', vendor.address);
		// eslint-disable-next-line
	}, [watchAll.vendorId, setValue, vendors]);

	const navigate = useNavigate();

	const { showSnackbar } = useContext(SnackbarContext);

	const [vendorTypes, setVendorTypes] = useState();
	const [addOns, setAddOns] = useState();

	const AddOns = ({ addOns }) => {
		return (
			<List>
				{addOns.map((addOn, index) => (
					<Fragment key={index}>
						<ListItem alignItems="center">
							<Grid
								container
								alignItems="center"
								justifyContent="space-between"
								spacing={3}>
								<Grid item>
									<Typography>{addOn.name}</Typography>
									<Typography>Price: {formatUSD(addOn.price)}</Typography>
								</Grid>
								<Grid item>
									<input
										type="hidden"
										{...register(
											`vendorApplicationConEventAddOnsAttributes.${index}.conEventAddOnId`,
										)}
										value={addOn.id}
									/>
									<FormTextField
										name={`vendorApplicationConEventAddOnsAttributes.${index}.amount`}
										control={control}
										options={addOns}
										error={errors?.addOns?.[index]?.value}
									/>
								</Grid>
							</Grid>
						</ListItem>
						<Divider />
					</Fragment>
				))}
			</List>
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchVendorApplicationOptions(conEventId);
			setVendorTypes(data.conEventVendorTypes);
			setAddOns(data.conEventAddOns);
		};

		fetchData().catch(err => console.error(err));
	}, [conEventId]);

	const handleCreateVendorApplication = async data => {
		try {
			const resp = await createVendorApplication(data);
			if (resp.status === 'success') {
				navigate(`/vendor/${data.vendorId}`);
				showSnackbar({
					message: 'Application Sent! We will alert you of any updates!',
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Failed creating Application. Please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Failed creating Application. Please try again later',
				type: 'error',
			});
		}
	};

	if (!vendorTypes) return <CircularProgress />;

	// this whole thing needs to be simplified
	const vendorType = vendorTypes.find(
		vt => vt.id.toString() === vendorTypeId.toString(),
	);

	const vendorOptions = vendors.map(vendor => ({
		label: vendor.name,
		value: vendor.id,
	}));

	return (
		<Card sx={formContainer}>
			<form onSubmit={handleSubmit(handleCreateVendorApplication)}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography>
								Applying for {vendorType.name} : {formatUSD(vendorType.price)}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<FormSelect
								control={control}
								id="vendorId"
								name="vendorId"
								label="Vendor Brand"
								options={vendorOptions}
								blankText="select Vendor Brand"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Name *"
								name="applicationInfo.name"
								error={!!errors.applicationInfo?.name}
								helperText={errors.applicationInfo?.name?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Email *"
								name="applicationInfo.email"
								error={!!errors.applicationInfo?.email}
								helperText={errors.applicationInfo?.email?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="EIN"
								name="applicationInfo.ein"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Booth Preference"
								name="applicationInfo.boothPreference"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Description of Items *"
								name="applicationInfo.itemDescription"
								multiline
								minRows={3}
								error={!!errors.applicationInfo?.itemDescription}
								helperText={errors.applicationInfo?.itemDescription?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1">Address</Typography>
							<FormTextField
								control={control}
								label="Street"
								name="applicationInfo.address.street1"
								error={!!errors.applicationInfo?.address?.street1}
								helperText={errors.applicationInfo?.address?.street1?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Street2"
								name="applicationInfo.address.street2"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								control={control}
								label="City"
								name="applicationInfo.address.city"
								error={!!errors.applicationInfo?.address?.city}
								helperText={errors.applicationInfo?.address?.city?.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								control={control}
								label="State"
								name="applicationInfo.address.state"
								error={!!errors.applicationInfo?.address?.state}
								helperText={errors.applicationInfo?.address?.state?.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								control={control}
								label="Postal Code"
								name="applicationInfo.address.postalCode"
								error={!!errors.applicationInfo?.address?.postalCode}
								helperText={
									errors.applicationInfo?.address?.postalCode?.message
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="subtitle1">
								Below are add ons offered by the event. Please enter the amount
								of add ons you will need for your booth (default: 0)
							</Typography>
							<AddOns addOns={addOns} />
						</Grid>

						<Grid item xs={12}>
							<Button type="submit" variant="contained" sx={conBrandButton}>
								Send Application
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Card>
	);
};
