import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { formatUSD } from '../../utils/format';
import { postSendStripeInvoice } from '../../vendors/services/VendorsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { getStripeStatus } from '../../payments/services/StripeServices';

export default ({
	vendorApplication: { id, conEventVendorType, conEventAddOns },
	refreshData,
}) => {
	const [sending, setSending] = useState(false);
	const [stripeStatus, setStripeStatus] = useState('loading');

	useEffect(() => {
		const fetchData = async () => {
			const { stripeStatus } = await getStripeStatus();
			setStripeStatus(stripeStatus);
		};
		fetchData().then();
	}, []);
	const { showSnackbar } = useContext(SnackbarContext);

	const breakdownItems = conEventAddOns.map(addon => (
		<TableRow key={addon.id}>
			<TableCell>
				{addon.name} x {addon.amount}
			</TableCell>
			<TableCell align="right">
				{formatUSD(addon.price * addon.amount)}
			</TableCell>
		</TableRow>
	));

	const total = conEventAddOns.reduce(
		(acc, cur) => acc + cur.price * cur.amount,
		conEventVendorType.price,
	);

	let action = null;
	switch (stripeStatus) {
		case 'ready':
			action = (
				<Button
					onClick={async () => {
						try {
							setSending(true);
							const { status } = await postSendStripeInvoice(id);
							if (status === 'fail') {
								showSnackbar({
									message: 'Error sending invoice, please try again later',
									type: 'error',
								});
							} else {
								showSnackbar({ message: 'Invoice sent!', type: 'success' });
								refreshData();
							}
						} catch (e) {
							showSnackbar({
								message: 'Error sending invoice, please try again later',
								type: 'error',
							});
						} finally {
							setSending(false);
						}
					}}
					variant="contained"
					disabled={sending}>
					Send Invoice
				</Button>
			);
			break;
		case 'loading':
			action = <CircularProgress />;
			break;

		default:
			action = (
				<Box>
					<Typography mb={1}>
						Please set up your stripe account before sending an invoice
					</Typography>
					<Button component={Link} variant="outlined" to={'/payments'}>
						Set Up Stripe Payments
					</Button>
				</Box>
			);
			break;
	}

	return (
		<Card>
			<CardContent>
				<Typography variant="h4">Invoice Preview</Typography>
				<Typography>
					This application has been accepted. Please review this invoice and
					send if it is correct
				</Typography>
				<Box marginBottom={3}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Description</TableCell>
									<TableCell align="right">Price</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>{conEventVendorType.name} fee</TableCell>
									<TableCell align="right">
										{formatUSD(conEventVendorType.price)}
									</TableCell>
								</TableRow>
								{breakdownItems}
								<TableRow>
									<TableCell sx={{ fontWeight: 'bold' }}>TOTAL</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }} align="right">
										{formatUSD(total)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				{action}
			</CardContent>
		</Card>
	);
};
