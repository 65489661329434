import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import ConBrandSubNav from '../components/ConBrandSubNav';
import { getVenues } from '../services/VenuesService';
import VenuesCard from '../components/VenuesCard';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);

	const [venues, setVenues] = useState();

	const { showSnackbar } = useContext(SnackbarContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				const resp = await getVenues(id);
				if (resp.status === 'success') {
					setVenues(resp.venues);
					setLoading(false);
				} else {
					showSnackbar({ message: 'Try again later', type: 'error' });
				}
			} catch (e) {
				showSnackbar({ message: 'Try again later', type: 'error' });
			}
		};
		fetchData().then();
	}, [id, showSnackbar]);

	const content = loading ? (
		<CircularProgress />
	) : (
		<VenuesCard venues={venues} />
	);

	return (
		<PageContainer title="Venues">
			<ConBrandSubNav value="venues" conBrandId={id} />
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{content}
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
