import React from 'react';
import FormSelect from '../../form/FormSelect';

const genreOptions = [
	{ label: 'Market', value: 'market' },
	{ label: 'Convention', value: 'convention' },
	{ label: 'Exhibit Hall', value: 'exhibitHall' },
];

export default ({ control, label, name }) => {
	return (
		<FormSelect
			control={control}
			name={name}
			label={label}
			options={genreOptions}
			blankText="< Choose one >"
		/>
	);
};
