import React from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';

import FormTextField from '../FormTextField';
export default ({ control, namePrefix, required, subText }) => {
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="subtitle1">Address</Typography>
				<FormHelperText>
					This address will be shared to vendors and attendees as the location
					of your event
				</FormHelperText>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					control={control}
					label={required ? 'Street *' : 'Street'}
					name={`${namePrefix}.street1`}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					control={control}
					label="Street2"
					name={`${namePrefix}.street2`}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label={required ? 'City *' : 'City'}
					name={`${namePrefix}.city`}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label={required ? 'State *' : 'State'}
					name={`${namePrefix}.state`}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label={required ? 'Postal Code *' : 'Postal Code'}
					name={`${namePrefix}.postalCode`}
				/>
			</Grid>
		</>
	);
};
