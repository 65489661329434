import PageContainer from '../../common/components/PageContainer';
import { Container, Grid } from '@mui/material';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getConEventSchedule } from '../services/SchedulesService';
import EnableSchedule from '../components/EnableSchedule';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import ScheduleDailyCard from '../components/ScheduleDailyCard';
import PanelsPreviewCard from '../components/PanelsPreviewCard';
import PublicInfoSubNav from '../../con-events/components/PublicInfoSubNav';

export default () => {
	const { conEventId, conBrandId } = useParams();
	const { showSnackbar } = useContext(SnackbarContext);

	const [schedule, setSchedule] = useState();
	const [loading, setLoading] = useState(false);

	const fetchSchedule = useCallback(async () => {
		try {
			const res = await getConEventSchedule({
				conEventId,
				includePanels: true,
			});

			if (res.status === 'success') {
				setSchedule(res.schedule);
			} else {
				showSnackbar({
					message: `Failed fetching schedule data. Please try again later`,
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: `Failed fetching schedule data. Please try again later`,
				type: 'error',
			});
		}
	}, [conEventId, setSchedule, showSnackbar]);

	useEffect(() => {
		fetchSchedule().then();
	}, [fetchSchedule]);

	return (
		<PageContainer>
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="schedule"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<EnableSchedule
							conEventId={conEventId}
							schedule={schedule}
							fetchData={fetchSchedule}
							setSchedule={setSchedule}
							loading={loading}
							setLoading={setLoading}
						/>
					</Grid>
					{schedule?.active && (
						<>
							<Grid item xs={12}>
								<ScheduleDailyCard
									schedule={schedule}
									setSchedule={setSchedule}
								/>
							</Grid>
							<Grid item xs={12}>
								<PanelsPreviewCard panels={schedule.panels || []} />
							</Grid>
						</>
					)}
				</Grid>
			</Container>
		</PageContainer>
	);
};
