import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { Box, Button, Divider, Grid } from '@mui/material';

import DashCard from '../../common/components/DashCard';
import FormSwitch from '../../form/FormSwitch';
import FormTextField from '../../form/FormTextField';
import { conBrandButton } from '../../cons/components/ConBrandForm.styles';
import { putUpdatePublicInfo } from '../../cons/services/ConEventsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({});
export default ({ conEvent }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});

	const {
		fields: faqsFields,
		append: faqsAppend,
		remove: faqsRemove,
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'faqs', // unique name for your Field Array
	});

	useEffect(() => {
		reset(conEvent?.publicInfo?.faq);
	}, [conEvent, reset]);

	const handleUpdateHotels = useCallback(
		async data => {
			setLoading(true);
			try {
				await putUpdatePublicInfo(conEvent.id, { faq: data });
				showSnackbar({
					message: 'Hotels successfully saved!',
					type: 'success',
				});
			} catch (e) {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[conEvent, showSnackbar],
	);

	return (
		<DashCard title="FAQ" size="fit">
			<form onSubmit={handleSubmit(handleUpdateHotels)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormSwitch control={control} name="active" label="Active" />
					</Grid>
					{faqsFields.map((hotel, index) => (
						<Grid key={index} item xs={12}>
							<Box>
								<FormTextField
									control={control}
									label="Question"
									name={`faqs.${index}.question`}
								/>
								<FormTextField
									control={control}
									label="Answer"
									name={`faqs.${index}.answer`}
									multiline
									minRows={3}
								/>
								<Button
									variant="outlined"
									sx={{ marginBottom: 1 }}
									onClick={() => faqsRemove(index)}
									disabled={loading}>
									Remove
								</Button>
							</Box>
							<Divider />
						</Grid>
					))}
					<Grid item xs={12}>
						<Button
							variant="outlined"
							onClick={() =>
								faqsAppend({
									answer: '',
									question: '',
								})
							}>
							Add another FAQ
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Button type="submit" variant="contained" sx={conBrandButton}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
