import React from 'react';
import {
	Divider,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';

const VenueItem = ({ venue: { id, name } }) => {
	const navigate = useNavigate();

	return (
		<>
			<ListItemButton
				onClick={() => {
					navigate(`${id}`);
				}}>
				<ListItemText
					primary={<Typography variant="h5">{name}</Typography>}
					secondary={
						<>
							<Typography
								component="span"
								variant="body2"
								color="text.primary"></Typography>
						</>
					}
				/>
			</ListItemButton>
			<Divider />
		</>
	);
};

export default ({ venues }) => {
	let itemContent = venues.map(venue => (
		<VenueItem key={venue.id} venue={venue} />
	));

	return (
		<DashCard title="Venues" size="max">
			{itemContent}
		</DashCard>
	);
};
