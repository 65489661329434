import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default ({ vendorId, value }) => {
	const navigate = useNavigate();

	const handleChange = (_, value) => {
		switch (value) {
			case 'overview':
				navigate(`/vendor/${vendorId}`);
				break;
			case 'exhibiting':
				navigate(`/vendor/${vendorId}/exhibitings`);
				break;
			case 'editPublicInfo':
				navigate(`/vendor/${vendorId}/edit-public-info`);
				break;

			default:
				break;
		}
	};
	return (
		<Box display="flex" justifyContent="center" mb={1}>
			<Tabs value={value} aria-label="Event navigation" onChange={handleChange}>
				<Tab value="overview" label="Overview" />
				<Tab value="exhibiting" label="Exhibiting" />
				<Tab value="editPublicInfo" label="Public Info" />
			</Tabs>
		</Box>
	);
};
