import React from 'react';
import {
	Email,
	Instagram,
	Language,
	Twitter,
	YouTube,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default ({
	contactLinks: { publicEmail, website, instagram, twitter, youtube, tiktok },
}) => {
	let fixedWebsite;
	if (website) {
		fixedWebsite = website;
		if (!fixedWebsite.match(/^[a-zA-Z]+:\/\//)) {
			fixedWebsite = 'http://' + fixedWebsite;
		}
	}

	return (
		<>
			{publicEmail && (
				<IconButton
					href={`mailto:${publicEmail}`}
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}>
					<Email fontSize="large" />
				</IconButton>
			)}
			{website && (
				<IconButton
					href={fixedWebsite}
					target="_blank"
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}>
					<Language fontSize="large" />
				</IconButton>
			)}
			{instagram && (
				<IconButton
					href={`https://www.instagram.com/${instagram}`}
					target="_blank"
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}>
					<Instagram fontSize="large" />
				</IconButton>
			)}
			{twitter && (
				<IconButton
					href={`https://www.twitter.com/${instagram}`}
					target="_blank"
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}>
					<Twitter fontSize="large" />
				</IconButton>
			)}
			{youtube && (
				<IconButton
					href={`https://www.youtube.com/${youtube}`}
					target="_blank"
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}>
					<YouTube fontSize="large" />
				</IconButton>
			)}
		</>
	);
};
