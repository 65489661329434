import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

import FormTextField from '../../form/FormTextField';
import { loginUser } from '../services/AuthService';
import { saveTokens } from '../helpers/AuthRequests';
import UserContext from '../contexts/UserContext';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default ({ onSuccess }) => {
	const { handleSubmit, control } = useForm();
	const { fetchUser } = useContext(UserContext);

	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);

	const handleLogin = async data => {
		try {
			setSubmitting(true);
			const { accessToken, refreshToken } = await loginUser({
				user: { email: data.email, password: data.password },
			});

			saveTokens({ accessToken, refreshToken });

			fetchUser();

			onSuccess();
		} catch (e) {
			// TODO don't handle this by throwing
			console.error('login error', e);
			showSnackbar({ message: 'Login Failed', type: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(handleLogin)}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Email"
						name="email"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EmailIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Password"
						name="password"
						type="password"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Link
						href=""
						onClick={() => navigate('/request-password-reset')}
						underline="none">
						<b>Forgot your password?</b>
					</Link>
				</Grid>
				<Grid item xs={12}>
					<Button type="submit" variant="contained" disabled={submitting}>
						Login
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
