import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	CircularProgress,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';
import { getVendorApplications } from '../services/VendorApplicationsService';

const ApplicationItem = ({
	vendorApplication: {
		id,
		status,
		conEvent: { name },
	},
}) => {
	return (
		<ListItemButton component={Link} to={`vendor-application/${id}`}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							Status: {status}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ vendor }) => {
	const [applications, setApplications] = useState();

	const lastVendorId = useRef(null);

	useEffect(() => {
		if (lastVendorId.current === vendor.id) return;
		lastVendorId.current = vendor.id;

		getVendorApplications({
			vendorId: vendor.id,
			includeConEventName: true,
			limit: 4,
		}).then(app => {
			setApplications(app.vendorApplications);
		});
	}, [vendor, setApplications]);

	let content = <CircularProgress />;

	if (applications) {
		const applicationItems = applications.map(va => (
			<Fragment key={va.id}>
				<ApplicationItem vendorApplication={va} />
				<Divider />
			</Fragment>
		));
		content = (
			<>
				<List>{applicationItems}</List>
				{applicationItems.length === 0 && (
					<Typography variant="p">You have no Applications</Typography>
				)}
			</>
		);
	}

	return <DashCard title="Vendor Applications">{content}</DashCard>;
};
