import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../components/ConEventSubNav';
import PanelForm from '../components/schedules/PanelForm';

export default () => {
	const { conEventId, conBrandId } = useParams();

	return (
		<PageContainer title="Add a Panel">
			<ConEventSubNav
				value="schedule"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>

			<Container>
				<PanelForm conBrandId={conBrandId} conEventId={conEventId} />
			</Container>
		</PageContainer>
	);
};
