import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';

import { authGet } from '../../auth/helpers/AuthRequests';
import EmailPreferencesCard from '../components/EmailPreferencesCard';
import PageContainer from '../../common/components/PageContainer';
import DeleteAccountCard from '../components/DeleteAccountCard';

export default () => {
	const [preferences, setPreferences] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const res = await authGet('/api/preferences');
			setPreferences(res.preferences);
		};
		fetchData();
	}, []);

	return (
		<PageContainer title="Account Preferences">
			<Container>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<EmailPreferencesCard preferences={preferences} />
					</Grid>
					<Grid item xs={12}>
						<DeleteAccountCard />
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
