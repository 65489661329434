import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	List,
	ListItem,
	ListItemText,
	Modal,
	Typography,
} from '@mui/material';

import { modalContainer } from '../../../form/components/ModalContainer.styles';
import { formatUSD } from '../../../utils/format';
import { getConEventAddOn } from '../../../cons/services/ConService';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';

const ExhibitorItem = ({ name, quantity }) => {
	return (
		<ListItem>
			<ListItemText primary={`${name} x ${quantity}`} />
		</ListItem>
	);
};

export default ({ onClose, addOnId }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const [addOn, setAddOn] = useState(null);

	const loadAddOn = useCallback(async () => {
		if (!addOnId) return;
		const { status, conEventAddOn } = await getConEventAddOn(addOnId);
		if (status === 'success') {
			setAddOn(conEventAddOn);
		} else {
			showSnackbar({
				message: 'Error loading Add On. Please try again later',
				type: 'error',
			});
			onClose();
		}
	}, [addOnId, onClose, showSnackbar]);

	useEffect(() => {
		loadAddOn().then();
	}, [loadAddOn]);

	const items = useMemo(() => {
		if (!addOn) return [];
		return addOn.vendorExhibitsWithQuantity.map(ve => (
			<Fragment key={ve.id}>
				<ExhibitorItem name={ve.vendor.name} quantity={ve.quantity} />
				<Divider />
			</Fragment>
		));
	}, [addOn]);

	return (
		<Modal open={!!addOnId} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent sx={{ height: 420 }}>
						<Box display="flex" justifyContent="space-between">
							<Typography variant="h5">{addOn?.name}</Typography>
							<Button variant="contained">Edit</Button>
						</Box>
						<Typography variant="">{addOn?.description}</Typography>
						<Divider />
						<Typography>price: {formatUSD(addOn?.price)}</Typography>
						<Divider />
						<Typography variant="h6">Bought By Exhibitors</Typography>
						<List sx={{ overflow: 'auto', height: 230 }}>{items}</List>
						<Box mb={1} mt={1}></Box>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
