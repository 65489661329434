import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';

const FormTimePicker = ({ control, name, label, inputId }) => {
	const [open, setOpen] = useState(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Controller
				name={name}
				control={control}
				defaultValue={null}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					let displayDate = null;
					if (value)
						displayDate = dayjs(value, 'hh:mm A').isValid()
							? dayjs(value).format('hh:mm A')
							: value;
					console.log('display date', displayDate);
					return (
						<TimePicker
							label={label}
							value={displayDate}
							open={open}
							onClose={() => setOpen(false)}
							onChange={value => {
								if (dayjs(value, 'hh:mm A').isValid()) {
									onChange(value.toISOString());
								} else onChange(value);
							}}
							renderInput={params => {
								return (
									<TextField
										{...params}
										error={!!error}
										helperText={error?.message}
										id={inputId}
										fullWidth
										color="primary"
										sx={{
											marginBottom: 2,
										}}
										inputProps={{ value: displayDate }}
										onClick={e => {
											setOpen(true);
										}}
									/>
								);
							}}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
};

export default FormTimePicker;
