import React, { useContext, useRef, useState } from 'react';
import {
	Button,
	Typography,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	ListSubheader,
} from '@mui/material';
import {
	Home,
	AccountCircle,
	Payment,
	Add,
	ExpandMore,
	ExpandLess,
	Mail,
	MailOutline,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

import UserContext from '../../../auth/contexts/UserContext';
import { flexGrow } from './Navbar.styles';
import ConContext from '../../../cons/contexts/ConContext';
import VendorContext from '../../../vendors/context/VendorContext';
import { MessageContext } from '../../../messages/contexts/MessageContext';

export default ({ viewContextText }) => {
	const { user, destroyCredentials } = useContext(UserContext);
	const { conBrands } = useContext(ConContext);
	const { vendors } = useContext(VendorContext);
	const { unreadMessageCount } = useContext(MessageContext);
	const navigate = useNavigate();

	const menuRef = useRef();

	const [showConBrandsMenu, setShowConBrandsMenu] = useState(false);

	const ExpandComponent = showConBrandsMenu ? ExpandLess : ExpandMore;

	const conBrandMenu = menuRef.current ? (
		<Menu
			id="con-brand-menu"
			anchorEl={menuRef.current}
			open={showConBrandsMenu}
			onClose={() => {
				setShowConBrandsMenu(false);
			}}>
			<ListSubheader>Vendor Brands</ListSubheader>
			{vendors.map(vendor => (
				<MenuItem key={vendor.id} component={Link} to={`/vendor/${vendor.id}`}>
					{vendor.name}
				</MenuItem>
			))}
			<MenuItem key="createVendor" component={Link} to="/vendors/create">
				<Add />
				New Brand
			</MenuItem>
			<ListSubheader>Event Brands</ListSubheader>
			{conBrands.map(conBrand => (
				<MenuItem
					key={conBrand.id}
					component={Link}
					to={`/con-brand/${conBrand.id}`}>
					{conBrand.name}
				</MenuItem>
			))}
			<MenuItem key="createConBrand" component={Link} to="/create-con-brand">
				<Add />
				New Brand
			</MenuItem>
		</Menu>
	) : null;

	return (
		<>
			{conBrandMenu}
			<Toolbar>
				<Button
					ref={menuRef}
					onClick={() => {
						setShowConBrandsMenu(!showConBrandsMenu);
					}}
					sx={{
						minWidth: 160,
						border: 1,
						marginRight: 3,
					}}>
					<Typography style={{ fontWeight: 'bold' }}>
						{viewContextText}
					</Typography>
					<ExpandComponent />
				</Button>
				<Typography variant="h6" component="div" sx={flexGrow}>
					Welcome {user.firstName}
				</Typography>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}
					component={Link}
					to="/">
					<Home />
				</IconButton>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}
					component={Link}
					to="/messages">
					{unreadMessageCount ? <Mail /> : <MailOutline />}
				</IconButton>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2 }}
					component={Link}
					to="/user/preferences">
					<AccountCircle />
				</IconButton>
				{conBrands.length > 0 && (
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="payments"
						component={Link}
						to="/payments">
						<Payment />
					</IconButton>
				)}
				<Button
					color="inherit"
					onClick={() => {
						destroyCredentials();
						navigate('/');
					}}>
					Logout
				</Button>
			</Toolbar>
		</>
	);
};
