import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { fetchVendorApplication } from '../services/VendorsService';
import VendorApplicationViewCard from '../../cons/components/VendorApplicationViewCard';
import PayStripeInvoiceCard from '../../payments/components/PayStripeInvoiceCard';
import { postAddNode } from '../services/VendorApplicationsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const { id } = useParams();
	const [vendorApplication, setVendorApplication] = useState();
	const [loading, setLoading] = useState(true);

	const { showSnackbar } = useContext(SnackbarContext);

	const refetchData = useCallback(async () => {
		setLoading(true);

		const vendorApplication = await fetchVendorApplication(id);
		setVendorApplication(vendorApplication);
		setLoading(false);
	}, [id]);

	useEffect(() => {
		refetchData().then();
	}, [refetchData]);

	const addNote = useCallback(
		async message => {
			const { status, vendorApplication: newApp } = await postAddNode(
				id,
				message,
			);

			if (status === 'success') {
				showSnackbar({ message: `Note added`, type: 'success' });
				setVendorApplication({
					...vendorApplication,
					...newApp,
				});
			} else {
				showSnackbar({
					message: `Operation failed, try again later`,
					type: 'error',
				});
			}
		},
		[id, vendorApplication, showSnackbar],
	);

	return (
		<PageContainer title={`Application to ${vendorApplication?.conEvent.name}`}>
			<Container>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						<Grid container spacing={2}>
							<Typography marginBottom={2} variant="h3">
								Application for {vendorApplication.conEvent.name}
							</Typography>

							<Grid item xs={12}>
								<VendorApplicationViewCard
									vendorApplication={vendorApplication}
									addNote={addNote}
								/>
							</Grid>
							{vendorApplication.stripeInvoice &&
								vendorApplication.status === 'invoice_sent' && (
									<Grid item xs={12}>
										<PayStripeInvoiceCard
											stripeInvoice={vendorApplication.stripeInvoice}
										/>
									</Grid>
								)}
						</Grid>
					</>
				)}
			</Container>
		</PageContainer>
	);
};
