import React, { useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { authGet } from '../../../auth/helpers/AuthRequests';

const AcceptedAppItem = ({ item }) => {
	return (
		<>
			<ListItemButton
				to={`vendor/${item.vendorId}/vendor-application/${item.id}`}
				component={Link}>
				<ListItemText
					primary={
						<Typography variant="h5">{`Application accepted for ${item.conEvent.name}`}</Typography>
					}
					secondary={
						<>
							<Typography
								component="span"
								variant="body2"
								color="text.primary"></Typography>
						</>
					}
				/>
			</ListItemButton>
			<Divider />
		</>
	);
};

const ExhibitingItem = ({ item }) => {
	return (
		<>
			<ListItemButton>
				<ListItemText
					primary={
						<Typography variant="h5">{`Exhibiting at ${item.conEvent.name}`}</Typography>
					}
					secondary={
						<>
							<Typography component="span" variant="body2" color="text.primary">
								Event starts{' '}
								{dayjs(item.conEvent.startTime).format(
									'ddd MMM DD YYYY [at] h:mm A',
								)}
							</Typography>
						</>
					}
				/>
			</ListItemButton>
			<Divider />
		</>
	);
};

export default () => {
	const [items, setItems] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const { acceptedApplications, exhibiting } = await authGet(
				`/api/vendors/recent_updates`,
			);
			setItems([
				...acceptedApplications.map(item => ({
					type: 'acceptedApp',
					item,
				})),
				...exhibiting.map(item => ({
					type: 'exhibiting',
					item,
				})),
			]);
		};
		fetchData().then();
	}, []);

	if (!items) return;

	const listItems = items.map(({ type, item }) => {
		switch (type) {
			case 'acceptedApp':
				return (
					<AcceptedAppItem
						key={`acceptedApp${item.id}`}
						type={type}
						item={item}
					/>
				);
			case 'exhibiting':
				return (
					<ExhibitingItem
						key={`exhibiting${item.id}`}
						type={type}
						item={item}
					/>
				);
			default:
				return null;
		}
	});

	return (
		<Card>
			<CardContent>
				<Typography variant="h3">Recent Updates</Typography>
				<List>{listItems}</List>
			</CardContent>
		</Card>
	);
};
