import React from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { formatUSD } from '../../utils/format';

export default ({ stripeInvoice: { name, amount, breakdown, url } }) => {
	// there are no ides on breakdowns and they will not change order
	const breakdownItems = breakdown.map((item, ind) => (
		<TableRow key={ind}>
			<TableCell>
				{item.itemName} x {item.quantity}
			</TableCell>
			<TableCell align="right">{formatUSD(item.price)}</TableCell>
		</TableRow>
	));

	return (
		<Card>
			<CardContent>
				<Typography variant="h4">{name}</Typography>
				<Box marginBottom={3}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Description</TableCell>
									<TableCell align="right">Price</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{breakdownItems}
								<TableRow>
									<TableCell sx={{ fontWeight: 'bold' }}>TOTAL</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }} align="right">
										{formatUSD(amount)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Button
					onClick={() => {
						window.open(url, '_blank');
					}}
					variant="contained">
					Pay Invoice
				</Button>
			</CardContent>
		</Card>
	);
};
