import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const ExhibitChannelItem = ({
	messageChannel: {
		id,
		exhibitChannel: { vendorExhibit },
	},
	channelMember: { lastMessageAt },
}) => {
	const lastMessageDay = dayjs(lastMessageAt);

	const displayDate =
		dayjs().diff(lastMessageDay, 'day') > 0
			? lastMessageDay.format('MMM D YYYY, hh:mm a')
			: lastMessageDay.fromNow();

	return (
		<TableRow
			hover
			component={Link}
			to={`${id}`}
			sx={{
				height: 80,
				textDecoration: 'none',
			}}>
			<TableCell component="div">
				<Typography>
					{vendorExhibit.vendor.name} exhibiting at{' '}
					{vendorExhibit.conEvent.name}
				</Typography>
			</TableCell>
			<TableCell component="div">
				{lastMessageAt ? displayDate : 'None'}
			</TableCell>
		</TableRow>
	);
};

export default ({ channelMembers, loading }) => {
	const LoadingRow = () => (
		<TableRow component="div">
			<TableCell component="div">
				<Skeleton variant="rectangular" width="100%" height={40} />
			</TableCell>
			<TableCell component="div">
				<Skeleton variant="rectangular" width="100%" height={40} />
			</TableCell>
		</TableRow>
	);

	const tableContent = !loading ? (
		channelMembers.map(channelMember => (
			<ExhibitChannelItem
				key={channelMember.id}
				channelMember={channelMember}
				messageChannel={channelMember.messageChannel}
			/>
		))
	) : (
		<>
			<LoadingRow />
			<LoadingRow />
			<LoadingRow />
		</>
	);

	return (
		<Card>
			<CardContent>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Typography variant="h4">Message Channels</Typography>
					<Box alignSelf="flex-end"></Box>
				</Box>
				<TableContainer>
					<Table component="div">
						<TableHead component="div">
							<TableRow component="div">
								<TableCell component="div">Channel</TableCell>
								<TableCell component="div">Last Message</TableCell>
							</TableRow>
						</TableHead>
						<TableBody component="div">{tableContent}</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
};
