import { getConfig } from '../../../config/config';

const config = getConfig();

export const uploadCloudinaryImage = async file => {
	const url = `https://api.cloudinary.com/v1_1/${config.cloudinaryCloudName}/upload`;

	const formData = new FormData();
	formData.append('file', file);
	formData.append('upload_preset', 'k1lbllr1');
	const res = await fetch(url, {
		method: 'POST',
		body: formData,
	});
	const json = await res.json();

	return { url: json.secure_url, publicId: json.public_id };
};
