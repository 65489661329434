import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import { getStripeInvoices } from '../services/StripeServices';
import PageContainer from '../../common/components/PageContainer';
import InvoicesCard from '../components/InvoicesCard';

export default () => {
	const [invoices, setInvoices] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const invoiceData = await getStripeInvoices();
			setInvoices(invoiceData);
		};
		fetchData().then();
	}, []);

	return (
		<PageContainer title="Invoices">
			<Container>
				<InvoicesCard invoices={invoices} />
			</Container>
		</PageContainer>
	);
};
