import { authGet } from '../../auth/helpers/AuthRequests';

export const getStripeStatus = async () => {
	return await authGet('/api/payments/status');
};
export const getStripeAccountLink = async () => {
	return await authGet('/api/payments/account_link');
};

export const getStripeInvoices = async () => {
	return await authGet('/api/payments/stripe_invoices');
};

export const getConEventInvoices = async conEventId => {
	return await authGet('/api/payments/con_event_invoices', {
		searchParams: {
			conEventId,
		},
	});
};
