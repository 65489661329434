import React from 'react';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { Instagram } from '@mui/icons-material';

import FormTextField from '../FormTextField';

export default ({ control, namePrefix }) => {
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="subtitle1">Contact</Typography>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label="Website"
					name={`${namePrefix}.website`}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label="Instagram"
					name={`${namePrefix}.instagram`}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Instagram />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label="Youtube"
					name={`${namePrefix}.youtube`}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormTextField
					control={control}
					label="Twitter"
					name={`${namePrefix}.twitter`}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<FormTextField
					control={control}
					label="Tiktok"
					name={`${namePrefix}.tiktok`}
				/>
			</Grid>
		</>
	);
};
