import React, { useCallback, useContext, useState } from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import dayjs from 'dayjs';
import HoursModal from './HoursModal';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { putUpdateSchedule } from '../services/SchedulesService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import DashCard from '../../common/components/DashCard';

dayjs.extend(utc);
dayjs.extend(tz);

const timeZone = dayjs.tz.guess();

const DayItem = ({ startTime, endTime, onClick }) => {
	return (
		<>
			<ListItemButton
				onClick={() => {
					onClick({ startTime, endTime });
				}}>
				<ListItemText
					primary={
						<Typography variant="h6">
							{dayjs.utc(startTime).tz(timeZone).format('ddd MMM DD')}
						</Typography>
					}
					secondary={
						<>
							<Typography component="span" variant="body2" color="text.primary">
								{dayjs.utc(startTime).tz(timeZone).format('h:mm A')} {' - '}
								{dayjs.utc(endTime).tz(timeZone).format('h:mm A')}
							</Typography>
						</>
					}
				/>
			</ListItemButton>
		</>
	);
};

export default ({ schedule, setSchedule }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const [selectedDay, setSelectedDay] = useState(null);

	const handleSave = useCallback(
		async data => {
			const hours = schedule.hours.slice();
			hours[selectedDay] = data;
			try {
				const res = await putUpdateSchedule({
					scheduleId: schedule.id,
					schedule: {
						hours,
					},
				});
				if (res.status === 'success') {
					setSchedule(res.schedule);
				} else {
					showSnackbar({
						message: `Failed updating schedule data. Please try again later`,
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: `Failed updating schedule data. Please try again later`,
					type: 'error',
				});
			}
		},
		[schedule, setSchedule, selectedDay, showSnackbar],
	);

	const content = (
		<List sx={{ overflow: 'auto' }}>
			{schedule.hours.map((hour, ind) => {
				return (
					<DayItem
						key={ind}
						startTime={hour.startTime}
						endTime={hour.endTime}
						onClick={() => setSelectedDay(ind)}
					/>
				);
			})}
		</List>
	);

	return (
		<DashCard title="Daily Hours" size="fit">
			{content}
			<HoursModal
				open={selectedDay !== null}
				startTime={schedule.hours[selectedDay]?.startTime}
				endTime={schedule.hours[selectedDay]?.endTime}
				onClose={() => {
					setSelectedDay(null);
				}}
				handleSave={handleSave}
			/>
		</DashCard>
	);
};
