import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Send } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';

import FormTextField from '../../form/FormTextField';
import { postCreateMessage } from '../services/MessageChannelsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const MessageInput = ({ onSubmit }) => {
	const [loading, setLoading] = useState();
	const { messageChannelId } = useParams();

	const { handleSubmit, control, reset } = useForm();
	const { showSnackbar } = useContext(SnackbarContext);

	const handleCreateMessage = useCallback(
		async data => {
			try {
				setLoading(true);
				const res = await postCreateMessage(
					messageChannelId,
					data.inputMessage,
				);
				if (res.status === 'success') {
					onSubmit();
					reset();
				} else {
					showSnackbar({
						message: 'Error fetching messages, Please try again later (CODE 2)',
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: 'Error fetching messages, Please try again later (CODE 2)',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[messageChannelId, onSubmit, reset, showSnackbar],
	);

	return (
		<form onSubmit={handleSubmit(handleCreateMessage)}>
			<Box display="flex" alignItems="center" justifyContent="center">
				<FormTextField
					control={control}
					label=""
					name="inputMessage"
					multiline
				/>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					sx={{ mr: 2, ml: 2 }}
					type="submit"
					disabled={loading}>
					<Send fontSize="large" />
				</IconButton>
			</Box>
		</form>
	);
};

export default MessageInput;
