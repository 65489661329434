import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import ConContext from '../contexts/ConContext';
import UpcomingConEventCard from '../components/UpcomingConEventCard';
import ExpiredConEventsCard from '../components/ExpiredConEventsCard';
import PageContainer from '../../common/components/PageContainer';
import ConBrandSubNav from '../../con-brands/components/ConBrandSubNav';

export default () => {
	const { conBrands, loading } = useContext(ConContext);
	const { id } = useParams();

	const conBrand = conBrands.find(cb => cb.id.toString() === id);

	if (!conBrand) {
		if (loading) return null;
		return <Navigate to="/" />;
	}

	return (
		<PageContainer title={conBrand?.name}>
			<ConBrandSubNav value="overview" conBrandId={id} />
			<Container>
				<Grid container spacing={2}>
					<Typography variant="h2">{conBrand.name}</Typography>
					<Grid item xs={12}>
						<UpcomingConEventCard conBrandId={conBrand.id} />
					</Grid>
					<Grid item xs={12}>
						<ExpiredConEventsCard conBrandId={conBrand.id} />
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
