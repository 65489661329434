import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageHeaderText from '../../common/components/PageHeaderText';
import PageContainer from '../../common/components/PageContainer';
import { getPublicConEvent } from '../../cons/services/ConService';
import HotelList from '../components/HotelList';

export default () => {
	const { conEventId, title } = useParams();

	const [conEvent, setConEvent] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const { conEvent } = await getPublicConEvent(conEventId, {});
			setConEvent(conEvent);
		};
		fetchData().then();
	}, [conEventId]);

	return (
		<PageContainer title={`${conEvent?.name || title} Hotels`}>
			<Container>
				<PageHeaderText>Hotels</PageHeaderText>
				<HotelList hotels={conEvent?.publicInfo?.hotels?.hotels} />
			</Container>
		</PageContainer>
	);
};
