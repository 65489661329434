import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';

const FormDatePicker = ({
	control,
	name,
	label,
	inputId,
	type,
	pickerParams,
}) => {
	let PickerComponent = DatePicker;
	if (type === 'datetime') {
		PickerComponent = DateTimePicker;
	}
	const [open, setOpen] = useState(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Controller
				name={name}
				control={control}
				defaultValue={null}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					let displayDate = null;
					if (value)
						displayDate = dayjs(value, 'YYYY-MM-DDTHH:mm').isValid()
							? dayjs(value).format('YYYY-MM-DDTHH:mm')
							: value;
					return (
						<PickerComponent
							label={label}
							value={displayDate}
							open={open}
							onClose={() => setOpen(false)}
							onChange={value => {
								if (dayjs(value, 'YYYY-MM-DDTHH:mm').isValid())
									onChange(value.toISOString());
								else onChange(value);
							}}
							renderInput={params => (
								<TextField
									{...params}
									error={!!error}
									helperText={error?.message}
									id={inputId}
									fullWidth
									color="primary"
									sx={{
										marginBottom: 2,
									}}
									onClick={e => {
										setOpen(true);
									}}
								/>
							)}
							{...pickerParams}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
};

export default FormDatePicker;
