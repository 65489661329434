import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import UserContext from '../../auth/contexts/UserContext';
import { authGet } from '../../auth/helpers/AuthRequests';

const ConContext = createContext({});

export const ConProvider = ({ children }) => {
	const [conBrands, setConBrands] = useState([]);
	const [loading, setLoading] = useState(true);
	const { user } = useContext(UserContext);
	const refreshConBrands = useCallback(() => {
		const fetchConBrands = async () => {
			setLoading(true);
			const conBrands = await authGet('/api/cons/con_brands');
			setConBrands(conBrands);
			setLoading(false);
		};
		fetchConBrands().then();
	}, []);
	useEffect(() => {
		if (!user?.id) return;
		refreshConBrands();
	}, [user?.id, refreshConBrands]);

	return (
		<ConContext.Provider
			value={{
				conBrands,
				refreshConBrands,
				loading,
			}}>
			{children}
		</ConContext.Provider>
	);
};

export default ConContext;
