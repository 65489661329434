import React from 'react';
import { Container } from '@mui/material';

import ConBrandForm from '../components/ConBrandForm';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	return (
		<PageContainer title="Create New Brand">
			<Container>
				<ConBrandForm />
			</Container>
		</PageContainer>
	);
};
