import { authGet, authPost, authPut } from '../../auth/helpers/AuthRequests';
import { uploadCloudinaryImage } from '../../common/services/CloudinaryService';

export const getConEventVendorTypes = async id => {
	return await authGet(`/api/cons/con_events/${id}/vendor_types`);
};

export const postAddQuickVendor = async (conEventId, data) => {
	return await authPost(`/api/cons/con_events/${conEventId}/quick_vendor`, {
		quickVendor: data.quickVendor,
		vendorType: data.vendorType,
		boothNumber: data.boothNumber,
		vendorTypeId: data.vendorTypeId,
	});
};

export const putUpdatePublicInfo = async (conEventId, data) => {
	return await authPut(
		`/api/cons/con_events/${conEventId}/update_public_info`,
		{
			publicInfo: {
				...data,
			},
		},
	);
};

export const putUpdatePublicInfoHotels = async (conEventId, data) => {
	const finalHotels = await Promise.all(
		data.hotels.map(async hotel => {
			if (hotel.image.changed) {
				const { url, publicId } = await uploadCloudinaryImage(hotel.image.file);
				return {
					...hotel,
					image: { imageUrl: url, publicId },
				};
			} else {
				return hotel;
			}
		}),
	);

	const finalData = {
		publicInfo: {
			hotels: {
				...data,
				hotels: finalHotels,
			},
		},
	};

	return await authPut(
		`/api/cons/con_events/${conEventId}/update_public_info`,
		finalData,
	);
};

export const putUpdatePublicInfoSponsors = async (conEventId, data) => {
	const finalSponsors = await Promise.all(
		data.sponsors.map(async sponsor => {
			if (sponsor.image.changed) {
				const { url, publicId } = await uploadCloudinaryImage(
					sponsor.image.file,
				);
				return {
					...sponsor,
					image: { imageUrl: url, publicId },
				};
			} else {
				return sponsor;
			}
		}),
	);

	const finalData = {
		publicInfo: {
			sponsors: {
				...data,
				sponsors: finalSponsors,
			},
		},
	};

	return await authPut(
		`/api/cons/con_events/${conEventId}/update_public_info`,
		finalData,
	);
};
