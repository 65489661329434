import React, { useCallback, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Divider, FormHelperText } from '@mui/material';
import { useForm } from 'react-hook-form';

import { updateConEvent } from '../../cons/services/ConService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import FormTextField from '../../form/FormTextField';

export default ({ conEvent }) => {
	const [loading, setLoading] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	const { handleSubmit, control, reset } = useForm({
		defaultValues: conEvent?.publicInfo,
	});

	useEffect(() => {
		reset(conEvent?.publicInfo);
	}, [conEvent, reset]);

	const handleUpdatePublicInfo = useCallback(
		async data => {
			setLoading(true);
			try {
				const { status } = await updateConEvent(conEvent.id, {
					...conEvent,
					publicInfo: data,
				});

				if (status === 'success') {
					showSnackbar({
						message: `Public info successfully saved!`,
						type: 'success',
					});
				} else {
					showSnackbar({
						message: `Failed saving. Please try again later`,
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: `Failed saving. Please try again later`,
					type: 'error',
				});
			} finally {
				setLoading(false);
			}
		},
		[conEvent, showSnackbar, setLoading],
	);

	return (
		<form onSubmit={handleSubmit(handleUpdatePublicInfo)}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Description"
						name="description"
						multiline
						minRows={3}
						maxRows={10}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField control={control} label="Subtitle" name="subtitle" />
					<FormHelperText>
						One liner to catch attendee's interests
					</FormHelperText>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Application Info"
						name="applicationInfo"
						multiline
						minRows={3}
						maxRows={10}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						label="Ticket Link"
						name="ticketLink"
					/>
					<FormHelperText>
						If you event is ticketed, link to where attendees can buy tickets
					</FormHelperText>
				</Grid>
				<Grid item xs={12}>
					<Button type="submit" variant="contained" disabled={loading}>
						Save
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
