import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AllVendorsModal from './AllVendorsModal';
import VendorListItem from './VendorListItem';

export default ({ vendors }) => {
	const [showAllVendors, setShowAllVendors] = useState(false);

	const [previewVendors, setPreviewVendors] = useState([]);
	useEffect(() => {
		const shuffled = vendors.slice().sort(() => 0.5 - Math.random());
		setPreviewVendors(shuffled.slice(0, 3));
	}, [vendors]);

	const vendorPreviewItems = useMemo(() => {
		return previewVendors.map(vendor => (
			<VendorListItem key={vendor.id} vendor={vendor} />
		));
	}, [previewVendors]);

	return (
		<Box pt={2} mb={3}>
			<Typography variant="h6" gutterBottom>
				Vendors
			</Typography>
			<Typography>{vendors.length} Vendors Confirmed!</Typography>
			<Box display="flex" flexDirection="column" justifyContent="center">
				{vendorPreviewItems}
				<Button onClick={() => setShowAllVendors(true)} variant="outlined">
					Show All
				</Button>
			</Box>
			<AllVendorsModal
				open={showAllVendors}
				onClose={() => setShowAllVendors(false)}
				vendors={vendors}
			/>
		</Box>
	);
};
