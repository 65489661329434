import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import VendorApplicationViewCard from '../components/VendorApplicationViewCard';
import {
	decideVendorApplication,
	fetchVendorApplication,
} from '../../vendors/services/VendorsService';
import AddVendorExhibitCard from '../components/AddVendorExhibitCard';
import InvoicePreviewCard from '../components/InvoicePreviewCard';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import PageContainer from '../../common/components/PageContainer';
import { postAddNode } from '../../vendors/services/VendorApplicationsService';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';

export default () => {
	const { id, conBrandId, conEventId } = useParams();
	const [vendorApplication, setVendorApplication] = useState();
	const [loading, setLoading] = useState(true);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(async () => {
		setLoading(true);

		const vendorApplication = await fetchVendorApplication(id);
		setVendorApplication(vendorApplication);
		setLoading(false);
	}, [id]);

	useEffect(() => {
		fetchData().then();
	}, [fetchData]);

	const decideApplication = useCallback(
		async action => {
			const { status, vendorApplication: newApp } =
				await decideVendorApplication(id, action);
			if (status === 'success') {
				showSnackbar({ message: `Application ${action}`, type: 'success' });
				setVendorApplication({
					...vendorApplication,
					...newApp,
				});
			} else {
				showSnackbar({
					message: `Operation failed, try again later`,
					type: 'error',
				});
			}
		},
		[id, showSnackbar, setVendorApplication, vendorApplication],
	);

	const addNote = useCallback(
		async message => {
			const { status, vendorApplication: newApp } = await postAddNode(
				id,
				message,
			);

			if (status === 'success') {
				showSnackbar({ message: `Note added`, type: 'success' });
				setVendorApplication({
					...vendorApplication,
					...newApp,
				});
			} else {
				showSnackbar({
					message: `Operation failed, try again later`,
					type: 'error',
				});
			}
		},
		[id, vendorApplication, showSnackbar],
	);

	return (
		<PageContainer
			title={`${vendorApplication?.vendor?.name || 'Vendor'}'s Application`}>
			<ConEventSubNav
				value="applications"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						<Grid container spacing={2}>
							<Typography variant="h3">
								{vendorApplication.vendor.name}'s Application
							</Typography>
							<Grid item xs={12}>
								<VendorApplicationViewCard
									vendorApplication={vendorApplication}
									decideApplication={decideApplication}
									addNote={addNote}
									decider
									conEventId={conEventId}
									conBrandId={conBrandId}
								/>
							</Grid>
							{vendorApplication.status === 'accepted' && (
								<Grid item xs={12}>
									<InvoicePreviewCard
										vendorApplication={vendorApplication}
										refreshData={fetchData}
									/>
								</Grid>
							)}
							{vendorApplication.status === 'paid' && (
								<Grid item xs={12}>
									<AddVendorExhibitCard
										conEventName={vendorApplication.conEvent.name}
										conEventId={vendorApplication.conEventId}
										conBrandId={conBrandId}
										vendorId={vendorApplication.vendorId}
										vendorApplicationId={vendorApplication.id}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
			</Container>
		</PageContainer>
	);
};
