import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import MessageInput from '../components/MessageInput';
import {
	getMessages,
	getShowMessageChannel,
	postViewMessageChannel,
} from '../services/MessageChannelsService';
import MessagesList from '../components/MessagesList';
import PageContainer from '../../common/components/PageContainer';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { MessageContext } from '../contexts/MessageContext';

export default () => {
	const { messageChannelId } = useParams();
	const [messageChannel, setMessageChannel] = useState();

	const [messages, setMessages] = useState([]);
	const { showSnackbar } = useContext(SnackbarContext);
	const { updateUnreadChannels } = useContext(MessageContext);

	const fetchMessageChannel = useCallback(async () => {
		const res = await getShowMessageChannel(messageChannelId);
		try {
			if (res.status === 'success') {
				setMessageChannel(res.messageChannel);
			} else {
				showSnackbar({
					message: 'Error fetching messages, Please try again (CODE 1)',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Error fetching messages, Please try again later (CODE 2)',
				type: 'error',
			});
			console.error(e);
		}
	}, [messageChannelId, showSnackbar]);

	const fetchMessages = useCallback(async () => {
		try {
			const res = await getMessages(messageChannelId);
			if (res.status === 'success') {
				setMessages(res.messages);
			} else {
				showSnackbar({
					message: 'Error fetching messages, Please try again later (CODE 3)',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Error fetching messages, Please try again later (CODE 4)',
				type: 'error',
			});
			console.error(e);
		}
	}, [showSnackbar, messageChannelId]);

	useEffect(() => {
		postViewMessageChannel(messageChannelId);
		updateUnreadChannels();
	}, [messages, messageChannelId, updateUnreadChannels]);

	useEffect(() => {
		fetchMessages();
		fetchMessageChannel();
	}, [fetchMessages, fetchMessageChannel, messageChannelId]);

	return (
		<PageContainer viewContextOverride="Konvene" title="Messages" hideFooter>
			<Container>
				<Box
					sx={{
						height: 'calc(100vh - 120px);',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<MessagesList messages={messages} users={messageChannel?.users} />
					<MessageInput onSubmit={() => fetchMessages()} />
				</Box>
			</Container>
		</PageContainer>
	);
};
