import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	CircularProgress,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';

import DashCard from '../../common/components/DashCard';
import { getVendorExhibits } from '../../cons/services/VendorExhibitsService';

const ExhibitItem = ({
	vendorExhibit: {
		conEvent: { name },
		conEventVendorType,
	},
}) => {
	return (
		<ListItemButton>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							{conEventVendorType.name}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ vendor }) => {
	const [exhibits, setExhibits] = useState();

	const lastVendorId = useRef(null);

	useEffect(() => {
		if (lastVendorId.current === vendor.id) return;
		lastVendorId.current = vendor.id;

		getVendorExhibits({
			vendorId: vendor.id,
			limit: 4,
			includeVendorTypes: true,
		}).then(res => {
			setExhibits(res.vendorExhibits);
		});
	}, [vendor, setExhibits]);

	let content = <CircularProgress />;

	if (exhibits) {
		const exhibitItems = exhibits.map(exhibit => {
			return (
				<Fragment key={exhibit.id}>
					<ExhibitItem vendorExhibit={exhibit} />
					<Divider />
				</Fragment>
			);
		});
		content = (
			<>
				<List>{exhibitItems}</List>
				{exhibitItems.length === 0 && (
					<Typography variant="p">You have no upcoming Exhibits</Typography>
				)}
			</>
		);
	}

	return <DashCard title="Upcoming Exhibits">{content}</DashCard>;
};
