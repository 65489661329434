import React from 'react';

import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const FormTextField = ({ control, name, rules, styles, ...props }) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue=""
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<TextField
					value={value}
					onChange={onChange}
					color="primary"
					sx={{
						marginBottom: 2,
						width: '100%',
					}}
					error={!!error}
					helperText={error?.message}
					{...props}
				/>
			)}
		/>
	);
};

export default FormTextField;
