import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

import { fetchUpcomingConEvents } from '../services/ConService';
import DashCard from '../../common/components/DashCard';

const EventItem = ({ event: { id, name, startTime, conBrandId } }) => {
	return (
		<ListItemButton
			component={Link}
			to={`/con-brand/${conBrandId}/con-event/${id}`}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							{dayjs(startTime).format('ddd MMM DD YYYY [at] h:mm A')}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ conBrandId }) => {
	const [events, setEvents] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		fetchUpcomingConEvents(conBrandId).then(upcomingEvents => {
			setEvents(upcomingEvents);
		});
	}, [conBrandId]);

	const eventItems = events.map(e => (
		<Fragment key={e.id}>
			<EventItem event={e} />
			<Divider />
		</Fragment>
	));

	return (
		<DashCard
			title="Upcoming Events"
			right={
				conBrandId !== undefined ? (
					<Button
						onClick={() => {
							navigate(`/con-brand/${conBrandId}/create-con-event`);
						}}
						variant="outlined">
						Create New
					</Button>
				) : null
			}>
			<List sx={{ overflow: 'auto' }}>{eventItems}</List>
			{eventItems.length === 0 && (
				<Box>
					<Typography variant="p">There are no upcoming Events</Typography>
				</Box>
			)}
		</DashCard>
	);
};
