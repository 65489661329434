import React, { useEffect } from 'react';

import Navbar from './navbar/Navbar';
import Footer from './Footer';

export default ({ title, children, viewContextOverride, hideFooter }) => {
	useEffect(() => {
		document.title = title || 'Konvene';
	}, [title]);

	return (
		<div>
			<Navbar viewContextOverride={viewContextOverride} />
			{children}
			{!hideFooter && <Footer />}
		</div>
	);
};
