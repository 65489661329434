import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import { fullViewHeight } from './LoginPage.styles';
import RequestPasswordResetForm from '../components/RequestPasswordResetForm';

export default () => {
	useEffect(() => {
		document.title = 'Reset Password';
	}, []);
	return (
		<Grid container sx={fullViewHeight}>
			<RequestPasswordResetForm />
		</Grid>
	);
};
