export const StatusTypes = {
	pending: {
		label: 'Pending',
		textColor: '#f3f08c',
	},
	accepted: {
		label: 'Accepted',
		textColor: '#d0ff57',
	},
	rejected: {
		label: 'Rejected',
		textColor: '#ff5f5f',
	},
	waitlisted: {
		label: 'Waitlisted',
		textColor: '#ffcd5a',
	},
	invoice_sent: {
		label: 'Invoice Sent',
		textColor: '#a2ff6b',
	},
	paid: {
		label: 'Invoice Paid',
		textColor: '#c0e8aa',
	},
	offsite_payment_submitted: {
		label: 'Payment Receipt Submitted',
		textColor: '#f3f08c',
	},
	confirmed: {
		label: 'Confirmed as Exhibitor',
		textColor: '#9cff99',
	},
};
