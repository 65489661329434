import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';

import FormTextField from '../../form/FormTextField';
import DashCard from '../../common/components/DashCard';
import { putUpdatePublicInfo } from '../../cons/services/ConEventsService';
import FormSwitch from '../../form/FormSwitch';
import { conBrandButton } from '../../cons/components/ConBrandForm.styles';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({});
export default ({ conEvent }) => {
	const [loading, setLoading] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		reset(conEvent?.publicInfo?.rules);
	}, [conEvent, reset]);

	const handleUpdateRules = useCallback(
		async data => {
			setLoading(true);
			try {
				await putUpdatePublicInfo(conEvent.id, {
					rules: data,
				});
				showSnackbar({
					message: 'Rules page successfully saved!',
					type: 'success',
				});
			} catch (e) {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[conEvent, showSnackbar],
	);

	return (
		<DashCard title="Rules" size="fit">
			<form onSubmit={handleSubmit(handleUpdateRules)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormSwitch control={control} name="active" label="Active" />
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							control={control}
							label="Rules Content"
							name="rulesContent"
							multiline
							minRows={3}
							maxRows={3}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							type="submit"
							variant="contained"
							sx={conBrandButton}
							disabled={loading}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
