import React, { useContext } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';

import UserContext from '../../auth/contexts/UserContext';

const MessageItem = ({ message, user, self }) => {
	const displayTime = dayjs(message.createdAt).format(
		'ddd MMM DD YYYY [at] h:mm A',
	);
	return (
		<Box>
			<Box pl={!self ? 12 : 0} pr={self ? 12 : 0} pb={3} mt={1}>
				<Typography variant="subtitle2">{user?.firstName}</Typography>
				<Typography variant="caption">{displayTime}</Typography>
				<Typography style={{ fontSize: 18 }}>{message.content.text}</Typography>
			</Box>
			<Divider />
		</Box>
	);
};

export default ({ messages, users }) => {
	const { user } = useContext(UserContext);

	const messageItems = (messages || []).map(message => {
		const uu = users?.find(u => u.id === message.userId);
		return (
			<MessageItem
				key={message.id}
				message={message}
				user={uu}
				self={uu?.id === user?.id}
			/>
		);
	});

	return (
		<Box
			flex={1}
			display="flex"
			flexDirection="column-reverse"
			mb={3}
			sx={{
				overflowY: 'scroll',
			}}>
			{messageItems}
		</Box>
	);
};
