import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	useRef,
} from 'react';

import { authGet } from '../helpers/AuthRequests';

const UserContext = createContext({});

const persistedAccessToken = localStorage.getItem('accessToken');
const persistedRefreshToken = JSON.parse(localStorage.getItem('refreshToken'));

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState();
	const [ready, setReady] = useState(false);

	const updateUser = useCallback(
		user => {
			setUser(user);
			if (!ready) setReady(true);
		},
		[setUser, ready, setReady],
	);

	const destroyCredentials = useCallback(() => {
		setUser(null);
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
	}, [setUser]);

	useEffect(() => {
		if (persistedAccessToken && persistedRefreshToken) {
		} else setReady(true);
	}, []);

	const lastLoadedUserId = useRef(null);

	const fetchUser = useCallback(async () => {
		const userInfo = await authGet('/api/users');
		updateUser(userInfo);
		lastLoadedUserId.current = userInfo.id;
	}, [updateUser]);

	useEffect(() => {
		if (
			!persistedRefreshToken?.userId ||
			persistedRefreshToken.userId === lastLoadedUserId
		)
			return;
		fetchUser().then();
	}, [fetchUser]);

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				updateUser,
				fetchUser,
				destroyCredentials,
				ready,
			}}>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
