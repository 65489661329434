import React from 'react';

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Link,
	Typography,
} from '@mui/material';

const HotelItem = ({
	hotel: { image, name, description, bookingLink },
	imageHeight,
}) => {
	return (
		<Card>
			<CardMedia
				sx={{ height: imageHeight || 400 }}
				image={image.imageUrl}
				title={name}
			/>
			<CardContent>
				<Typography gutterBottom variant="h5" component="div">
					{name}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{description}
				</Typography>
			</CardContent>
			<CardActions>
				<Button
					size="small"
					component={Link}
					href={bookingLink}
					target="_blank">
					Book
				</Button>
			</CardActions>
		</Card>
	);
};

export default ({ hotels }) => {
	if (hotels?.length === 1) {
		return <HotelItem hotel={hotels[0]} imageHeight={800} />;
	}

	const items = (hotels || []).map((hotel, ind) => (
		<Grid item xs={6}>
			<HotelItem key={ind} hotel={hotel} />
		</Grid>
	));

	return (
		<Grid container spacing={2}>
			{items}
		</Grid>
	);
};
