import React from 'react';
import { Typography } from '@material-ui/core';

export default ({ contact }) => {
	if (!contact) return null;
	const content = Object.entries(contact)
		.filter(([_, value]) => value)
		.map(([key, value]) => (
			<Typography key={key}>
				{key}: {value}
			</Typography>
		));

	return content;
};
