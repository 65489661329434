import React, { useContext } from 'react';
import { Container } from '@mui/material';

import UserContext from '../../auth/contexts/UserContext';
import VendorDashboard from './components/VendorDashboard';
import OwnerDashboard from './components/OwnerDashboard';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const { user } = useContext(UserContext);

	let content = null; //default to no user later
	switch (user?.primaryType) {
		case 'vendor':
			content = <VendorDashboard />;
			break;
		case 'owner':
			content = <OwnerDashboard />;
			break;
		default:
	}

	return (
		<PageContainer>
			<Container>{content}</Container>
		</PageContainer>
	);
};
