import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, Grid, Link } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

import {
	authBox,
	authButton,
	authForm,
	AuthInput,
} from '../../auth/components/AuthForms.styles';
import FormCheckbox from '../../form/FormCheckbox';
import UserContext from '../../auth/contexts/UserContext';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { saveTokens } from '../../auth/helpers/AuthRequests';
import { registerUser } from '../../auth/services/AuthService';

const formSchema = Yup.object().shape({
	email: Yup.string().email().required('Email is required'),
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string().oneOf(
		[Yup.ref('password')],
		'Passwords do not match',
	),
});

export default ({ vendor, toggleType }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({ resolver: yupResolver(formSchema) });

	const { fetchUser } = useContext(UserContext);

	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const handleRegister = async data => {
		try {
			setLoading(true);
			const resp = await registerUser({
				user: {
					...data,
					primaryType: 'vendor',
					confirmPassword: undefined,
				},
			});

			const { accessToken, refreshToken } = resp;

			saveTokens({
				accessToken,
				refreshToken,
			});

			await fetchUser();
		} catch (e) {
			showSnackbar({
				message: 'Registration failed, please try again later',
				type: 'error',
			});
		}
		setLoading(false);
	};

	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<Card sx={authBox}>
				<h1>Sign Up to claim {vendor?.name}</h1>
				<form style={authForm} onSubmit={handleSubmit(handleRegister)}>
					<Grid item xs={12}>
						<AuthInput
							control={control}
							label="Email"
							name="email"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIcon />
									</InputAdornment>
								),
							}}
							error={!!errors.email}
							helperText={errors.email?.message}
						/>
					</Grid>
					<Grid item xs={12}>
						<AuthInput
							control={control}
							label="First Name"
							name="firstName"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
							error={!!errors.firstName}
							helperText={errors.firstName?.message}
						/>
					</Grid>

					<Grid item xs={12}>
						<AuthInput
							control={control}
							label="Last Name"
							name="lastName"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<AuthInput
							control={control}
							label="Password"
							name="password"
							type="password"
							error={!!errors.password}
							helperText={errors.password?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<AuthInput
							control={control}
							label="Confirm Password"
							name="confirmPassword"
							type="password"
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormCheckbox
							control={control}
							name="preferences.alertEmailEnabled"
							label="I would like to receive updates via email."
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							type="submit"
							variant="contained"
							sx={authButton}
							disabled={loading}>
							Create Your Account
						</Button>
					</Grid>
				</form>
				<CardActions>
					<Grid mt={4}>
						Already have an account? Login{' '}
						<b>
							<Link onClick={toggleType} underline="none">
								here
							</Link>
						</b>
						.
					</Grid>
				</CardActions>
			</Card>
		</Grid>
	);
};
