import React, { useEffect, useState } from 'react';
import { Card, CardContent, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import { fetchConEvent } from '../../cons/services/ConService';
import ConEventPublicInfoForm from '../components/ConEventPublicInfoForm';
import ConEventSubNav from '../components/ConEventSubNav';
import PublicInfoSubNav from '../components/PublicInfoSubNav';

export default () => {
	const { conEventId, conBrandId } = useParams();
	const [conEvent, setConEvent] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const conEvent = await fetchConEvent(conEventId);
			setConEvent(conEvent);
		};
		fetchData().then();
	}, [conEventId]);

	const content = (
		<Card>
			<CardContent>
				<h1>Public Info</h1>

				<ConEventPublicInfoForm conEvent={conEvent} editing />
			</CardContent>
		</Card>
	);

	return (
		<PageContainer title="Edit Public Info">
			<ConEventSubNav
				value="editPublicInfo"
				conBrandId={conBrandId}
				conEventId={conEventId}
			/>
			<PublicInfoSubNav
				value="content"
				conBrandId={conBrandId}
				conEventId={conEventId}
			/>
			<Container>{content}</Container>
		</PageContainer>
	);
};
