import React, { useState } from 'react';
import { Box, Card, CardContent, Link, Modal } from '@mui/material';
import { Typography } from '@material-ui/core';

import QuickRegisterForm from './QuickRegisterForm';
import { modalContainer } from '../../form/components/ModalContainer.styles';
import QuickLoginForm from './QuickLoginForm';

export default ({ open, onClose, onSuccess }) => {
	const [mode, setMode] = useState('register');

	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent>
						<Typography variant="h3" style={{ marginBottom: 5 }}>
							Login or Sign up to Follow
						</Typography>
						<Box mb={2}>
							<b>
								<Link
									onClick={() => {
										setMode(mode === 'register' ? 'login' : 'register');
									}}
									underline="none">
									{mode === 'register'
										? 'I already have an account'
										: 'Register'}
								</Link>
							</b>
						</Box>
						{mode === 'register' ? (
							<QuickRegisterForm onSuccess={onSuccess} />
						) : (
							<QuickLoginForm onSuccess={onSuccess} />
						)}
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
