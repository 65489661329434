import { styled } from '@mui/material';
import FormTextField from '../../form/FormTextField';
import FormSelect from '../../form/FormSelect';

export const AuthInput = styled(FormTextField)({
	marginBottom: 20,
});

export const AuthSelect = styled(FormSelect)({
	marginBottom: 20,
});

export const authBox = {
	width: 500,
	border: 1,
	borderRadius: '16px',
	padding: '4rem',
};

export const authBoxSmall = {
	width: 500,
	border: 1,
	borderRadius: '16px',
	padding: '4rem',
};

export const authForm = {
	display: 'flex',
	flexDirection: 'column',
};

export const authButton = {
	borderRadius: '20px',
};
