import React from 'react';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography,
} from '@mui/material';

export default ({ title, right, bottom, children, size }) => {
	const titleContent = title ? (
		<>
			<Box sx={{ flexDirection: 'row', display: 'flex' }}>
				<Box sx={{ flex: 1 }}>
					<Typography variant="h4">{title}</Typography>
				</Box>
				<Box>{right}</Box>
			</Box>
			<Divider />
		</>
	) : null;

	let height;
	switch (size) {
		case 'short':
			height = 200;
			break;
		case 'large':
			height = 900;
			break;
		case 'max':
			height = '100%';
			break;
		case 'fit':
			break;
		default:
			height = 450;
			break;
	}

	return (
		<Card sx={{ height }}>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}>
					{titleContent}
					{children}
				</CardContent>
				{bottom && <CardActions>{bottom}</CardActions>}
			</Box>
		</Card>
	);
};
