import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default ({ conBrandId, value }) => {
	const navigate = useNavigate();

	const handleChange = (_, value) => {
		switch (value) {
			case 'overview':
				navigate(`/con-brand/${conBrandId}`);
				break;
			case 'editProfileImage':
				navigate(`/con-brand/${conBrandId}/edit-profile-image`);
				break;
			case 'venues':
				navigate(`/con-brand/${conBrandId}/venues`);
				break;
			case 'info':
				navigate(`/con-brand/${conBrandId}/edit-info`);
				break;
			default:
				break;
		}
	};
	return (
		<Box display="flex" justifyContent="center" mb={1}>
			<Tabs value={value} aria-label="Event navigation" onChange={handleChange}>
				<Tab value="overview" label="Overview" />
				<Tab value="editProfileImage" label="Profile Image" />
				<Tab value="venues" label="Venues" />
				<Tab value="info" label="Info" />
			</Tabs>
		</Box>
	);
};
