import React from 'react';
import {
	Box,
	Card,
	CardContent,
	IconButton,
	Modal,
	Typography,
} from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { Launch } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { modalContainer } from '../../form/components/ModalContainer.styles';
import { getConfig } from '../../../config/config';
import { fixUrl } from '../../utils/urls';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

export default ({ sponsor, onClose }) => {
	const img = cld.image(sponsor?.image?.publicId).format('png');

	return (
		<Modal open={!!sponsor} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card>
					<Box
						ml={2}
						display="flex"
						alignItems="center"
						justifyContent="space-between">
						<Typography
							variant="h5"
							style={{
								marginLeft: 5,
							}}>
							{sponsor?.name}
						</Typography>
						{sponsor?.link && (
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								type="submit"
								component={Link}
								target="_blank"
								to={fixUrl(sponsor?.link)}>
								<Launch fontSize="large" />
							</IconButton>
						)}
					</Box>
					<CardContent>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							mt={2}>
							<AdvancedImage
								style={{
									objectFit: 'contain',
									height: 400,
									marginBottom: 10,
								}}
								cldImg={img}
							/>
							<Typography variant="p">{sponsor?.description}</Typography>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
