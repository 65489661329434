import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import DashCard from '../../../common/components/DashCard';

export default () => {
	return (
		<DashCard
			title="Payments not ready"
			size="short"
			bottom={
				<Button variant="contained" component={Link} to="payments">
					Set up payments
				</Button>
			}>
			Set up Stripe to accept payments.
		</DashCard>
	);
};
