import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import { getConEventInvoices } from '../../payments/services/StripeServices';
import InvoicesCard from '../../payments/components/InvoicesCard';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import ConEventSubNav from '../components/ConEventSubNav';

export default () => {
	const { conBrandId, id } = useParams();
	const { showSnackbar } = useContext(SnackbarContext);

	const [invoices, setInvoices] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const { status, invoices: newInvoices } = await getConEventInvoices(id);
			if (status === 'success') {
				setInvoices(newInvoices);
			} else {
				showSnackbar({
					message: `An error occurred. Please try again later`,
					type: 'error',
				});
			}
		};
		fetchData().then();
	}, [setInvoices, id, showSnackbar]);

	return (
		<PageContainer title="Event Invoices">
			<ConEventSubNav
				value="invoices"
				conEventId={id}
				conBrandId={conBrandId}
			/>
			<Container>
				<InvoicesCard invoices={invoices} />
			</Container>
		</PageContainer>
	);
};
