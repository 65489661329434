import React, { Fragment, useState } from 'react';
import {
	Button,
	Divider,
	List,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';
import AddOnModal from '../../con-events/components/addons/AddOnModal';

const AddOnItem = ({ name, onClick }) => {
	return (
		<ListItemButton onClick={onClick}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography
							component="span"
							variant="body2"
							color="text.primary"></Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

const ConEventAddOnCard = ({ conEvent: { conEventAddOns } }) => {
	const [showAddOn, setShowAddon] = useState(null);

	const navigate = useNavigate();

	const path = 'create-con-addon';

	const content = (
		<List sx={{ overflow: 'auto', maxHeight: '100%' }}>
			{conEventAddOns.map(addon => (
				<Fragment key={addon.id}>
					<AddOnItem
						name={addon.name}
						onClick={() => {
							setShowAddon(addon);
						}}
					/>
					<Divider />
				</Fragment>
			))}
		</List>
	);

	return (
		<DashCard
			title="Add Ons"
			right={
				<Button
					variant="outlined"
					onClick={() => {
						navigate(path);
					}}>
					Create add on
				</Button>
			}>
			<Divider />
			{content}
			<AddOnModal addOnId={showAddOn?.id} onClose={() => setShowAddon(null)} />
		</DashCard>
	);
};

export default ConEventAddOnCard;
