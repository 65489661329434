import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';

import { getMessageChannels } from '../services/MessageChannelsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import PageContainer from '../../common/components/PageContainer';
import MessageChannelsList from '../components/MessageChannelsList';

export default () => {
	const [loading, setLoading] = useState(false);
	const [channelMembers, setChannelMembers] = useState([]);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(
		async page => {
			setLoading(true);
			try {
				const res = await getMessageChannels();
				if (res.status === 'success') {
					setChannelMembers(res.channelMembers);
				} else {
					showSnackbar({
						message: 'Error fetching data, Try again later',
						type: 'error',
					});
				}
			} catch (e) {
				console.error('reee', e);
			}
			setLoading(false);
		},
		[setLoading, showSnackbar],
	);

	useEffect(() => {
		fetchData().then();
	}, [fetchData]);

	return (
		<PageContainer title="Messages">
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<MessageChannelsList
							channelMembers={channelMembers}
							loading={loading}
						/>
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
