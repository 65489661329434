import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { getVendorExhibits } from '../../cons/services/VendorExhibitsService';
import DashCard from '../../common/components/DashCard';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { Link } from 'react-router-dom';

export default ({ vendorId }) => {
	const [exhibits, setExhibits] = useState();
	const [pagData, setPagData] = useState({ pages: 1, page: 1 });
	const [loading, setLoading] = useState(false);
	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(
		async page => {
			try {
				setLoading(true);
				const res = await getVendorExhibits({
					vendorId,
					page,
				});
				if (res.status === 'success') {
					setExhibits(res.vendorExhibits);
					setPagData(res.pagyInfo);
				} else {
					showSnackbar({
						message: 'Error fetching data, Try again later',
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: 'Error fetching data, Try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[setLoading, vendorId, setExhibits, setPagData, showSnackbar],
	);

	useEffect(() => {
		fetchData().then();
	}, [fetchData]);

	let tableContent = null;

	if (exhibits) {
		tableContent = exhibits.map(exhibit => (
			<TableRow key={exhibit.id} hover onClick={() => {}}>
				<TableCell>{exhibit.conEvent.name}</TableCell>
				<TableCell>{exhibit.conEventVendorType.name}</TableCell>
				<TableCell>{exhibit.boothNumber}</TableCell>
				<TableCell>{exhibit.confirmationCode}</TableCell>
				<TableCell>
					{exhibit.exhibitChannel && (
						<Button
							variant="contained"
							component={Link}
							to={`/messages/${exhibit.exhibitChannel.messageChannelId}`}>
							Message
						</Button>
					)}
				</TableCell>
			</TableRow>
		));
	}

	return (
		<DashCard
			title="Exhibiting Events"
			size="max"
			bottom={
				<Box
					display="flex"
					justifyContent="center"
					mt={2}
					pb={1}
					width={'100%'}>
					<Pagination
						count={pagData?.pages}
						page={pagData?.page}
						onChange={(_, value) => {
							if (value !== pagData?.page) fetchData(value).then();
						}}
						size="large"
						disabled={loading}
					/>
				</Box>
			}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Event</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Booth</TableCell>
							<TableCell>Confirmation</TableCell>
							<TableCell>Message</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{tableContent}</TableBody>
				</Table>
			</TableContainer>
		</DashCard>
	);
};
