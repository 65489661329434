import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	AuthInput,
	authBoxSmall,
	authForm,
	authButton,
} from './components/AuthForms.styles';
import { Box, Button, Card, CardActions, Link } from '@mui/material';
import { loginUser } from './services/AuthService';
import UserContext from './contexts/UserContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { saveTokens } from './helpers/AuthRequests';
import SnackbarContext from '../snackbar/context/SnackbarContext';

export default () => {
	const { handleSubmit, control } = useForm();
	const { fetchUser } = useContext(UserContext);

	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);

	const [searchParams] = useSearchParams();
	const goto = searchParams.get('goto');

	const handleLogin = async data => {
		try {
			setSubmitting(true);
			const { accessToken, refreshToken } = await loginUser({
				user: { email: data.email, password: data.password },
			});

			saveTokens({ accessToken, refreshToken });

			await fetchUser();

			if (goto) navigate(goto);
			else navigate('/');
		} catch (e) {
			// TODO don't handle this by throwing
			console.error('login error', e);
			showSnackbar({ message: 'Login Failed', type: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<Grid item>
				<Card sx={authBoxSmall}>
					<h1>Login</h1>
					<form style={authForm} onSubmit={handleSubmit(handleLogin)}>
						<AuthInput
							control={control}
							label="Email"
							name="email"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIcon />
									</InputAdornment>
								),
							}}
						/>
						<AuthInput
							control={control}
							label="Password"
							name="password"
							type="password"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
						/>
						<Box mb={3}>
							<Link
								href=""
								onClick={() => navigate('/request-password-reset')}
								underline="none">
								<b>Forgot your password?</b>
							</Link>
						</Box>
						<Button
							sx={authButton}
							type="submit"
							variant="contained"
							disabled={submitting}>
							Login
						</Button>
					</form>
					<CardActions>
						<Grid mt={4}>
							Create a free account{' '}
							<b>
								<Link
									href=""
									onClick={() => navigate('/register')}
									underline="none">
									here
								</Link>
							</b>
							.
						</Grid>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};
