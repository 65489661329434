import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { getAttractions } from '../services/AttractionsServices';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import AttractionsCard from '../components/AttractionsCard';
import PublicInfoSubNav from '../../con-events/components/PublicInfoSubNav';
import { Container } from '@mui/material';

export default () => {
	const { conEventId, conBrandId } = useParams();

	const { showSnackbar } = useContext(SnackbarContext);

	const [attractions, setAttractions] = useState();

	const fetchAttractions = useCallback(async () => {
		try {
			const res = await getAttractions(conEventId);

			if (res.status === 'success') {
				setAttractions(res.attractions);
			} else {
				showSnackbar({
					message: `Failed fetching attraction data. Please try again later`,
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: `Failed fetching attraction data. Please try again later`,
				type: 'error',
			});
		}
	}, [conEventId, showSnackbar]);

	useEffect(() => {
		fetchAttractions().then();
	}, [fetchAttractions]);

	return (
		<PageContainer>
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="attractions"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				<AttractionsCard attractions={attractions} />
			</Container>
		</PageContainer>
	);
};
