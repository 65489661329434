import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Box, Button } from '@mui/material';
import { Typography } from '@material-ui/core';

import DashCard from '../../common/components/DashCard';

export default ({ onSubmit }) => {
	const [image, setImage] = useState();

	return (
		<DashCard size="max" title="Upload your profile picture">
			<Box pt={3}>
				<Typography>
					Upload a profile picture that everyone will see! Usually this will be
					a logo
				</Typography>
				<Box display="flex" justifyContent="center" mb={2} mt={3}>
					<ImageUploading
						value={[image]}
						onChange={data => {
							setImage(data[0]);
						}}
						dataURLKey="data_url">
						{({ onImageUpload, dragProps }) => (
							<Box
								width={300}
								height={300}
								display="flex"
								alignItems="center"
								justifyContent="center"
								border={1}
								onClick={onImageUpload}
								{...dragProps}>
								{image ? (
									<>
										<img
											style={{
												width: 'auto',
												height: 'auto',
												maxWidth: '100%',
												maxHeight: '100%',
											}}
											src={image.data_url}
											alt="selected"
										/>
									</>
								) : (
									<Box
										sx={{
											flex: 1,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<Typography style={{ userSelect: 'none' }}>
											{' '}
											Click or Drag photo here
										</Typography>
									</Box>
								)}
							</Box>
						)}
					</ImageUploading>
				</Box>
				<Button variant="outlined" onClick={() => onSubmit(image)}>
					Save Profile Picture
				</Button>
			</Box>
		</DashCard>
	);
};
