import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RegisterPage from './modules/auth/pages/RegisterPage';
import LoginPage from './modules/auth/pages/LoginPage';
import HomePage from './modules/home/pages/HomePage';
import CreateConBrandPage from './modules/cons/pages/CreateConBrandPage';
import ConBrandPage from './modules/cons/pages/ConBrandPage';
import CreateConEventPage from './modules/cons/pages/CreateConEventPage';
import CreateConEventAddOnPage from './modules/cons/pages/CreateConEventAddOnPage';
import ConEventPage from './modules/cons/pages/ConEventPage';
import CreateVendorApplicationPage from './modules/vendors/pages/CreateVendorApplicationPage';
import CreateVendorPage from './modules/vendors/pages/CreateVendorPage';
import VendorPage from './modules/vendors/pages/VendorPage';
import ViewApplicationPage from './modules/cons/pages/ViewApplicationPage';
import AuthenticatedRoute from './modules/auth/components/AuthenticatedRoute';
import VendorViewApplicationPage from './modules/vendors/pages/VendorViewApplicationPage';
import VendorApplyLandingPage from './modules/vendors/pages/VendorApplyLandingPage';
import ExhibitorsPage from './modules/cons/pages/ExhibitorsPage';
import PreferencesPage from './modules/user/pages/PreferencesPage';
import EmailUnsubscribePage from './modules/user/pages/EmailUnsubscribePage';
import PaymentsPage from './modules/payments/pages/PaymentsPage';
import RequestPasswordResetPage from './modules/auth/pages/RequestPasswordResetPage';
import ResetPasswordPage from './modules/auth/pages/ResetPasswordPage';
import ConEventEditPage from './modules/cons/pages/ConEventEditPage';
import OwnerInvoicesPage from './modules/payments/pages/OwnerInvoicesPage';
import VendorEditPage from './modules/vendors/pages/VendorEditPage';
import VendorEditProfileImagePage from './modules/vendors/pages/VendorEditProfileImagePage';
import CreateVenuePage from './modules/con-brands/pages/CreateVenuePage';
import VenuePage from './modules/con-brands/pages/VenuePage';
import VenuesPage from './modules/con-brands/pages/VenuesPage';
import ConEventInvoicesPage from './modules/con-events/pages/ConEventInvoicesPage';
import ConEventLandingPage from './modules/landing/pages/ConEventLandingPage';
import ConEventEditPublicInfoPage from './modules/con-events/pages/ConEventEditPublicInfoPage';
import VendorEditPublicInfoPage from './modules/vendors/pages/VendorEditPublicInfoPage';
import EditConBrandPage from './modules/con-brands/pages/EditConBrandPage';
import ConEventApplicationsPage from './modules/con-events/pages/ConEventApplicationsPage';
import VendorExhibitingsPage from './modules/vendors/pages/VendorExhibitingsPage';
import ConEventGalleryPage from './modules/con-events/pages/ConEventGalleryPage';
import SchedulePage from './modules/schedules/pages/SchedulePage';
import AddPanelPage from './modules/con-events/pages/AddPanelPage';
import ConEventLandingSchedulePage from './modules/landing/pages/ConEventLandingSchedulePage';
import ProfileImagePage from './modules/con-brands/pages/ProfileImagePage';
import CreateAttractionPage from './modules/attractions/pages/CreateAttractionPage';
import AttractionsPage from './modules/attractions/pages/AttractionsPage';
import CreateMapImagePage from './modules/con-events/pages/CreateMapImagePage';
import MapImagesPage from './modules/con-events/pages/MapImagesPage';
import LandingMapImagesPage from './modules/landing/pages/LandingMapImagesPage';
import PrivacyPolicyPage from './modules/privacy/pages/PrivacyPolicyPage';
import ConEventPublicInfoMiscPage from './modules/con-events/pages/ConEventPublicInfoMiscPage';
import RulesPage from './modules/landing/pages/RulesPage';
import HotelsPage from './modules/landing/pages/HotelsPage';
import FAQPage from './modules/landing/pages/FAQPage';
import ClaimVendorPage from './modules/vendors/pages/ClaimVendorPage';
import MessageChannelsPage from './modules/messages/pages/MessageChannelsPage';
import MessagesPage from './modules/messages/pages/MessagesPage';

export default () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route
					path="/request-password-reset"
					element={<RequestPasswordResetPage />}
				/>
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route
					path="/user/preferences"
					element={
						<AuthenticatedRoute>
							<PreferencesPage />
						</AuthenticatedRoute>
					}
				/>
				<Route path="/email-unsubscribe" element={<EmailUnsubscribePage />} />
				<Route
					path="/con-brand/:conBrandId/create-con-event"
					element={
						<AuthenticatedRoute>
							<CreateConEventPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/create-con-brand"
					element={
						<AuthenticatedRoute>
							<CreateConBrandPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:id"
					element={
						<AuthenticatedRoute>
							<ConBrandPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/edit-profile-image"
					element={
						<AuthenticatedRoute>
							<ProfileImagePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:id/venues"
					element={
						<AuthenticatedRoute>
							<VenuesPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:id/create-venue"
					element={
						<AuthenticatedRoute>
							<CreateVenuePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/venues/:venueId"
					element={
						<AuthenticatedRoute>
							<VenuePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:id/edit-info"
					element={
						<AuthenticatedRoute>
							<EditConBrandPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id"
					element={
						<AuthenticatedRoute>
							<ConEventPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/edit"
					element={
						<AuthenticatedRoute>
							<ConEventEditPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/applications"
					element={
						<AuthenticatedRoute>
							<ConEventApplicationsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/applications/:id"
					element={
						<AuthenticatedRoute>
							<ViewApplicationPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/exhibitors"
					element={
						<AuthenticatedRoute>
							<ExhibitorsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/edit-public-info"
					element={
						<AuthenticatedRoute>
							<ConEventEditPublicInfoPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/map-images"
					element={
						<AuthenticatedRoute>
							<MapImagesPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/add-map-image"
					element={
						<AuthenticatedRoute>
							<CreateMapImagePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/gallery"
					element={
						<AuthenticatedRoute>
							<ConEventGalleryPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/schedules"
					element={
						<AuthenticatedRoute>
							<SchedulePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/schedules/add-panel"
					element={
						<AuthenticatedRoute>
							<AddPanelPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/attractions"
					element={
						<AuthenticatedRoute>
							<AttractionsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/attractions/add"
					element={
						<AuthenticatedRoute>
							<CreateAttractionPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:conEventId/other"
					element={
						<AuthenticatedRoute>
							<ConEventPublicInfoMiscPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/invoices"
					element={
						<AuthenticatedRoute>
							<ConEventInvoicesPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-brand/:conBrandId/con-event/:id/create-con-addon"
					element={
						<AuthenticatedRoute>
							<CreateConEventAddOnPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/con-event/:id/application-landing"
					element={<VendorApplyLandingPage />}
				/>
				<Route
					path="/payments"
					element={
						<AuthenticatedRoute>
							<PaymentsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/payments/invoices"
					element={
						<AuthenticatedRoute>
							<OwnerInvoicesPage />
						</AuthenticatedRoute>
					}
				/>

				<Route
					path="/vendors/create"
					element={
						<AuthenticatedRoute>
							<CreateVendorPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:id"
					element={
						<AuthenticatedRoute>
							<VendorPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:id/edit"
					element={
						<AuthenticatedRoute>
							<VendorEditPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:id/profile-image"
					element={
						<AuthenticatedRoute>
							<VendorEditProfileImagePage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:vendorId/edit-public-info"
					element={
						<AuthenticatedRoute>
							<VendorEditPublicInfoPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/apply/:conEventId"
					element={
						<AuthenticatedRoute>
							<CreateVendorApplicationPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:vendorId/vendor-application/:id"
					element={
						<AuthenticatedRoute>
							<VendorViewApplicationPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/vendor/:vendorId/exhibitings"
					element={
						<AuthenticatedRoute>
							<VendorExhibitingsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/messages"
					element={
						<AuthenticatedRoute>
							<MessageChannelsPage />
						</AuthenticatedRoute>
					}
				/>
				<Route
					path="/messages/:messageChannelId"
					element={
						<AuthenticatedRoute>
							<MessagesPage />
						</AuthenticatedRoute>
					}
				/>
				<Route path="/claim-vendor/:token" element={<ClaimVendorPage />} />
				<Route path="/events/:id/:title" element={<ConEventLandingPage />} />
				<Route
					path="/events/:conEventId/:title/schedule"
					element={<ConEventLandingSchedulePage />}
				/>
				<Route
					path="/events/:conEventId/:title/map-images"
					element={<LandingMapImagesPage />}
				/>
				<Route
					path="/events/:conEventId/:title/rules"
					element={<RulesPage />}
				/>
				<Route
					path="/events/:conEventId/:title/hotels"
					element={<HotelsPage />}
				/>
				<Route path="/events/:conEventId/:title/faq" element={<FAQPage />} />
				<Route path="/privacy" element={<PrivacyPolicyPage />} />
			</Routes>
		</BrowserRouter>
	);
};
