import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Tab, Tabs } from '@mui/material';
import dayjs from 'dayjs';

import PageContainer from '../../common/components/PageContainer';
import PageHeaderText from '../../common/components/PageHeaderText';
import { getPublicSchedule } from '../../schedules/services/SchedulesService';
import LandingPanelItem from '../components/LandingPanelItem';

export default () => {
	const { conEventId } = useParams();
	const [schedule, setSchedule] = useState();
	const [selectedDay, setSelectedDay] = useState();

	const fetchSchedule = useCallback(async () => {
		const res = await getPublicSchedule({ conEventId });

		setSchedule(res.schedule);
		setSelectedDay(res.schedule.hours[0].startTime);
	}, [conEventId, setSchedule]);

	useEffect(() => {
		fetchSchedule().then();
	}, [fetchSchedule]);

	const tabs = schedule?.hours.map(hour => {
		const dayDisplayText = dayjs(hour.startTime).format('ddd MMM DD YYYY');
		return <Tab value={hour.startTime} label={dayDisplayText} />;
	});

	const panelItems = useMemo(() => {
		return schedule?.panels
			.filter(panel => {
				return dayjs(panel.startTime).isSame(selectedDay, 'day');
			})
			.map(panel => {
				return (
					<Grid item key={panel.id} xs={12}>
						<LandingPanelItem panel={panel} />
					</Grid>
				);
			});
	}, [schedule, selectedDay]);

	return (
		<PageContainer title="Schedule">
			<Container>
				<PageHeaderText>Schedule</PageHeaderText>
				<Tabs
					value={selectedDay}
					onChange={(_, value) => setSelectedDay(value)}>
					{tabs}
				</Tabs>
				<Grid container spacing={2}>
					{panelItems}
				</Grid>
			</Container>
		</PageContainer>
	);
};
