import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import {
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import FileSaver from 'file-saver';

import PageContainer from '../../common/components/PageContainer';
import { getConfig } from '../../../config/config';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import DashCard from '../../common/components/DashCard';
import { getPublicMapImages } from '../../con-events/services/MapImagesService';
import MapImageModal from '../../con-events/components/maps/MapImageModal';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

const MapImageItem = ({ mapImage, onSelect }) => {
	const img = cld.image(mapImage.clImage.publicId).format('png');

	return (
		<Box display="flex" flexDirection="column" justifyContent="center">
			<Box
				sx={{
					paddingLeft: 3,
					paddingTop: 1,
					paddingBottom: 3,
					display: 'flex',
					alignItems: 'center',
				}}>
				<Typography variant="h6" flex={1}>
					{mapImage.caption}
				</Typography>

				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={() => {
						FileSaver.saveAs(mapImage.clImage.url, `map${mapImage.id}.jpg`);
					}}>
					<Download />
				</IconButton>
			</Box>

			<AdvancedImage
				cldImg={img}
				style={{
					maxWidth: '100%',
					maxHeight: '100%',
					width: 'auto',
					height: 350,
					objectFit: 'cover',
				}}
				onClick={() => onSelect(mapImage)}
			/>
		</Box>
	);
};

export default () => {
	const { conEventId } = useParams();

	const [mapImages, setMapImages] = useState([]);
	const [selectedMapImage, setSelectedMapImage] = useState(null);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchMapImages = useCallback(async () => {
		const resp = await getPublicMapImages({ conEventId });
		if (resp) {
			setMapImages(resp.mapImages);
		} else {
			showSnackbar({
				message: 'Something went wrong, please try again later',
				type: 'error',
			});
		}
	}, [conEventId, showSnackbar]);

	const items = mapImages.map(image => (
		<Grid key={image.key} item xs={6}>
			<MapImageItem
				mapImage={image}
				onSelect={mapImage => {
					setSelectedMapImage(mapImage);
				}}
			/>
		</Grid>
	));

	useEffect(() => {
		fetchMapImages();
	}, [fetchMapImages]);

	return (
		<PageContainer title="Maps">
			<Container>
				<DashCard
					title="Map Images"
					size="fit"
					right={
						<Button variant="outlined" component={Link} to={'./..'}>
							back
						</Button>
					}>
					<Grid container spacing={2}>
						{items}
					</Grid>
				</DashCard>
			</Container>

			<MapImageModal
				mapImage={selectedMapImage}
				onClose={() => setSelectedMapImage(null)}
			/>
		</PageContainer>
	);
};
