import React, { useContext, useEffect } from 'react';
import { Button, Card, CardContent, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';

import { postEmailUnsubscribe } from '../services/PreferencesService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	useEffect(() => {
		document.title = 'Unsubscribe Email';
	}, []);

	const [searchParams] = useSearchParams();

	const { showSnackbar } = useContext(SnackbarContext);

	const token = searchParams.get('token');
	const preferenceKey = searchParams.get('key');

	return (
		<div>
			<Container>
				<Card sx={{ marginTop: 5 }}>
					<CardContent sx={{ padding: 5 }}>
						<Typography variant="h3">Unsubscribe from email?</Typography>
						<Button
							variant="contained"
							onClick={async () => {
								try {
									await postEmailUnsubscribe(token, preferenceKey);
									showSnackbar({
										message: 'Unsubscribe successful',
										type: 'success',
									});
								} catch (e) {
									showSnackbar({
										message: 'Unsubscribe failed',
										type: 'error',
									});
								}
							}}>
							Unsubscribe
						</Button>
					</CardContent>
				</Card>
			</Container>
		</div>
	);
};
