import React, { useCallback, useContext, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Modal,
	Typography,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';

import { modalContainer } from '../../../form/components/ModalContainer.styles';
import { postAddGalleryImage } from '../../services/GalleriesService';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';

export default ({ open, onClose, conEventId, fetchGalleryImages }) => {
	const [image, setImage] = useState();

	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const handleSubmit = useCallback(async () => {
		if (!image) return;
		setLoading(true);
		const res = await postAddGalleryImage({ file: image.file, conEventId });
		if (res.status === 'success') {
			fetchGalleryImages();
			showSnackbar({
				message: 'Gallery Image added!',
				type: 'success',
			});
			onClose();
		} else {
			showSnackbar({
				message: 'Something went wrong, please try again later',
				type: 'error',
			});
		}
		setLoading(false);
	}, [image, conEventId, fetchGalleryImages, onClose, showSnackbar]);

	return (
		<Modal open={open}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent>
						<Typography variant="h4">Add a Gallery Image</Typography>

						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							mt={2}>
							<ImageUploading
								value={[image]}
								onChange={data => {
									setImage(data[0]);
								}}
								dataURLKey="data_url">
								{({ onImageUpload, dragProps }) => (
									<Box
										width={500}
										height={500}
										display="flex"
										alignItems="center"
										justifyContent="center"
										border={1}
										onClick={onImageUpload}
										{...dragProps}>
										{image ? (
											<>
												<img
													style={{
														width: 'auto',
														height: 'auto',
														maxWidth: '100%',
														maxHeight: '100%',
													}}
													src={image.data_url}
													alt="selected"
												/>
											</>
										) : (
											<Box
												sx={{
													flex: 1,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}>
												<Typography style={{ userSelect: 'none' }}>
													{' '}
													Click or Drag photo here
												</Typography>
											</Box>
										)}
									</Box>
								)}
							</ImageUploading>
							<Box mt={1}>
								<Button
									variant="contained"
									onClick={handleSubmit}
									disabled={loading}>
									Add
								</Button>
								<Button variant="outlined" onClick={onClose}>
									Cancel
								</Button>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
