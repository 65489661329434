import React from 'react';
import { Container } from '@mui/material';

import VendorConEventApplicationForm from '../components/VendorConEventApplicationForm';
import { useParams } from 'react-router-dom';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const { conEventId } = useParams();

	return (
		<PageContainer viewContextOverride="Konvene" title="Apply to Event">
			<Container>
				<VendorConEventApplicationForm conEventId={conEventId} />
			</Container>
		</PageContainer>
	);
};
