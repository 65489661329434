import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import MapImageForm from '../components/maps/MapImageForm';
import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../components/ConEventSubNav';
import PublicInfoSubNav from '../components/PublicInfoSubNav';

export default () => {
	const { conEventId, conBrandId } = useParams();

	return (
		<PageContainer>
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="mapImages"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				<MapImageForm />
			</Container>
		</PageContainer>
	);
};
