import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Grid } from '@mui/material';

import UserContext from '../../auth/contexts/UserContext';
import { createConBrand } from '../services/ConService';
import FormTextField from '../../form/FormTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { conBrandButton, formContainer } from './ConBrandForm.styles';
import ConContext from '../contexts/ConContext';
import ContactFields from '../../form/components/ContactFields';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import GenreSelect from './GenreSelect';
import { updateConBrand } from '../services/ConBrandsService';

const formSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a name for your event brand'),
	genre: Yup.string().required('Please select a genre'),
});

export default ({ conBrand, editing }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(formSchema),
	});
	const { authPost } = useContext(UserContext);
	const { refreshConBrands } = useContext(ConContext);
	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	useEffect(() => {
		reset(conBrand);
	}, [conBrand, reset]);

	const handleCreateConEvent = async data => {
		try {
			const resp = await createConBrand(data, authPost);
			if (resp.status === 'success') {
				await refreshConBrands();
				navigate(`/con-brand/${resp.conBrand.id}`);
				showSnackbar({
					message: `${data.name} successfully created!`,
					type: 'success',
				});
			} else {
				showSnackbar({ message: `Error creating brand`, type: 'error' });
			}
		} catch (e) {
			console.error(e);
			showSnackbar({
				message: `Failed creating brand. Please try again later`,
				type: 'error',
			});
		}
	};

	const handleUpdate = async data => {
		try {
			const resp = await updateConBrand(conBrand.id, data);

			if (resp.status === 'success') {
				await refreshConBrands();
				showSnackbar({
					message: `${data.name} saved!`,
					type: 'success',
				});
			} else {
				showSnackbar({ message: `Error updating ${data.name}`, type: 'error' });
			}
		} catch (e) {
			showSnackbar({ message: `Error updating ${data.name}`, type: 'error' });
		}
	};

	const title = editing ? `Edit ${conBrand?.name}` : 'Create an Event Brand';

	return (
		<Card sx={formContainer}>
			<h1>{title}</h1>
			<form
				onSubmit={handleSubmit(editing ? handleUpdate : handleCreateConEvent)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormTextField
							control={control}
							label="Brand Name *"
							name="name"
							error={!!errors.name}
							helperText={errors.name?.message}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							control={control}
							label="Description"
							name="description"
							multiline
							minRows={3}
							maxRows={10}
						/>
					</Grid>

					<Grid item xs={12}>
						<GenreSelect name="genre" control={control} label="Primary Genre" />
					</Grid>
					<ContactFields control={control} namePrefix="socialMedia" />
					<Grid item xs={12}>
						<Button type="submit" variant="contained" sx={conBrandButton}>
							{editing ? 'Save' : 'Create'}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Card>
	);
};
