import { authGet, authPost, authPut } from '../../auth/helpers/AuthRequests';

export const createConBrand = async data => {
	const resp = await authPost('/api/cons/con_brands', {
		conBrand: data,
	});
	return resp;
};

export const createConEvent = async data => {
	if (data.venueId === 'new') {
		const conEvent = {
			...data,
			conEventVendorTypesAttributes: data.conEventVendorTypes.map(vt => ({
				...vt,
				price: vt.price * 100,
			})),
		};

		const venue = {
			name: data.venue.name,
			address: data.venue.address,
		};

		delete conEvent.conEventVendorTypes;
		delete conEvent.venue;

		return await authPost('/api/cons/con_events', {
			conEvent,
			venue,
		});
	} else {
		const conEvent = {
			...data,
			conEventVendorTypesAttributes: data.conEventVendorTypes.map(vt => ({
				...vt,
				price: vt.price * 100,
			})),
		};

		delete conEvent.conEventVendorTypes;

		return await authPost('/api/cons/con_events', {
			conEvent,
		});
	}
};

export const updateConEvent = async (conEventId, data) => {
	const conEvent = {
		...data,
		conEventVendorTypes: undefined,
		createdAt: undefined,
		updatedAt: undefined,
		conEventAddOns: undefined,
		venue: undefined,
		address: undefined,
	};

	const resp = await authPut(`/api/cons/con_events/${conEventId}`, {
		conEvent,
	});

	return resp;
};

export const createConEventAddOn = async data => {
	return await authPost('/api/cons/con_event_add_ons', {
		...data,
		price: data.price * 100,
	});
};

export const getConEventAddOn = async addOnId => {
	return await authGet(`/api/cons/con_event_add_ons/${addOnId}`);
};

export const fetchUpcomingConEvents = async conBrandId => {
	return await authGet('/api/cons/upcoming_con_events', {
		searchParams: { conBrandId },
	});
};

export const fetchExpiredConEvents = async conBrandId => {
	return await authGet('/api/cons/expired_con_events', {
		searchParams: { conBrandId },
	});
};
export const fetchConEvent = async id => {
	return await authGet(`/api/cons/con_events/${id}`);
};

export const getPublicConEvent = async (id, searchParams) => {
	return await authGet(`/api/cons/public_con_event/${id}`, {
		searchParams,
		allowUnauthed: true,
	});
};

export const fetchConEventApplications = async id => {
	return await authGet(`/api/cons/con_events/${id}/vendor_applications`);
};

export const getConEventDecideInfo = async id => {
	return await authGet(`/api/cons/con_events/${id}/decide_info`);
};
