import React, { useContext } from 'react';
import { AppBar, Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

import UserContext from '../../../auth/contexts/UserContext';
import ConContext from '../../../cons/contexts/ConContext';
import VendorContext from '../../../vendors/context/VendorContext';
import NavbarFull from './NavbarFull';
import NavbarSmall from './NavbarSmall';
import NavbarSignedOut from './NavbarSignedOut';

export default ({ viewContextOverride }) => {
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

	const { user } = useContext(UserContext);
	const { conBrands } = useContext(ConContext);
	const { vendors } = useContext(VendorContext);

	const location = useLocation();

	const locationTokens = location.pathname.split('/');

	let viewContextText;

	switch (locationTokens[1]) {
		case 'con-brand':
			const conBrand = conBrands.find(
				cb => cb.id.toString() === locationTokens[2],
			);
			viewContextText = conBrand?.name;
			break;
		case 'vendor':
			const vendor = vendors.find(cb => cb.id.toString() === locationTokens[2]);
			viewContextText = vendor?.name;
			break;

		default:
			viewContextText = 'Konvene';
			break;
	}

	if (viewContextOverride) {
		viewContextText = viewContextOverride;
	}

	return (
		<Box marginBottom={4}>
			<AppBar position="static">
				<>
					{user?.id ? (
						mobileView ? (
							<NavbarSmall viewContextText={viewContextText} />
						) : (
							<NavbarFull viewContextText={viewContextText} />
						)
					) : (
						<NavbarSignedOut />
					)}
				</>
			</AppBar>
		</Box>
	);
};
