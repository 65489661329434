import React, { useEffect, useState } from 'react';
import { CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import ConEventForm from '../components/ConEventForm';
import PageContainer from '../../common/components/PageContainer';
import { fetchConEvent } from '../services/ConService';

export default () => {
	const { id } = useParams();
	const [conEvent, setConEvent] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const conEvent = await fetchConEvent(id);
			setConEvent(conEvent);
			setLoading(false);
		};
		fetchData().then();
	}, [id]);

	const content = loading ? (
		<CircularProgress />
	) : (
		<ConEventForm conEvent={conEvent} editing />
	);

	return (
		<PageContainer title={`Edit ${conEvent?.name || 'Brand'}`}>
			<Container>{content}</Container>
		</PageContainer>
	);
};
