import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export default ({ images }) => {
	const imageChildren = images.map(image => (
		<div key={image} style={{ height: 400 }}>
			<img
				src={image}
				style={{ height: '100%', objectFit: 'cover' }}
				alt="cover"
			/>
		</div>
	));

	return <Carousel showArrows={true}>{imageChildren}</Carousel>;
};
