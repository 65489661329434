import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import ExhibitorsCard from '../components/ExhibitorsCard';
import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import { getConEventVendorTypes } from '../services/ConEventsService';

export default () => {
	const { id, conBrandId } = useParams();
	const [vendorTypes, setVendorTypes] = useState([]);

	const fetchVendorTypes = useCallback(async () => {
		const res = await getConEventVendorTypes(id);
		setVendorTypes(res);
	}, [id]);

	useEffect(() => {
		fetchVendorTypes().then();
	}, [id, fetchVendorTypes]);

	return (
		<PageContainer title="Exhibitors">
			<ConEventSubNav
				value="exhibitors"
				conEventId={id}
				conBrandId={conBrandId}
			/>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ExhibitorsCard conEventId={id} vendorTypes={vendorTypes} />
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
