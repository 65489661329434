import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import DashCard from '../../common/components/DashCard';
import { authButton } from '../../auth/components/AuthForms.styles';
import { putVendorClaim } from '../services/VendorsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import VendorContext from '../context/VendorContext';

export default ({ vendor, token }) => {
	const { showSnackbar } = useContext(SnackbarContext);
	const { refreshVendors } = useContext(VendorContext);

	const navigate = useNavigate();

	const handleClaim = async () => {
		try {
			const res = await putVendorClaim(token);
			if (res.status === 'success') {
				showSnackbar({
					message: 'Vendor claimed!',
					type: 'success',
				});
				await refreshVendors();
				navigate('/');
			} else {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Something went wrong, please try again later',
				type: 'error',
			});
		}
	};

	return (
		<Box
			sx={{
				width: 500,
			}}>
			<DashCard title={`Claim ${vendor?.name}`} size="fit">
				<Typography variant="h6">
					Do you want to claim this Vendor profile?
				</Typography>
				<Box sx={{ pt: 3, pl: 2, mb: 2 }}>
					<Typography variant="subtitle">name: {vendor?.name}</Typography>
					<Typography fontStyle={{ fontSize: 16 }}>
						email: {vendor?.email}
					</Typography>
				</Box>
				<Typography>You can edit the profile information later</Typography>

				<Box mt={3}>
					<Button
						sx={authButton}
						type="submit"
						variant="contained"
						onClick={handleClaim}>
						Claim this vendor
					</Button>
				</Box>
			</DashCard>
		</Box>
	);
};
