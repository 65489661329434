import React, { useCallback, useContext } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate, useParams } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';
import FormTextField from '../../form/FormTextField';
import ContactFields from '../../form/components/ContactFields';
import ImageInput from '../../form/components/ImageInput';
import { postCreateAttraction } from '../services/AttractionsServices';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a panel name'),
});

export default () => {
	const { conEventId } = useParams();

	const navigate = useNavigate();

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(formSchema),
	});

	const { showSnackbar } = useContext(SnackbarContext);

	const handleCreateAttraction = useCallback(
		async data => {
			try {
				const res = await postCreateAttraction(conEventId, data);
				if (res.status === 'success') {
					showSnackbar({ message: `Attraction added!`, type: 'success' });
					navigate('./..');
				} else {
					showSnackbar({
						message: 'Error adding Attraction, please try again later',
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: 'Error adding Attraction, please try again later',
					type: 'error',
				});
			}
		},
		[conEventId, showSnackbar, navigate],
	);

	return (
		<DashCard size="fit">
			<form onSubmit={handleSubmit(handleCreateAttraction)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormTextField control={control} label="Name *" name="name" />
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							control={control}
							label="Description"
							name="publicInfo.description"
							multiline
							minRows={3}
							maxRows={3}
						/>
					</Grid>
					<ContactFields control={control} namePrefix="publicInfo" />
					<Grid item xs={6}>
						<Box>
							<ImageInput control={control} name="image" />
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box pt={2}>
							<Typography variant="h5" gutterBottom>
								Pick an image to show to attendees{' '}
							</Typography>
							<Typography>
								This image will be shown publicly to everyone who searches for
								your event
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" variant="contained">
							Add Attraction
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
