import { authGet, authPost } from '../../auth/helpers/AuthRequests';

export const getVendorApplications = async searchParams => {
	return await authGet(
		`/api/vendors/vendor_applications/`,

		{ searchParams },
	);
};
export const submitOffsitePaymentReceipt = async (id, transactionReceipt) => {
	return await authPost(
		`/api/vendors/vendor_applications/submit_receipt/${id}`,
		{
			transactionReceipt,
		},
	);
};

export const createVendorExhibit = async (
	applicationId,
	conEventId,
	vendorExhibit,
) => {
	const resp = await authPost(
		`/api/vendors/vendor_applications/${applicationId}/accept_exhibitor/`,
		{
			vendorExhibit,
			conEventId,
		},
	);

	return resp;
};

export const postAddNode = async (id, message) => {
	return await authPost(`/api/vendors/vendor_applications/${id}/add_note`, {
		message,
	});
};
