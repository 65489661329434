import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Divider,
	Grid,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import PageContainer from '../../common/components/PageContainer';
import { getPublicConEvent } from '../../cons/services/ConService';
import PageHeaderText from '../../common/components/PageHeaderText';
import RegisterModal from '../../auth/components/RegisterModal';
import {
	followConBrand,
	followConEvent,
	unfollowConBrand,
	unfollowConEvent,
} from '../../attendees/services/FollowingsService';
import UserContext from '../../auth/contexts/UserContext';
import LandingVendor from '../components/LandingVendor';
import LandingDateLocation from '../components/LandingDateLocation';
import ImageCarousel from '../components/ImageCarousel';
import LandingSchedulePreview from '../components/LandingSchedulePreview';
import LandingDescription from '../../schedules/components/LandingDescription';
import ConBrandFollowCard from '../components/ConBrandFollowCard';
import LandingAttractionsPreview from '../components/LandingAttractionsPreview';
import SponsorsSection from '../components/SponsorsSection';

//TODO we might want to SSR this

export default () => {
	const { id, title } = useParams();
	const [conEvent, setConEvent] = useState();
	const [openQuickRegister, setOpenQuickRegister] = useState(false);
	const [following, setFollowing] = useState(false);
	const [loading, setLoading] = useState(false);

	const [followingConBrand, setFollowingConBrand] = useState(false);

	const [registerContext, setRegisterContext] = useState();

	const { user } = useContext(UserContext);

	useEffect(() => {
		const fetchData = async () => {
			const {
				conEvent,
				following: newFollowing,
				followingConBrand: fcb,
			} = await getPublicConEvent(id, {
				following: true,
				includeVendors: true,
			});
			setConEvent(conEvent);
			setFollowing(newFollowing);
			setFollowingConBrand(fcb);
		};
		fetchData().then();
	}, [id]);

	const handleFollow = useCallback(async () => {
		if (user?.id) {
			if (following) {
				try {
					setLoading(true);
					await unfollowConEvent(id);
					setFollowing(false);
				} catch (e) {
				} finally {
					setLoading(false);
				}
			} else {
				try {
					setLoading(true);
					await followConEvent(id, 'event_landing');
					setFollowing(true);
				} catch (e) {
				} finally {
					setLoading(false);
				}
			}
		} else {
			setOpenQuickRegister(true);
			setRegisterContext('follow_con_event');
		}
	}, [following, setLoading, setFollowing, setOpenQuickRegister, id, user?.id]);

	const handleFollowConBrand = useCallback(async () => {
		if (user?.id) {
			if (followingConBrand) {
				try {
					setLoading(true);
					await unfollowConBrand(id);
					setFollowingConBrand(false);
				} catch (e) {
				} finally {
					setLoading(false);
				}
			} else {
				try {
					setLoading(true);
					await followConBrand(id, 'event_landing');
					setFollowingConBrand(true);
				} catch (e) {
				} finally {
					setLoading(false);
				}
			}
		} else {
			setOpenQuickRegister(true);
			setRegisterContext('follow_con_brand');
		}
	}, [followingConBrand, id, user?.id]);

	let infoContent = (
		<Skeleton
			variant="rectangular"
			sx={{
				width: '100%',
				height: '100%',
			}}
		/>
	);

	if (conEvent) {
		const startBlurb = dayjs(conEvent?.startTime).format('MMMM DD');
		infoContent = (
			<>
				<Grid item xs={12}>
					<Typography style={{ fontWeight: 'bold' }}>{startBlurb}</Typography>
					<Typography variant="h3">{conEvent?.publicInfo?.subtitle}</Typography>
					<Divider />
				</Grid>

				<LandingDateLocation conEvent={conEvent} />
				<Grid item xs={12}>
					<Box mt={2} mb={2}>
						<LandingDescription
							description={conEvent.publicInfo?.description}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<LandingVendor vendors={conEvent?.vendors} />
				</Grid>
				{conEvent.attractions.length > 0 && (
					<Grid item xs={12}>
						<LandingAttractionsPreview attractions={conEvent.attractions} />
					</Grid>
				)}
				{conEvent.schedule && conEvent.schedule.active && (
					<Grid item xs={12}>
						<LandingSchedulePreview schedule={conEvent.schedule} />
					</Grid>
				)}
				{conEvent.publicInfo?.rules?.active && (
					<Grid item xs={6} sm={6}>
						<Button
							variant="contained"
							sx={{ width: '100%' }}
							component={Link}
							to="rules">
							Rules
						</Button>
					</Grid>
				)}
				{conEvent.publicInfo?.hotels?.active && (
					<Grid item xs={6} sm={6}>
						<Button
							variant="contained"
							sx={{ width: '100%' }}
							component={Link}
							to="hotels">
							Hotels
						</Button>
					</Grid>
				)}
				{conEvent.publicInfo?.faq?.active && (
					<Grid item xs={6} sm={6}>
						<Button
							variant="contained"
							sx={{ width: '100%' }}
							component={Link}
							to="faq">
							FAQ
						</Button>
					</Grid>
				)}
				{conEvent.publicInfo?.sponsors?.active && (
					<Grid item xs={12}>
						<SponsorsSection sponsors={conEvent.publicInfo?.sponsors} />
					</Grid>
				)}
				<Grid item xs={12} mt={2}>
					<ConBrandFollowCard
						conBrand={conEvent.conBrand}
						following={followingConBrand}
						loading={loading}
						onFollow={handleFollowConBrand}
					/>
				</Grid>
			</>
		);
	}

	const carouselImages =
		conEvent?.galleryImages.map(gi => gi.clImage.url) || [];

	let ticketUrl = conEvent?.publicInfo?.ticketLink;
	if (ticketUrl && !/^https?:\/\//i.test(ticketUrl)) {
		ticketUrl = 'http://' + ticketUrl;
	}

	return (
		<PageContainer title={conEvent?.name || title}>
			<Container>
				<ImageCarousel images={carouselImages} />

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageHeaderText variant="h2">
							{conEvent?.name || title}
						</PageHeaderText>
					</Grid>
					<Grid container item xs={8} spacing={1}>
						{infoContent}
					</Grid>
					<Grid item xs={4}>
						<Card>
							<CardContent
								sx={{
									paddingTop: 7,
									paddingBottom: 6,
									paddingLeft: 3,
									paddingRight: 3,
								}}>
								<Stack spacing={2}>
									<Button
										variant={following ? 'outlined' : 'contained'}
										onClick={() => {
											handleFollow().then();
										}}
										disabled={loading}>
										{following ? 'Unfollow' : 'Follow'}
									</Button>

									<Button variant="contained" disabled>
										Contact
									</Button>

									<Button
										component={Link}
										to={`/con-event/${id}/application-landing`}
										variant="contained">
										Apply to Exhibit
									</Button>
									{ticketUrl && (
										<Button
											component={Link}
											target="_blank"
											to={ticketUrl}
											variant="contained">
											Buy Tickets
										</Button>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
			<RegisterModal
				open={openQuickRegister}
				onClose={() => setOpenQuickRegister(false)}
				onSuccess={async () => {
					switch (registerContext) {
						case 'follow_con_event': {
							const { status, errors } = await followConEvent(
								id,
								'quick_register',
							);
							if (
								status === 'success' ||
								errors?.userId?.[0] === 'Already following'
							)
								setFollowing(true);

							setOpenQuickRegister(false);
							break;
						}

						case 'follow_con_brand': {
							const { status, errors } = await followConBrand(
								conEvent.conBrandId,
								'quick_register',
							);
							if (
								status === 'success' ||
								errors?.userId?.[0] === 'Already following'
							)
								setFollowingConBrand(true);
							setOpenQuickRegister(false);
							break;
						}

						default:
					}
				}}
			/>
		</PageContainer>
	);
};
