import { Controller } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React from 'react';

const FormSelect = ({
	name,
	control,
	options,
	blankText,
	id,
	label,
	helperText,
	onChange: changeCallback,
	...props
}) => {
	const optionsItems = options.map(({ label, value }) => (
		<MenuItem key={value} value={value}>
			{label}
		</MenuItem>
	));
	return (
		<Controller
			control={control}
			name={name}
			defaultValue=""
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<FormControl sx={{ width: '100%', marginBottom: 2 }} {...props}>
					<InputLabel id={`${id}label`}>{label}</InputLabel>
					<Select
						id={id}
						value={value}
						label={label}
						error={!!error}
						onChange={value => {
							onChange(value);
							changeCallback && changeCallback(value.target.value);
						}}>
						<MenuItem key="none" value="">
							{blankText}
						</MenuItem>
						{optionsItems}
					</Select>
					<FormHelperText error={!!error}>
						{error?.message || helperText}
					</FormHelperText>
				</FormControl>
			)}
		/>
	);
};

export default FormSelect;
