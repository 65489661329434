import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';
import { Button, Box, Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import FormTextField from '../../form/FormTextField';
import {
	conBrandButton,
	formContainer,
} from '../../cons/components/ConBrandForm.styles';
import ContactFields from '../../form/components/ContactFields';
import AddressFields from '../../form/components/AddressFields';
import PhoneTextField from '../../form/PhoneTextField';
import { createVendor, updateVendorInfo } from '../services/VendorsService';
import VendorContext from '../context/VendorContext';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a name for your event brand'),
	email: Yup.string().email().required('Please enter an email for your brand'),
});

export default ({ editing, vendor }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: editing ? { ...vendor } : {},
		resolver: yupResolver(formSchema),
	});
	const { refreshVendors } = useContext(VendorContext);
	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const handleCreateVendor = async data => {
		try {
			const { status, vendor } = await createVendor({
				...data,
				phone: data.phone ? data.phone.replace(/\D/g, '') : '',
			});
			if (status === 'success') {
				await refreshVendors();

				navigate(`/vendor/${vendor.id}`);
				showSnackbar({
					message: `${data.name} successfully created!`,
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Failed creating vendor. Please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Failed creating vendor. Please try again later',
				type: 'error',
			});
		}
	};

	const handleUpdateVendorInfo = async data => {
		try {
			const { status } = await updateVendorInfo(vendor.id, data);
			if (status === 'success') {
				await refreshVendors();
				navigate(`/vendor/${vendor.id}`);
				showSnackbar({
					message: `${data.name} successfully updated!`,
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Failed updating vendor. Please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Failed creating vendor. Please try again later',
				type: 'error',
			});
		}
	};

	return (
		<Card sx={formContainer}>
			<h1>Vendor</h1>
			<form
				onSubmit={handleSubmit(
					editing ? handleUpdateVendorInfo : handleCreateVendor,
				)}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Name *"
								name="name"
								error={!!errors.name}
								helperText={errors.name?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Email *"
								name="email"
								error={!!errors.email}
								helperText={errors.email?.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField control={control} label="EIN" name="ein" />
						</Grid>
						<Grid item xs={12}>
							<PhoneTextField
								name="phone"
								label="phone"
								control={control}
								helperText="You will not be contacted by this number"
							/>
						</Grid>

						<AddressFields control={control} namePrefix="address" />

						<ContactFields control={control} namePrefix="contact" />

						<Grid item xs={12}>
							<Button type="submit" variant="contained" sx={conBrandButton}>
								{editing ? 'Update Info' : 'Create Vendor Brand'}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Card>
	);
};
