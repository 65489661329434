import { useCallback, useContext } from 'react';
import DashCard from '../../common/components/DashCard';
import { Switch, Typography } from '@mui/material';

import {
	putDisableSchedule,
	putEnableSchedule,
} from '../services/SchedulesService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default ({ conEventId, schedule, setSchedule, loading, setLoading }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const handleToggleSchedule = useCallback(
		async (_, value) => {
			setLoading(true);
			try {
				let res;
				if (value) {
					res = await putEnableSchedule(conEventId);
				} else {
					res = await putDisableSchedule(conEventId);
				}

				if (res.status === 'success') {
					setSchedule(res.schedule);
				} else {
					showSnackbar({
						message: `Failed changing schedule. Please try again later`,
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: `Failed changing schedule. Please try again later`,
					type: 'error',
				});
			} finally {
				setLoading(false);
			}
		},
		[conEventId, setSchedule, setLoading, showSnackbar],
	);

	const content = schedule ? (
		<></>
	) : (
		<>
			<Typography>You currently don't have the schedule enabled</Typography>
		</>
	);

	return (
		<DashCard title="Schedule" size="short">
			{content}
			Enable Schedule
			<Switch
				checked={!!(schedule && schedule.active)}
				onChange={handleToggleSchedule}
				disabled={loading}
			/>
			Allow Vendors to submit schedules events (noop)
			<Switch />
		</DashCard>
	);
};
