import React from 'react';

import { Controller } from 'react-hook-form';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

const FormSwitch = ({ control, name, label, rules, styles, ...props }) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<FormGroup>
					<FormControlLabel
						control={
							<Switch checked={value || false} onChange={onChange} {...props} />
						}
						label={label}
					/>
				</FormGroup>
			)}
		/>
	);
};

export default FormSwitch;
