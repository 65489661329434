import React, { Fragment, useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ContentCopy } from '@mui/icons-material';

import DashCard from '../../common/components/DashCard';
import { getVendorApplications } from '../../vendors/services/VendorApplicationsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const ApplicationItem = ({
	vendorApplication: {
		id,
		status,
		vendor: { name },
	},
}) => {
	return (
		<ListItemButton component={Link} to={`applications/${id}`}>
			<ListItemText
				primary={<Typography variant="subtitle2">{name}</Typography>}
				secondary={status}
			/>
		</ListItemButton>
	);
};
export default ({ conEventId }) => {
	const [applications, setApplications] = useState();

	const { showSnackbar } = useContext(SnackbarContext);

	useEffect(() => {
		const fetchData = async () => {
			const res = await getVendorApplications({
				conEventId,
				includeVendorName: true,
				limit: 4,
			});
			if (res.status === 'success') {
				setApplications(res.vendorApplications);
			}
		};
		fetchData().then();
	}, [conEventId, setApplications]);
	let content = <Skeleton height={'100%'} width={'100%'} />;

	if (applications) {
		const applicationItems = applications.map(va => (
			<Fragment key={va.id}>
				<ApplicationItem vendorApplication={va} />
				<Divider />
			</Fragment>
		));
		content = (
			<List sx={{ overflow: 'auto', maxHeight: '100%' }}>
				{applicationItems}
			</List>
		);
	}

	const relativeLink = `/con-event/${conEventId}/application-landing`;
	const applicationLink = `${window.location.protocol}//${window.location.host}${relativeLink}`;

	return (
		<DashCard
			title="Applications"
			right={
				<Box display="flex" alignItems="center">
					<Typography>Apply Link: </Typography>
					<IconButton
						onClick={async () => {
							try {
								navigator.clipboard.writeText(applicationLink);
								showSnackbar({
									message: 'Application link copied to clipboard',
									type: 'success',
								});
							} catch (e) {
								showSnackbar({
									message: 'failed copying to clipboard',
									type: 'error',
								});
							}
						}}>
						<ContentCopy />
					</IconButton>
				</Box>
			}
			bottom={
				<>
					<Button>View All</Button>
				</>
			}>
			{content}
		</DashCard>
	);
};
