import React, { useContext, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Modal,
	Typography,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import dayjs from 'dayjs';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

import DashCard from '../../common/components/DashCard';
import { postSetMapImage } from '../services/VenuesService';
import { modalContainer } from '../../form/components/ModalContainer.styles';
import { getConfig } from '../../../config/config';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});
export default ({ venueId, existingImage, setVenue }) => {
	const [image, setImage] = useState();

	const [showMap, setShowMap] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	let existingContent = null;

	if (existingImage) {
		const createdText = dayjs(existingImage.createdAt).format(
			'ddd MMM DD YYYY [at] h:mm A',
		);
		existingContent = (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				mt={1}>
				<Typography>Current map uploaded on {createdText}</Typography>
				<Button
					variant="outlined"
					onClick={() => {
						setShowMap(true);
					}}>
					View Current Map
				</Button>
			</Box>
		);
	}

	const img = cld.image(existingImage?.publicId).format('png');

	return (
		<DashCard title="Map Image" size="short">
			<Box>
				{existingContent}

				<Box display="flex" alignItems="center" mb={1} mt={1}>
					<ImageUploading
						value={[image]}
						onChange={data => {
							setImage(data[0]);
						}}
						dataURLKey="data_url">
						{({ onImageUpload, dragProps }) => (
							<>
								{!image ? (
									<Typography onClick={onImageUpload} {...dragProps}>
										Click or Drag image here to upload
									</Typography>
								) : (
									<Typography onClick={onImageUpload} {...dragProps}>
										{image.file.name}
									</Typography>
								)}
							</>
						)}
					</ImageUploading>
				</Box>
				<Box justifyContent="flex-end" display="flex">
					<Button
						variant="outlined"
						onClick={async () => {
							const { status, venue } = await postSetMapImage(
								venueId,
								image.file,
							);
							if (status === 'success') {
								setVenue(venue);
								showSnackbar({
									message: `Map image saved!`,
									type: 'success',
								});
							} else {
								showSnackbar({
									message: `Failed saving map. Please try again later`,
									type: 'error',
								});
							}
						}}
						disabled={!image}>
						{existingImage ? 'Replace Image' : 'Save Map Image'}
					</Button>
				</Box>
			</Box>
			<Modal open={showMap} onClose={() => setShowMap(false)}>
				<Box sx={modalContainer}>
					<Card>
						<CardContent
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<AdvancedImage
								cldImg={img}
								style={{
									maxWidth: '100%',
									maxHeight: '100%',
									width: 'auto',
									height: 'auto',
								}}
							/>
						</CardContent>
					</Card>
				</Box>
			</Modal>
		</DashCard>
	);
};
