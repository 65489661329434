import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	AuthInput,
	authBoxSmall,
	authForm,
	authButton,
} from './AuthForms.styles';
import { Button, Card, CardActions, Link } from '@mui/material';
import { postRequestPasswordReset } from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const { handleSubmit, control } = useForm();

	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);

	const handleRequest = async data => {
		try {
			setSubmitting(true);

			const result = await postRequestPasswordReset(data.email);

			if (result) {
				navigate('/login');
				showSnackbar({
					message: 'We will email you password reset instructions',
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Error requesting reset, please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			// TODO don't handle this by throwing
			console.error('login error', e);
			showSnackbar({ message: 'Login Failed', type: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<Grid item>
				<Card sx={authBoxSmall}>
					<h1>Lost Password</h1>
					<form style={authForm} onSubmit={handleSubmit(handleRequest)}>
						<AuthInput
							control={control}
							label="Email"
							name="email"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIcon />
									</InputAdornment>
								),
							}}
						/>
						<Button
							sx={authButton}
							type="submit"
							variant="contained"
							disabled={submitting}>
							Send Password Reset
						</Button>
					</form>
					<CardActions>
						<Grid mt={4}>
							Back to{' '}
							<b>
								<Link
									href="."
									onClick={() => navigate('/login')}
									underline="none">
									login
								</Link>
							</b>
							.
						</Grid>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};
