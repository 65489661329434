import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AttractionListItem from './AttractionListItem';
import AllAttractionsModal from './AllAttractionsModal';

export default ({ attractions }) => {
	const [previewAttractions, setPreviewAttractions] = useState([]);
	const [showAllAttractions, setShowAllAttractions] = useState();

	useEffect(() => {
		const shuffled = attractions.slice().sort(() => 0.5 - Math.random());
		setPreviewAttractions(shuffled.slice(0, 3));
	}, [attractions]);

	const items = useMemo(() => {
		return previewAttractions.map(attraction => (
			<AttractionListItem key={attraction.id} attraction={attraction} />
		));
	}, [previewAttractions]);

	return (
		<Box pt={2} mb={3}>
			<Typography variant="h6" gutterBottom>
				Attractions
			</Typography>
			<Box display="flex" flexDirection="column" justifyContent="center">
				{items}
				<Button
					onClick={() => {
						setShowAllAttractions(true);
					}}
					variant="outlined">
					Show All
				</Button>
			</Box>
			<AllAttractionsModal
				open={showAllAttractions}
				onClose={() => setShowAllAttractions(false)}
				attractions={attractions}
			/>
		</Box>
	);
};
