import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Toolbar, Typography } from '@mui/material';

import { flexGrow, whiteLinks } from './Navbar.styles';

export default () => {
	return (
		<Toolbar>
			<Typography variant="h6" component="div" sx={flexGrow}>
				Welcome to Konvene
			</Typography>

			<Button color="primary">
				<Link to="/login" style={whiteLinks}>
					Login
				</Link>
			</Button>

			<Button color="inherit">
				<Link to="/register" style={whiteLinks}>
					Register
				</Link>
			</Button>
		</Toolbar>
	);
};
