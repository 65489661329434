export const getConfig = () => {
	let defaults = {
		cloudinaryCloudName: 'dk4wggygr',
	};
	switch (process.env.NODE_ENV) {
		case 'production':
			defaults = {
				...defaults,
				apiHost: 'https://konvene-demo.fly.dev',
			};

			break;

		// dev
		default:
			defaults = {
				...defaults,
				apiHost: 'http://localhost:3001',
			};
			break;
	}

	return {
		apiHost: process.env.API_HOST || defaults.apiHost,
		cloudinaryCloudName:
			process.env.CLOUDINARY_CLOUD_NAME || defaults.cloudinaryCloudName,
	};
};
