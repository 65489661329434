import React from 'react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { CalendarToday, Place } from '@mui/icons-material';
import dayjs from 'dayjs';

import AddressDisplay from '../../utils/AddressDisplay';
import { Link } from 'react-router-dom';

export default ({ conEvent }) => {
	const startText = dayjs(conEvent?.startTime).format(
		'ddd MMM DD YYYY [at] h:mm A',
	);
	const endText = dayjs(conEvent?.endTime).format(
		'ddd MMM DD YYYY [at] h:mm A',
	);

	if (!conEvent) {
		return <Skeleton width="100%" height={300} />;
	}

	return (
		<Grid container spacing={1} mt={1}>
			<Grid item xs={6} s={6}>
				<Box display="flex" flexDirection="row">
					<CalendarToday
						style={{
							margin: 16,
						}}
					/>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>Dates</Typography>
						<Typography>{startText}</Typography>
						<Typography fontWeight="bold">to</Typography>
						<Typography paddingBottom={1}>{endText}</Typography>
					</Box>
				</Box>
			</Grid>

			<Grid item xs={6} s={6}>
				<Box display="flex" flexDirection="row">
					<Place
						style={{
							margin: 16,
						}}
					/>
					<Box display="flex" flexDirection="column">
						<Typography variant="p" style={{ fontWeight: 'bold' }}>
							Location
						</Typography>
						<AddressDisplay
							address={conEvent.venue?.address || conEvent.address}
						/>
						{conEvent.mapImages?.length > 0 && (
							<Button
								variant="outlined"
								style={{ marginTop: 5 }}
								component={Link}
								to={'map-images'}>
								Maps
							</Button>
						)}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
