import { authGet, authPost } from '../../auth/helpers/AuthRequests';

export const getMessageChannels = async () => {
	return await authGet(`/api/messages/message_channels`);
};

export const getShowMessageChannel = async messageChannelId => {
	return await authGet(`/api/messages/message_channels/${messageChannelId}`);
};

export const getUnreadMessageChannels = async () => {
	return await authGet(`/api/messages/unread_message_channels`);
};

export const postViewMessageChannel = async messageChannelId => {
	return await authPost(
		`/api/messages/view_message_channel/${messageChannelId}`,
	);
};

export const getMessages = async messageChannelId => {
	return await authGet(`/api/messages/messages`, {
		searchParams: {
			messageChannelId,
		},
	});
};

export const postCreateMessage = async (messageChannelId, messageText) => {
	return await authPost(`/api/messages/messages`, {
		message: {
			messageChannelId,
			messageText,
		},
	});
};
