import React from 'react';
import { Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { getStripeAccountLink } from '../services/StripeServices';

export default ({ status }) => {
	let content;

	switch (status) {
		case 'none':
			content = (
				<>
					<Typography variant="h3">Setting up Payments</Typography>
					<Typography variant="body1">
						Your payments are currently not setup
					</Typography>
					<Typography variant="body1">
						You must set up your stripe account before being able to accept
						payments
					</Typography>
					<Button
						onClick={async () => {
							const { url } = await getStripeAccountLink();
							window.location.replace(url);
						}}>
						Set up account
					</Button>
				</>
			);
			break;
		case 'info_needed':
			content = (
				<>
					<Typography variant="h3">Info Needed</Typography>
					<Typography variant="body1">
						Stripe needs more info before you can receive payments
					</Typography>
					<Typography variant="body1">Please finish onboarding</Typography>
					<Button
						onClick={async () => {
							const { url } = await getStripeAccountLink();
							window.location.replace(url);
						}}>
						Set up account
					</Button>
				</>
			);
			break;
		case 'ready':
			content = (
				<>
					<Typography variant="h3">Payments Ready</Typography>
					<Typography variant="body1">
						You are ready to receive payments!
					</Typography>
				</>
			);
			break;
		default:
			break;
	}
	return (
		<Card>
			<CardContent>{content}</CardContent>
		</Card>
	);
};
