import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default ({ conBrandId, conEventId, value }) => {
	const navigate = useNavigate();

	const handleChange = (_, value) => {
		switch (value) {
			case 'content':
				navigate(
					`/con-brand/${conBrandId}/con-event/${conEventId}/edit-public-info`,
				);
				break;
			case 'mapImages':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/map-images`);
				break;
			case 'gallery':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/gallery`);
				break;
			case 'schedule':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/schedules`);
				break;
			case 'attractions':
				navigate(
					`/con-brand/${conBrandId}/con-event/${conEventId}/attractions`,
				);
				break;
			case 'misc':
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}/other`);
				break;
			default:
				break;
		}
	};
	return (
		<Box display="flex" justifyContent="center" mb={1}>
			<Tabs value={value} aria-label="Event navigation" onChange={handleChange}>
				<Tab value="content" label="Content" />
				<Tab value="mapImages" label="Map Images" />
				<Tab value="gallery" label="Gallery" />
				<Tab value="schedule" label="Schedule" />
				<Tab value="attractions" label="Attractions" />
				<Tab value="misc" label="Other" />
			</Tabs>
		</Box>
	);
};
