import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const EXPANDABLE_LENGTH = 900;

export default ({ description }) => {
	const [open, setOpen] = useState(false);

	const expandable = description.length > EXPANDABLE_LENGTH;

	return (
		<Box>
			<Box
				style={{
					height: open || !expandable ? null : 300,
					overflow: 'hidden',
				}}>
				<Typography style={{ whiteSpace: 'pre-line' }}>
					{description}
				</Typography>
			</Box>
			{expandable && (
				<Button
					variant="outlined"
					style={{ width: '100%' }}
					onClick={() => setOpen(!open)}>
					{open ? (
						<>
							Hide <ExpandLess />
						</>
					) : (
						<>
							Show More <ExpandMore />
						</>
					)}
				</Button>
			)}
		</Box>
	);
};
