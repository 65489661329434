import React, { Fragment } from 'react';
import {
	Card,
	CardContent,
	Divider,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatUSD } from '../../utils/format';

const ApplyAsItem = ({ conEventId, vendorType: { id, name, price } }) => {
	const navigate = useNavigate();

	return (
		<ListItemButton
			onClick={() => {
				const params = new URLSearchParams({ vendor_type: id });

				navigate(`/vendor/apply/${conEventId}?${params}`);
			}}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							{formatUSD(price)}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ vendorTypes, conEventId }) => {
	const content = vendorTypes.map(vt => (
		<Fragment key={vt.id}>
			<ApplyAsItem vendorType={vt} conEventId={conEventId} />
			<Divider />
		</Fragment>
	));

	return (
		<Card>
			<CardContent>
				<Typography variant="h3">Apply As</Typography>
				{content}
			</CardContent>
		</Card>
	);
};
