import React, { Fragment, useEffect, useState } from 'react';
import {
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { fetchExpiredConEvents } from '../services/ConService';
import DashCard from '../../common/components/DashCard';

const EventItem = ({ event: { id, name, startTime } }) => {
	return (
		<ListItemButton component={Link} to={`con-event/${id}`}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							{dayjs(startTime).format('ddd MMM DD YYYY [at] h:mm A')}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ conBrandId }) => {
	const [events, setEvents] = useState([]);

	useEffect(() => {
		fetchExpiredConEvents(conBrandId).then(upcomingEvents => {
			setEvents(upcomingEvents);
		});
	}, [conBrandId]);

	const eventItems = events.map(e => (
		<Fragment key={e.id}>
			<EventItem event={e} />
			<Divider />
		</Fragment>
	));

	return (
		<DashCard title="Past Events">
			<List sx={{ overflow: 'auto' }}>{eventItems}</List>
			{eventItems.length === 0 && (
				<Typography variant="p">You have no past events</Typography>
			)}
		</DashCard>
	);
};
