import React from 'react';
import { Container } from '@mui/material';

import VendorForm from '../components/VendorForm';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	return (
		<PageContainer title="Create New Vendor Profile">
			<Container>
				<VendorForm />
			</Container>
		</PageContainer>
	);
};
