import React, { useContext, useState } from 'react';
import DashCard from '../../common/components/DashCard';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import UserContext from '../../auth/contexts/UserContext';
import { postDeleteUser } from '../services/PreferencesService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const { destroyCredentials } = useContext(UserContext);
	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	return (
		<DashCard
			title="Delete Account"
			size="short"
			bottom={
				<Button
					variant="contained"
					color="error"
					sx={{ marginLeft: 'auto', marginRight: 2 }}
					onClick={() => {
						setShowDeleteDialog(true);
					}}>
					Delete Account
				</Button>
			}>
			<Box mt={3}>
				<Typography variant="h5">WARNING! This cannot be undone</Typography>
			</Box>
			<Dialog
				open={showDeleteDialog}
				onClose={() => {
					setShowDeleteDialog(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Delete your Account</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This cannot be undone
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setShowDeleteDialog(false)}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={async () => {
							const res = await postDeleteUser();
							if (res.status === 'success') {
								destroyCredentials();
								navigate('/');
							} else {
								showSnackbar({
									message: `Something went wrong, try again later`,
									type: 'error',
								});
							}
						}}>
						Delete Account
					</Button>
				</DialogActions>
			</Dialog>
		</DashCard>
	);
};
