import React, { Fragment } from 'react';
import { Button, Divider, List, ListItemText, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';

const PanelItem = ({ panel: { id, name, startTime, endTime, location } }) => {
	const displayDay = dayjs(startTime).format('ddd MMM DD YYYY');
	const displayStartTime = dayjs(startTime).format('h:mm A');
	const displayEndTime = dayjs(endTime).format('h:mm A');
	return (
		<ListItemText
			key={id}
			primary={<Typography variant="h5">{name}</Typography>}
			secondary={
				<Typography component="span" variant="body2" color="text.primary">
					{location}: {displayDay} {displayStartTime} - {displayEndTime}
				</Typography>
			}
		/>
	);
};

export default ({ panels }) => {
	const panelItems = panels.map(panel => (
		<Fragment key={panel.id}>
			<PanelItem panel={panel} />
			<Divider />
		</Fragment>
	));

	return (
		<DashCard
			title="Panels"
			right={
				<Button variant="outlined" component={Link} to="add-panel">
					Add Panel
				</Button>
			}>
			<List sx={{ overflowY: 'auto' }}>{panelItems}</List>
		</DashCard>
	);
};
