import React, { useCallback, useContext, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Modal,
} from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

import { modalContainer } from '../../../form/components/ModalContainer.styles';
import { getConfig } from '../../../../config/config';
import { deleteGalleryImage } from '../../services/GalleriesService';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

export default ({
	open,
	onClose,
	galleryImage,
	conEventId,
	fetchGalleryImages,
}) => {
	const { showSnackbar } = useContext(SnackbarContext);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [loading, setLoading] = useState(false);

	const img = cld.image(galleryImage?.clImage?.publicId).format('png');
	const handleRemoveGalleryImage = useCallback(async () => {
		try {
			setLoading(true);
			const res = await deleteGalleryImage({
				conEventId,
				galleryImageId: galleryImage.id,
			});
			if (res.status === 'success') {
				fetchGalleryImages();
				showSnackbar({
					message: 'Image removed!',
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
			setLoading(false);
			setShowDeleteDialog(false);
			onClose();
		} catch (e) {
			showSnackbar({
				message: 'Something went wrong, please try again later',
				type: 'error',
			});
		}
	}, [showSnackbar, galleryImage, conEventId, fetchGalleryImages, onClose]);

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box sx={modalContainer}>
					<Card>
						<CardContent>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								mt={2}>
								<AdvancedImage cldImg={img} />
								<Box mt={1}>
									<Button
										variant="contained"
										color="error"
										onClick={() => {
											setShowDeleteDialog(true);
										}}>
										Delete
									</Button>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</Box>
			</Modal>
			<Dialog
				open={showDeleteDialog}
				onClose={() => {
					setShowDeleteDialog(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Remove Gallery Image</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Remove this image from your event's page
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading}>Cancel</Button>
					<Button onClick={handleRemoveGalleryImage} disabled={loading}>
						Remove
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
