import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import { getPublicConEvent } from '../../cons/services/ConService';
import PageHeaderText from '../../common/components/PageHeaderText';

export default () => {
	const { conEventId, title } = useParams();

	const [conEvent, setConEvent] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const { conEvent } = await getPublicConEvent(conEventId, {});
			setConEvent(conEvent);
		};
		fetchData().then();
	}, [conEventId]);

	return (
		<PageContainer title={`${conEvent?.name || title} Rules`}>
			<Container>
				<PageHeaderText>Rules and Policies</PageHeaderText>
				<Typography style={{ whiteSpace: 'pre-line' }}>
					{conEvent?.publicInfo?.rules?.rulesContent}
				</Typography>
			</Container>
		</PageContainer>
	);
};
