import React, { useCallback, useContext, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate, useParams } from 'react-router-dom';

import FormTextField from '../../../form/FormTextField';
import ImageInput from '../../../form/components/ImageInput';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';
import DashCard from '../../../common/components/DashCard';
import { postCreateMapImage } from '../../services/MapImagesService';

const formSchema = Yup.object().shape({
	caption: Yup.string().required('Please enter a caption'),
	mapImage: Yup.object().required('Please select an image'),
});

export default () => {
	const { conBrandId, conEventId } = useParams();

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(formSchema),
	});

	const { showSnackbar } = useContext(SnackbarContext);

	const handleCreateMapImage = useCallback(
		async data => {
			try {
				setLoading(true);
				const res = await postCreateMapImage({
					file: data.mapImage.file,
					caption: data.caption,
					conEventId,
				});
				if (res.status === 'success') {
					showSnackbar({ message: `Map Image added!`, type: 'success' });
					navigate(
						`/con-brand/${conBrandId}/con-event/${conEventId}/map-images`,
					);
				} else {
					showSnackbar({
						message: 'Error adding Map Image, please try again later',
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: 'Error adding Map Image, please try again later',
					type: 'error',
				});
			}

			setLoading(false);
		},
		[conBrandId, conEventId, showSnackbar, navigate],
	);

	return (
		<DashCard size="fit" title="Add Map Image">
			<form onSubmit={handleSubmit(handleCreateMapImage)}>
				<Grid
					container
					spacing={2}
					sx={{
						paddingTop: 3,
						paddingBottom: 5,
						paddingLeft: 4,
						paddingRight: 4,
					}}>
					<Grid item xs={12}>
						<FormTextField control={control} label="Caption *" name="caption" />
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<ImageInput control={control} name="mapImage" size={600} />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" variant="contained" disabled={loading}>
							Add Map Image
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
