import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import { getPublicConEvent } from '../../cons/services/ConService';
import PageHeaderText from '../../common/components/PageHeaderText';

const FaqItem = ({ faq: { question, answer } }) => {
	return (
		<Box>
			<Typography
				style={{
					whiteSpace: 'pre-line',
					fontSize: 24,
					marginBottom: 8,
				}}>
				{question}
			</Typography>
			<Typography
				style={{
					whiteSpace: 'pre-line',
					fontSize: 20,
					marginBottom: 4,
				}}>
				{answer}
			</Typography>
			<Divider />
		</Box>
	);
};

export default () => {
	const { conEventId, title } = useParams();

	const [conEvent, setConEvent] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const { conEvent } = await getPublicConEvent(conEventId, {});
			setConEvent(conEvent);
		};
		fetchData().then();
	}, [conEventId]);

	const items = conEvent?.publicInfo?.faq.faqs.map(faq => (
		<FaqItem faq={faq} />
	));

	return (
		<PageContainer title={`${conEvent?.name || title} FAQ`}>
			<Container>
				<PageHeaderText>FAQ</PageHeaderText>
				<Stack spacing={2}>{items}</Stack>
			</Container>
		</PageContainer>
	);
};
