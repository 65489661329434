import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid } from '@mui/material';

import { fetchConEvent } from '../services/ConService';
import VendorsCard from '../components/VendorsCard';
import ConEventInfoCard from '../components/ConEventInfoCard';
import VendorApplicationsCard from '../components/VendorApplicationsCard';
import ConEventAddOnCard from '../components/ConEventAddOnCard';
import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import PageHeaderText from '../../common/components/PageHeaderText';

export default () => {
	const { id, conBrandId } = useParams();
	const [conEvent, setConEvent] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const conEvent = await fetchConEvent(id);
			setConEvent(conEvent);
			setLoading(false);
		};
		fetchData().then();
	}, [id]);

	return (
		<PageContainer title={conEvent?.name}>
			<ConEventSubNav
				value="overview"
				conEventId={id}
				conBrandId={conBrandId}
			/>

			<Container>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						<PageHeaderText>{conEvent.name}</PageHeaderText>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<ConEventInfoCard conEvent={conEvent} />
							</Grid>
							<Grid item xs={12} md={6}>
								<VendorApplicationsCard conEventId={conEvent.id} />
							</Grid>
							<Grid item xs={12} md={6}>
								<VendorsCard conEventId={conEvent.id} />
							</Grid>

							<Grid item xs={12} md={6}>
								<ConEventAddOnCard conEvent={conEvent} />
							</Grid>
						</Grid>
					</>
				)}
			</Container>
		</PageContainer>
	);
};
