import { uploadCloudinaryImage } from '../../common/services/CloudinaryService';
import { authDelete, authGet, authPost } from '../../auth/helpers/AuthRequests';

export const postCreateMapImage = async ({ file, caption, conEventId }) => {
	const { url, publicId } = await uploadCloudinaryImage(file);
	return await authPost(`/api/cons/map_images`, {
		mapImage: {
			conEventId,
			caption,
		},
		conEventId,
		clImage: {
			url,
			publicId,
		},
	});
};

export const deleteMapImage = async mapImageId => {
	return await authDelete(`/api/cons/map_images/${mapImageId}`);
};

export const getMapImages = async ({ conEventId }) => {
	return await authGet(`/api/cons/map_images`, {
		searchParams: { conEventId },
	});
};

export const getPublicMapImages = async ({ conEventId }) => {
	return await authGet(`/api/cons/public_map_images`, {
		allowUnauthed: true,
		searchParams: { conEventId },
	});
};
