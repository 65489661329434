import { authGet, authPut } from '../../auth/helpers/AuthRequests';

export const getConEventSchedule = async searchParams => {
	return await authGet('/api/schedules/', { searchParams });
};

export const getPublicSchedule = async searchParams => {
	return await authGet('/api/schedules/public', {
		searchParams,
		allowUnauthed: true,
	});
};

export const putEnableSchedule = async conEventId => {
	return await authPut(`/api/schedules/enable`, {
		conEventId,
	});
};

export const putDisableSchedule = async conEventId => {
	return await authPut(`/api/schedules/disable`, {
		conEventId,
	});
};

export const putUpdateSchedule = async ({ scheduleId, schedule }) => {
	return await authPut(`/api/schedules/${scheduleId}`, {
		schedule,
	});
};
