import { authGet, authPost } from '../../auth/helpers/AuthRequests';

export const getPanels = async ({ scheduleId }) => {
	return await authGet('/api/schedules/panels', {
		scheduleId,
	});
};

export const postCreatePanel = async ({ conEventId, panel }) => {
	return await authPost(`/api/schedules/panels`, {
		conEventId,
		panel,
	});
};
