import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import ClaimVendorLogin from './ClaimVendorLogin';
import ClaimVendorRegister from './ClaimVendorRegister';

export default ({ vendor }) => {
	const [type, setType] = useState('login');

	const toggleType = useCallback(() => {
		setType(type === 'login' ? 'register' : 'login');
	}, [type]);

	return (
		<Box>
			{type === 'login' ? (
				<ClaimVendorLogin vendor={vendor} toggleType={toggleType} />
			) : (
				<ClaimVendorRegister vendor={vendor} toggleType={toggleType} />
			)}
		</Box>
	);
};
