export const formContainer = {
	border: 1,
	borderRadius: 4,
	padding: 4,
	marginTop: 3,
	marginBottom: 4,
};

export const conBrandForm = {
	display: 'flex',
	flexDirection: 'column',
};

export const conBrandButton = {
	borderRadius: '20px',
};
