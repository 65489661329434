import React from 'react';
import { Box, Card, CardContent, Modal } from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

import { modalContainer } from '../../../form/components/ModalContainer.styles';
import { getConfig } from '../../../../config/config';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

export default ({ mapImage, onClose }) => {
	const img = cld.image(mapImage?.clImage?.publicId).format('png');

	return (
		<Modal open={!!mapImage} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							mt={2}>
							<AdvancedImage cldImg={img} />
							<Box mt={1}></Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
