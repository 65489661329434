import { createContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [note, setNote] = useState('');
	const [severity, setSeverity] = useState();

	const showSnackbar = ({ message, type }) => {
		setOpen(true);
		setNote(message);
		setSeverity(type);
	};

	return (
		<SnackbarContext.Provider
			value={{
				showSnackbar,
			}}>
			{children}
			<Snackbar
				open={open}
				autoHideDuration={6000}
				message={note}
				onClose={(_, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					setOpen(false);
				}}>
				<Alert
					severity={severity}
					sx={{ width: '100%' }}
					onClose={() => setOpen(false)}>
					{note}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};

export default SnackbarContext;
