import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import DashCard from '../../common/components/DashCard';
import FormSwitch from '../../form/FormSwitch';
import FormTextField from '../../form/FormTextField';
import { conBrandButton } from '../../cons/components/ConBrandForm.styles';
import ImageInput from '../../form/components/ImageInput';
import { putUpdatePublicInfoHotels } from '../../cons/services/ConEventsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({});
export default ({ conEvent }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});

	const {
		fields: hotelsFields,
		append: hotelsAppend,
		remove: hotelsRemove,
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'hotels', // unique name for your Field Array
	});

	useEffect(() => {
		reset(conEvent?.publicInfo?.hotels);
	}, [conEvent, reset]);

	const handleUpdateHotels = useCallback(
		async data => {
			setLoading(true);
			try {
				await putUpdatePublicInfoHotels(conEvent.id, data);
				showSnackbar({
					message: 'Hotels successfully saved!',
					type: 'success',
				});
			} catch (e) {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[conEvent, showSnackbar],
	);

	return (
		<DashCard title="Hotels" size="fit">
			<form onSubmit={handleSubmit(handleUpdateHotels)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormSwitch control={control} name="active" label="Active" />
					</Grid>
					<Grid item xs={12}>
						<Typography>Wide photos work best</Typography>
					</Grid>
					{hotelsFields.map((hotel, index) => (
						<Grid key={index} item xs={12}>
							<Box display="flex" alignItems="center">
								<ImageInput
									control={control}
									name={`hotels.${index}.image`}
									size={200}
								/>
								<Box ml={2}>
									<FormTextField
										control={control}
										label="Name"
										name={`hotels.${index}.name`}
									/>
									<FormTextField
										control={control}
										label="Description"
										name={`hotels.${index}.description`}
										multiline
										minRows={3}
									/>
									<FormTextField
										control={control}
										label="Booking Link"
										name={`hotels.${index}.bookingLink`}
									/>
									<Button
										variant="outlined"
										sx={{ marginBottom: 1 }}
										onClick={() => hotelsRemove(index)}
										disabled={loading}>
										Remove
									</Button>
								</Box>
							</Box>
							<Divider />
						</Grid>
					))}
					<Grid item xs={12}>
						<Button
							variant="outlined"
							onClick={() =>
								hotelsAppend({
									name: '',
									description: '',
								})
							}>
							Add another hotel
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Button type="submit" variant="contained" sx={conBrandButton}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
