import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { getVendorExhibits } from '../services/VendorExhibitsService';
import DashCard from '../../common/components/DashCard';

const VendorTypeItem = ({ name, count, limit }) => {
	return (
		<ListItemButton onClick={() => {}}>
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<>
						<Typography component="span" variant="body2" color="text.primary">
							Confirmed: {count} {!!limit && `Limit: ${limit}`}
						</Typography>
					</>
				}
			/>
		</ListItemButton>
	);
};

export default ({ conEventId }) => {
	const [listData, setListData] = useState();

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { vendorExhibits } = await getVendorExhibits({
					conEventId,
					includeVendorTypes: true,
				});

				const vendorTypes = [];
				const te = {};
				for (const ve of vendorExhibits) {
					if (!te[ve.conEventVendorType.id]) {
						te[ve.conEventVendorType.id] = [];
						vendorTypes.push(ve.conEventVendorType);
					}

					te[ve.conEventVendorType.id].push(ve);
				}

				const ld = vendorTypes.map(vendorType => ({
					vendorType,
					exhibits: te[vendorType.id],
				}));

				setListData(ld);
			} catch (e) {}
		};
		fetchData().then();
	}, [conEventId]);

	let content = <CircularProgress />;

	if (listData) {
		const items = listData.map(ld => (
			<Fragment key={ld.vendorType.id}>
				<VendorTypeItem
					name={ld.vendorType.name}
					limit={ld.vendorType.limit}
					count={ld.exhibits.length}
				/>
				<Divider />
			</Fragment>
		));
		content = <List>{items}</List>;
	}

	return (
		<DashCard
			title="Vendors Exhibiting"
			bottom={<Button onClick={() => navigate(`exhibitors`)}>View All</Button>}>
			<Box>
				<Box sx={{ flex: 1 }}>{content}</Box>
			</Box>
		</DashCard>
	);
};
