import { camelizeKeys, decamelizeKeys } from 'humps';
import { getConfig } from '../../config/config';

const config = getConfig();

export const apiGet = async (url, { searchParams } = {}) => {
	if (searchParams) {
		for (let key in searchParams) {
			if (searchParams[key] === undefined) delete searchParams[key];
		}
	}

	const params = new URLSearchParams(decamelizeKeys(searchParams));
	const finalUrl = searchParams
		? `${config.apiHost}${url}?${params.toString()}`
		: `${config.apiHost}${url}`;

	const resp = await fetch(finalUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	const json = await resp.json();
	const camelizedJson = camelizeKeys(json);

	if (!resp.ok) {
		throw new Error(camelizedJson.statusText);
	}

	return camelizedJson;
};
