import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default () => {
	return (
		<Card>
			<CardContent>
				<Typography variant="h3">Getting Started</Typography>
				<Typography variant="p">You have no Vendor Brands currently</Typography>
				<div>
					<Button component={Link} to="/vendors/create" variant="outlined">
						Create your first Vendor Brand
					</Button>
				</div>
			</CardContent>
		</Card>
	);
};
