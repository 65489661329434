import {
	Box,
	Button,
	Divider,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const PanelItem = ({ panel: { name, startTime, endTime, location } }) => {
	const displayDay = dayjs(startTime).format('ddd MMM DD YYYY');
	const displayStartTime = dayjs(startTime).format('h:mm A');
	const displayEndTime = dayjs(endTime).format('h:mm A');
	return (
		<ListItem alignItems="flex-start">
			<ListItemText
				primary={<Typography variant="h5">{name}</Typography>}
				secondary={
					<React.Fragment>
						{location && (
							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.primary">
								at {location} {'\n'}
							</Typography>
						)}
						<Typography
							sx={{ display: 'block' }}
							component="span"
							variant="body2"
							color="text.primary">
							{displayDay} from {displayStartTime} to {displayEndTime}
						</Typography>
					</React.Fragment>
				}
			/>
		</ListItem>
	);
};

export default ({ schedule }) => {
	const [previewPanels, setPreviewPanels] = useState([]);
	useEffect(() => {
		const shuffled = schedule.panels.slice().sort(() => 0.5 - Math.random());
		setPreviewPanels(shuffled.slice(0, 3));
	}, [schedule]);

	const panelItems = previewPanels.map(panel => (
		<Fragment key={panel.id}>
			<PanelItem panel={panel} />
			<Divider />
		</Fragment>
	));

	return (
		<Box pt={2} mb={3}>
			<Typography variant="h6" gutterBottom>
				Schedule
			</Typography>
			<Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
				{panelItems}
			</Box>
			<Button
				component={Link}
				to={'schedule'}
				variant="outlined"
				sx={{ width: '100%' }}>
				View Complete Schedule
			</Button>
		</Box>
	);
};
