import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import DashCard from '../../common/components/DashCard';

export default ({
	panel: { name, description, location, startTime, endTime },
}) => {
	const displayDay = dayjs(startTime).format('MMM DD YYYY');
	const displayStartTime = dayjs(startTime).format('h:mm A');
	const displayEndTime = dayjs(endTime).format('h:mm A');
	return (
		<DashCard title={name} size="fit" style={{ width: '100%' }}>
			<Typography variant="caption-text">
				{displayDay} {displayStartTime} - {displayEndTime}
			</Typography>
			{location && <Typography>location: {location}</Typography>}
			<Typography style={{ marginTop: 8 }}>{description}</Typography>
		</DashCard>
	);
};
