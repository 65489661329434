import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';
import {
	Button,
	Card,
	Grid,
	FormLabel,
	Divider,
	ListItem,
	List,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { createConEvent, updateConEvent } from '../services/ConService';
import FormTextField from '../../form/FormTextField';
import FormDatePicker from '../../form/FormDatePicker';
import { conBrandButton, formContainer } from './ConBrandForm.styles';
import AddressFields from '../../form/components/AddressFields';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import GenreSelect from './GenreSelect';
import ConContext from '../contexts/ConContext';
import { getVenues } from '../../con-brands/services/VenuesService';
import FormSelect from '../../form/FormSelect';

const formSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a vendor name'),
	startTime: Yup.date()
		.typeError('Please input a valid date')
		.required('Please enter a start time'),
	endTime: Yup.date()
		.typeError('Please input a valid date')
		.required('Please enter a end time')
		.min(Yup.ref('startTime'), 'End time has to be after start time'),
	venueId: Yup.lazy(value =>
		typeof value === 'number'
			? Yup.number().required('Please pick a venue')
			: Yup.string().required('Please pick a venue'),
	),
	venue: Yup.object()
		.nullable()
		.when('venueId', {
			is: val => val === 'new',
			then: Yup.object()
				.nullable()
				.shape({
					name: Yup.string().required('Please enter a name the new venue'),
					address: Yup.object().shape({
						street1: Yup.string().required('Please enter a street'),
						city: Yup.string().required('Please enter a city'),
						state: Yup.string().required('Please enter a state'),
						postalCode: Yup.string().required('Please enter a postalCode'),
					}),
				}),
			otherwise: Yup.object().nullable().notRequired(),
		}),
	conEventVendorTypes: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Please enter a name for this vendor type'),
			price: Yup.number()
				.typeError('Price must be a number')
				.nullable()
				.min(0, 'Must be 0 or greater'),
			limit: Yup.number()
				.typeError('Price must be a number')
				.nullable()
				.min(0, 'Must be 0 or greater'),
		}),
	),
});

export default ({ conBrandId, editing, conEvent }) => {
	const { conBrands } = useContext(ConContext);

	const conBrand = conBrands.find(cb => cb.id.toString() === conBrandId);

	const defaultValues = editing ? { ...conEvent } : { conBrandId };

	const { handleSubmit, control, setValue, watch } = useForm({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	const {
		fields: vTFields,
		append: vtAppend,
		remove: vTRemove,
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'conEventVendorTypes', // unique name for your Field Array
	});

	const { showSnackbar } = useContext(SnackbarContext);

	const [venues, setVenues] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const resp = await getVenues(conBrandId || conEvent.conBrandId);
				if (resp.status === 'success') {
					setVenues(resp.venues);
				} else {
					showSnackbar({ message: 'Try again later', type: 'error' });
				}
			} catch (e) {
				showSnackbar({ message: 'Try again later', type: 'error' });
			}
		};
		fetchData().then();
	}, [conBrandId, showSnackbar, conEvent?.conBrandId]);

	const venueOptions = useMemo(() => {
		const vo = (venues || []).map(venue => ({
			label: venue.name,
			value: venue.id,
		}));
		if (!editing) vo.unshift({ label: 'New Venue', value: 'new' });
		return vo;
	}, [venues, editing]);

	useEffect(() => {
		if (conBrand) setValue('genre', conBrand.genre);
	}, [conBrand, setValue]);

	const venueValue = watch('venueId');
	const startTime = dayjs(watch('startTime'));

	const navigate = useNavigate();

	const handleCreateConEvent = async data => {
		try {
			const { status, conEvent } = await createConEvent(data);

			if (status === 'success') {
				navigate(`/con-brand/${conBrandId}/con-event/${conEvent.id}`);
				showSnackbar({
					message: `${conEvent.name} successfully created!`,
					type: 'success',
				});
			} else {
				showSnackbar({
					message: `Failed creating event. Please try again later`,
					type: 'error',
				});
			}
		} catch (e) {
			console.error('error', e);
			showSnackbar({
				message: `Failed creating event. Please try again later`,
				type: 'error',
			});
		}
	};

	const handleUpdateConEvent = async data => {
		try {
			const { status } = await updateConEvent(conEvent.id, data);
			if (status === 'success') {
				navigate(`/con-brand/${conEvent.conBrandId}/con-event/${conEvent.id}`);
				showSnackbar({
					message: `${data.name} successfully updated!`,
					type: 'success',
				});
			} else {
				showSnackbar({
					message: `Failed updating event. Please try again later`,
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: `Failed updating event. Please try again later`,
				type: 'error',
			});
		}
	};

	const title = editing ? `Edit ${conEvent.name}` : 'Create a Event';

	return (
		<Card sx={formContainer}>
			<h1>{title}</h1>
			<form
				onSubmit={handleSubmit(
					editing ? handleUpdateConEvent : handleCreateConEvent,
				)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormTextField control={control} label="Event Name *" name="name" />
					</Grid>
					{!editing && (
						<Grid item xs={12}>
							<GenreSelect control={control} label="Genre *" name="genre" />
						</Grid>
					)}
					<Grid item xs={12} sm={6}>
						<FormDatePicker
							control={control}
							label="Start Time"
							name="startTime"
							inputId="startTime"
							type="datetime"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDatePicker
							control={control}
							label="End Time"
							name="endTime"
							inputId="endTime"
							type="datetime"
							pickerParams={{
								minDateTime: startTime,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<FormSelect
							control={control}
							name="venueId"
							label="Venue"
							options={venueOptions}
							blankText="Choose Venue"
							defaultValue="new"
						/>
					</Grid>
					{venueValue === 'new' && (
						<Grid item xs={12}>
							<FormTextField
								control={control}
								label="Venue Name *"
								name="venue.name"
							/>
							<AddressFields
								control={control}
								namePrefix="venue.address"
								required
							/>
						</Grid>
					)}
					{!editing && (
						<Grid item display="flex" flexDirection="column" xs={12}>
							<FormLabel>Vendor Types</FormLabel>
							<List>
								{vTFields.map((vendorType, index) => (
									<ListItem key={index} sx={{ flexDirection: 'column' }}>
										<FormTextField
											control={control}
											label="Name *"
											name={`conEventVendorTypes.${index}.name`}
										/>
										<FormTextField
											control={control}
											label="price"
											type="numeric"
											name={`conEventVendorTypes.${index}.price`}
										/>
										<FormTextField
											control={control}
											label="Limit vendors of this type"
											helperText="Leave at 0 for unlimited"
											type="numeric"
											name={`conEventVendorTypes.${index}.limit`}
										/>
										<Button
											variant="outlined"
											sx={{ marginBottom: 1 }}
											onClick={() => vTRemove(index)}>
											Remove
										</Button>
										<Divider />
									</ListItem>
								))}
							</List>
							<Button
								variant="outlined"
								onClick={() =>
									vtAppend({
										name: '',
										price: 0,
										limit: 0,
									})
								}>
								+
							</Button>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button type="submit" variant="contained" sx={conBrandButton}>
							{editing ? 'Update Event Info' : 'Create Event'}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Card>
	);
};
