import React from 'react';
import { Container } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import VenueForm from '../components/VenueForm';

const CreateVenuePage = () => {
	return (
		<PageContainer title="Create Venue">
			<Container>
				<VenueForm />
			</Container>
		</PageContainer>
	);
};

export default CreateVenuePage;
