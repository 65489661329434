import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

import DashCard from '../../../common/components/DashCard';
import AddGalleryImageModal from './AddGalleryImageModal';
import { getGalleryImages } from '../../services/GalleriesService';
import GalleryImageItem from './GalleryImageItem';
import ViewGalleryImageModal from './ViewGalleryImageModal';

export default ({ conEventId }) => {
	const [showAddModal, setShowAddModal] = useState(false);
	const [viewImage, setViewImage] = useState();
	const [galleryImages, setGalleryImages] = useState([]);

	const openViewImage = useCallback(
		image => {
			setViewImage(image);
		},
		[setViewImage],
	);

	const fetchGalleryImages = useCallback(async () => {
		const res = await getGalleryImages(conEventId);

		setGalleryImages(res.galleryImages);
	}, [conEventId, setGalleryImages]);

	useEffect(() => {
		fetchGalleryImages().then();
	}, [fetchGalleryImages]);

	const imageItems = galleryImages.map(gi => (
		<Grid key={gi.id} item xs={4}>
			<GalleryImageItem galleryImage={gi} onClick={openViewImage} />
		</Grid>
	));

	return (
		<DashCard
			title="Gallery Images"
			size="max"
			right={
				<Button
					variant="contained"
					onClick={() => {
						setShowAddModal(true);
					}}>
					New Image
				</Button>
			}>
			These images will be displayed on your event's public page
			<Box>
				<Grid container spacing={2}>
					{imageItems}
				</Grid>
			</Box>
			<AddGalleryImageModal
				open={showAddModal}
				onClose={() => setShowAddModal(false)}
				conEventId={conEventId}
				fetchGalleryImages={fetchGalleryImages}
			/>
			<ViewGalleryImageModal
				open={viewImage}
				onClose={() => setViewImage(null)}
				galleryImage={viewImage}
				conEventId={conEventId}
				fetchGalleryImages={fetchGalleryImages}
			/>
		</DashCard>
	);
};
