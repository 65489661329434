import { authPost, authPut } from '../../auth/helpers/AuthRequests';
import { uploadCloudinaryImage } from '../../common/services/CloudinaryService';

export const updateConBrand = async (conBrandId, data) => {
	const conBrand = {
		...data,
		createdAt: undefined,
		updatedAt: undefined,
	};

	const resp = await authPut(`/api/cons/con_brands/${conBrandId}`, {
		conBrand,
	});

	return resp;
};

export const postSetProfileImage = async (conBrandId, file) => {
	try {
		const { url, publicId } = await uploadCloudinaryImage(file);
		return await authPost(
			`/api/cons/con_brands/${conBrandId}/set_profile_image`,
			{
				imageUrl: url,
				publicId,
			},
		);
	} catch (e) {
		console.log('error', e);
	}
};
