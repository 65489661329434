import React from 'react';
import DashCard from '../../common/components/DashCard';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default ({ vendor }) => {
	const navigate = useNavigate();
	return (
		<DashCard
			title={vendor.name}
			size="short"
			right={
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={() => {
						navigate('edit');
					}}>
					<Edit />
				</IconButton>
			}></DashCard>
	);
};
