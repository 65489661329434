import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import ContactLinksDisplay from './ContactLinksDisplay';

export default ({ conBrand, following, loading, onFollow }) => {
	return (
		<Card>
			<CardContent>
				<Box display="flex">
					{conBrand.profileImage?.url && (
						<img
							src={conBrand.profileImage.url}
							style={{ height: 75, marginRight: 15 }}
							alt="cover"
						/>
					)}
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						flex={1}>
						<Box>
							<Typography variant="subtitle">organized by</Typography>
							<Typography variant="h6">{conBrand.name}</Typography>
						</Box>
						<Button disabled={loading} variant="contained" onClick={onFollow}>
							{following ? 'Unfollow' : 'Follow'}
						</Button>
					</Box>
				</Box>
				<ContactLinksDisplay contactLinks={conBrand?.socialMedia || {}} />
			</CardContent>
		</Card>
	);
};
