import React, { useContext } from 'react';
import { Grid } from '@mui/material';

import NoVendorCard from './NoVendorCard';
import VendorRecentUpdatesCard from './VendorRecentUpdatesCard';
import VendorContext from '../../../vendors/context/VendorContext';

export default () => {
	const { vendors } = useContext(VendorContext);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<VendorRecentUpdatesCard />
				</Grid>
				{vendors.length === 0 && (
					<Grid item xs={12}>
						<NoVendorCard />
					</Grid>
				)}
			</Grid>
		</>
	);
};
