import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';

export default ({ vendor: { name, publicInfo, profileImage } }) => {
	return (
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={name} src={profileImage?.url} />
			</ListItemAvatar>
			<ListItemText
				primary={name}
				secondary={
					<React.Fragment>
						<Typography
							sx={{ display: 'inline' }}
							component="span"
							variant="body2"
							color="text.primary">
							{publicInfo?.tagline}
						</Typography>
					</React.Fragment>
				}
			/>
		</ListItem>
	);
};
