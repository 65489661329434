import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { getUnreadMessageChannels } from '../services/MessageChannelsService';
import UserContext from '../../auth/contexts/UserContext';

export const MessageContext = createContext({});

export const MessageProvider = ({ children }) => {
	const [unreadMessageCount, setUnreadMessageCount] = useState(0);
	const { user } = useContext(UserContext);

	const updateUnreadChannels = useCallback(async () => {
		const res = await getUnreadMessageChannels();

		if (res.status === 'success') {
			setUnreadMessageCount(res.unreadMessageChannelsCount);
		}
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (user) updateUnreadChannels();
		}, 12000);
		if (user) updateUnreadChannels();

		return () => clearInterval(interval);
	}, [user, user?.id, updateUnreadChannels]);

	return (
		<MessageContext.Provider
			value={{
				unreadMessageCount,
				updateUnreadChannels,
			}}>
			{children}
		</MessageContext.Provider>
	);
};
