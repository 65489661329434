import React, { Fragment } from 'react';
import {
	Box,
	Card,
	CardContent,
	Divider,
	List,
	Modal,
	Typography,
} from '@mui/material';

import { modalContainer } from '../../form/components/ModalContainer.styles';
import AttractionListItem from './AttractionListItem';

export default ({ open, onClose, attractions }) => {
	const items = attractions.map(attraction => (
		<Fragment key={attraction.id}>
			<AttractionListItem attraction={attraction} />
			<Divider />
		</Fragment>
	));

	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent sx={{ height: 400 }}>
						<Typography variant="h4">Attractions / Guests</Typography>
						<List sx={{ overflow: 'auto', height: 330 }}>{items}</List>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
