import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import RegisterForm from '../RegisterForm';
import { registerContainer } from './RegisterPage.styles';

export default () => {
	useEffect(() => {
		document.title = 'Konvene | Register';
	}, []);

	const navigate = useNavigate();

	return (
		<Box sx={registerContainer}>
			<Grid container flex={1}>
				<RegisterForm
					onSuccess={() => {
						navigate('/');
					}}
				/>
			</Grid>
		</Box>
	);
};
