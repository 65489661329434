import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { modalContainer } from '../../form/components/ModalContainer.styles';
import { Box, Card, CardContent, Modal } from '@mui/material';
import FormTimePicker from '../../form/FormTimePicker';

export default ({ open, startTime, endTime, onClose, handleSave }) => {
	const { handleSubmit, control, reset, getValues } = useForm({
		//resolver: yupResolver(),
		initialValues: {
			startTime,
			endTime,
		},
	});

	useEffect(() => {
		reset({ startTime, endTime });
	}, [reset, startTime, endTime]);

	return (
		<Modal
			open={open}
			onClose={() => {
				const [st, et] = getValues(['startTime', 'endTime']);
				onClose();
				handleSave({ startTime: st, endTime: et });
			}}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent>
						<form onSubmit={handleSubmit(() => {})}>
							<FormTimePicker
								control={control}
								label="Start Time"
								name="startTime"
								inputId="startTime"
							/>

							<FormTimePicker
								control={control}
								label="End Time"
								name="endTime"
								inputId="endTime"
							/>
						</form>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
