import React, { useContext } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import FormTextField from '../../form/FormTextField';
import { conBrandButton } from '../../cons/components/ConBrandForm.styles';
import { createVendorExhibit } from '../../vendors/services/VendorApplicationsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default ({
	conEventName,
	conEventId,
	vendorId,
	vendorApplicationId,
	conBrandId,
}) => {
	const navigate = useNavigate();
	const { handleSubmit, control } = useForm({
		defaultValues: {
			conEventId,
			vendorId,
		},
	});

	const { showSnackbar } = useContext(SnackbarContext);

	const addExhibit = async data => {
		try {
			const res = await createVendorExhibit(
				vendorApplicationId,
				conEventId,
				data,
			);

			if (res.status === 'success') {
				showSnackbar({ message: `Exhibitor added!`, type: 'success' });
				navigate(`/con-brand/${conBrandId}/con-event/${conEventId}`);
			} else {
				showSnackbar({
					message: 'Error adding exhibitor, please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Error adding exhibitor, please try again later',
				type: 'error',
			});
		}
	};

	return (
		<Card>
			<CardContent>
				<form onSubmit={handleSubmit(addExhibit)}>
					<Typography variant="h6" marginBottom={1}>
						This vendor has submitted a payment
					</Typography>
					<Typography variant="body1">
						Verify their payment and accept this vendor as an exhibitor for{' '}
						{conEventName}
					</Typography>
					<FormTextField
						control={control}
						label="Booth Number"
						name="boothNumber"
					/>

					<Button type="submit" variant="contained" sx={conBrandButton}>
						Add as exhibitor
					</Button>
				</form>
			</CardContent>
		</Card>
	);
};
