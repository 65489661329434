import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import UserContext from '../../auth/contexts/UserContext';
import ClaimVendorSignedIn from '../components/ClaimVendorSignedIn';
import { fetchVendorClaim } from '../services/VendorsService';
import ClaimVendorSignedOut from '../components/ClaimVendorSignedOut';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const { token } = useParams();
	const { user } = useContext(UserContext);
	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const [vendor, setVendor] = useState();

	useEffect(() => {
		const loadData = async () => {
			try {
				const res = await fetchVendorClaim(token);

				if (res.status === 'success') {
					setVendor(res.vendor);
				} else {
					if (res.error === 'vendor_owned') {
						showSnackbar({ message: 'Vendor not available', type: 'error' });
					} else {
						showSnackbar({ message: 'Something went wrong', type: 'error' });
					}
					navigate('/');
				}
			} catch (e) {
				showSnackbar({ message: 'Something went wrong', type: 'error' });
				navigate('/');
			}
		};
		loadData();
	}, [navigate, showSnackbar, token]);

	return (
		<PageContainer viewContextOverride="Konvene" title="Claim Vendor Profile">
			<Container>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					pt={10}>
					{user ? (
						<ClaimVendorSignedIn vendor={vendor} token={token} />
					) : (
						<ClaimVendorSignedOut vendor={vendor} />
					)}
				</Grid>
			</Container>
		</PageContainer>
	);
};
