import React from 'react';
import Typography from '@mui/material/Typography';

import AddressDisplay from '../../utils/AddressDisplay';
import DashCard from '../../common/components/DashCard';
export default ({ venue: { address } }) => {
	return (
		<DashCard title="Address" size="short">
			<Typography variant="p">
				Address:
				<AddressDisplay address={address} />
			</Typography>
		</DashCard>
	);
};
