import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		background: {
			default: '#091322',
			paper: '#091322',
		},
		primary: {
			main: '#80deea',
		},
		text: {
			primary: '#80deea',
			secondary: '#e0f7fa',
			default: '#f50057',
		},
	},
	typography: {
		fontFamily: [
			'Roboto',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
		].join(','),
	},
	components: {
		MuiListSubheader: {
			styleOverrides: {
				root: {
					fontSize: 16,
					fontWeight: 600,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: 14,
				},
			},
		},
	},
});
