import { uploadCloudinaryImage } from '../../common/services/CloudinaryService';
import { authGet, authPost } from '../../auth/helpers/AuthRequests';

export const getAttractions = async conEventId => {
	return authGet(`/api/attractions`, {
		searchParams: {
			conEventId,
		},
	});
};

export const postCreateAttraction = async (conEventId, data) => {
	if (data.image) {
		const { url, publicId } = await uploadCloudinaryImage(data.image.file);
		return await authPost(`/api/attractions`, {
			attraction: {
				...data,
				conEventId,
			},
			conEventId,
			imageUrl: url,
			publicId,
		});
	} else {
		return await authPost(`/api/attractions`, {
			attraction: {
				...data,
				conEventId,
			},
			conEventId,
		});
	}
};
