import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { Controller } from 'react-hook-form';

const PhoneInput = forwardRef((props, ref) => {
	const { onChange, ...rest } = props;
	return (
		<IMaskInput
			{...rest}
			mask="(000) 000-0000"
			inputRef={ref}
			onAccept={onChange}
		/>
	);
});

const PhoneTextField = ({ control, name, rules, label, ...props }) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue=""
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<TextField
					label={label}
					value={value}
					onChange={onChange}
					error={!!error}
					helperText={error?.message}
					fullWidth
					InputProps={{
						inputComponent: PhoneInput,
					}}
					{...props}
				/>
			)}
		/>
	);
};

export default PhoneTextField;
