import React from 'react';
import { Container } from '@mui/material';

import ConEventForm from '../components/ConEventForm';
import { useParams } from 'react-router-dom';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const { conBrandId } = useParams();
	return (
		<PageContainer title="Create New Event">
			<Container>
				<ConEventForm conBrandId={conBrandId} />
			</Container>
		</PageContainer>
	);
};
