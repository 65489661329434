import React, { useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';

import VendorContext from '../context/VendorContext';
import VendorApplicationsCard from '../components/VendorApplicationsCard';
import VendorInfoCard from '../components/VendorInfoCard';
import DashCard from '../../common/components/DashCard';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { getConfig } from '../../../config/config';
import { Edit } from '@mui/icons-material';
import PageContainer from '../../common/components/PageContainer';
import VendorSubNav from '../components/VendorSubNav';
import UpcomingExhibitorCard from '../components/UpcomingExhibitorCard';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

export default () => {
	const { vendors, loading } = useContext(VendorContext);
	const { id } = useParams();

	const navigate = useNavigate();

	const vendor = vendors.find(v => v.id.toString() === id);
	if (!vendor) {
		if (loading) return null;
		return <Navigate to="/" />;
	}

	const img = cld.image(vendor.profileImage?.publicId).format('png');

	return (
		<PageContainer title={vendor?.name}>
			<VendorSubNav value="overview" vendorId={id} />

			<Container>
				<Typography variant="h2">{vendor.name}</Typography>

				<Grid container spacing={2}>
					<Grid item xs={9} md={9}>
						<VendorInfoCard vendor={vendor} />
					</Grid>
					<Grid item xs={3}>
						<DashCard
							title="Profile"
							size="short"
							right={
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="menu"
									sx={{ mr: 2 }}
									onClick={() => {
										navigate('profile-image');
									}}>
									<Edit />
								</IconButton>
							}>
							{vendor.profileImage ? (
								<Box
									sx={{
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
									}}>
									<AdvancedImage
										cldImg={img}
										style={{
											maxWidth: '100%',
											maxHeight: '100%',
											width: 'auto',
											height: 'auto',
										}}
									/>
								</Box>
							) : (
								<Button
									onClick={() => {
										navigate('profile-image');
									}}>
									Change
								</Button>
							)}
						</DashCard>
					</Grid>
					<Grid item xs={12} md={6}>
						<VendorApplicationsCard vendor={vendor} />
					</Grid>
					<Grid item xs={12} md={6}>
						<UpcomingExhibitorCard vendor={vendor} />
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
