import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import ExhibitViewModal from './ExhibitViewModal';
import AddQuickVendorModal from './exhibitors/AddQuickVendorModal';
import { getVendorExhibits } from '../services/VendorExhibitsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default ({ conEventId, vendorTypes }) => {
	const [selectedExhibitId, setSelectedExhibitId] = useState();
	const [openVendorModal, setOpenVendorModal] = useState(false);
	const [openAddVendorModal, setOpenAddVendorModal] = useState(false);

	const [exhibits, setExhibits] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pagData, setPagData] = useState({ pages: 1, page: 1 });
	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(
		async page => {
			setLoading(true);
			const res = await getVendorExhibits({
				conEventId,
				page,
			});
			if (res.status === 'success') {
				setExhibits(res.vendorExhibits);
				setPagData(res.pagyInfo);
			} else {
				showSnackbar({
					message: 'Error fetching data, Try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[setLoading, conEventId, setExhibits, setPagData, showSnackbar],
	);

	useEffect(() => {
		fetchData().then();
	}, [fetchData]);

	const tableContent = exhibits.map(exhibit => (
		<TableRow
			key={exhibit.id}
			hover
			onClick={() => {
				setOpenVendorModal(true);
				setSelectedExhibitId(exhibit.id);
			}}>
			<TableCell>{exhibit.vendor.name}</TableCell>
			<TableCell>{exhibit.conEventVendorType.name}</TableCell>
			<TableCell>{exhibit.boothNumber}</TableCell>
			<TableCell>{exhibit.confirmationCode}</TableCell>
		</TableRow>
	));

	return (
		<Card>
			<CardContent>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Typography variant="h4">Vendors Exhibiting</Typography>
					<Box alignSelf="flex-end">
						<Button
							variant="contained"
							onClick={() => setOpenAddVendorModal(true)}>
							Add Vendor
						</Button>
					</Box>
				</Box>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Vendor</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Booth</TableCell>
								<TableCell>Confirmation</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{tableContent}</TableBody>
					</Table>
				</TableContainer>
				<Box display="flex" justifyContent="center" mt={2}>
					<Pagination
						count={pagData?.pages}
						page={pagData?.page}
						onChange={(_, value) => {
							if (value !== pagData?.page) fetchData(value).then();
						}}
						size="large"
						disabled={loading}
					/>
				</Box>
			</CardContent>
			<ExhibitViewModal
				open={openVendorModal}
				exhibitId={selectedExhibitId}
				onClose={() => setOpenVendorModal(false)}
				refetchExhibits={fetchData}
			/>
			<AddQuickVendorModal
				open={openAddVendorModal}
				onClose={() => setOpenAddVendorModal(false)}
				vendorTypes={vendorTypes}
				refetchExhibits={fetchData}
			/>
		</Card>
	);
};
