import React, { useContext } from 'react';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import ProfileImageUpload from '../components/ProfileImageUpload';
import VendorContext from '../context/VendorContext';
import VendorSubNav from '../components/VendorSubNav';
import { postSetProfileImage } from '../services/VendorsService';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

export default () => {
	const { id } = useParams();
	const { refreshVendors } = useContext(VendorContext);
	const { showSnackbar } = useContext(SnackbarContext);

	return (
		<PageContainer title="Change Profile Picture">
			<VendorSubNav value="editPublicInfo" vendorId={id} />
			<Container>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center">
					<Grid item xs={10}>
						<ProfileImageUpload
							onSubmit={async image => {
								const res = await postSetProfileImage(id, image.file);
								if (res.status === 'success') {
									refreshVendors();
									showSnackbar({
										message: 'Profile Image updated!',
										type: 'success',
									});
								}
							}}
						/>
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
