import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import NoPaymentsCard from '../components/NoPaymentsCard';
import { getStripeStatus } from '../services/StripeServices';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import PageContainer from '../../common/components/PageContainer';

export default () => {
	const [searchParams] = useSearchParams();
	const [status, setStatus] = useState();

	const { showSnackbar } = useContext(SnackbarContext);

	if (searchParams.get('ref') === 'expired') {
		showSnackbar({
			message: 'Error sending invoice, please try again later',
			type: 'error',
		});
		searchParams.set('ref', null);
	}

	useEffect(() => {
		const fetchData = async () => {
			const { stripeStatus } = await getStripeStatus();
			setStatus(stripeStatus);
		};
		fetchData().then();
	}, []);

	return (
		<PageContainer title="Payments">
			<Container>
				<NoPaymentsCard status={status} />
			</Container>
		</PageContainer>
	);
};
