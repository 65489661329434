import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

export default ({ control, name, rules, styles, label, ...props }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value } }) => (
				<FormControlLabel
					label={label}
					sx={{ marginBottom: 2 }}
					{...props}
					control={
						<Checkbox
							checked={value || false}
							onChange={e => onChange(e.target.checked)}
						/>
					}
				/>
			)}
		/>
	);
};
