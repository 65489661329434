import React from 'react';
import {
	Card,
	CardContent,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormGroup,
	Switch,
} from '@mui/material';
import { decamelize } from 'humps';

import Typography from '@mui/material/Typography';
import { authPost } from '../../auth/helpers/AuthRequests';

const PreferenceOptions = { alertEmailEnabled: 'Update Alerts' };

const OptionSwitch = ({ preferenceKey, label, defaultValue }) => {
	return (
		<FormGroup>
			<FormControlLabel
				control={<Switch defaultChecked={defaultValue} />}
				label={label}
				onChange={event => {
					const url = event.target.checked
						? '/api/preferences/subscribe'
						: '/api/preferences/unsubscribe';

					authPost(url, { preferenceKey: decamelize(preferenceKey) }).then();
				}}
			/>
		</FormGroup>
	);
};
export default ({ preferences }) => {
	return (
		<Card>
			<CardContent sx={{ padding: 5 }}>
				<Typography variant="h3">Email Preferences</Typography>
				<Divider />
				{preferences ? (
					<>
						{Object.entries(preferences).map(([key, value]) => {
							const option = PreferenceOptions[key];
							return (
								<OptionSwitch
									key={key}
									preferenceKey={key}
									label={option}
									defaultValue={value}
								/>
							);
						})}
					</>
				) : (
					<CircularProgress />
				)}
			</CardContent>
		</Card>
	);
};
