import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

export default ({ attraction: { name, publicInfo, clImage } }) => {
	return (
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={name} src={clImage?.url} />
			</ListItemAvatar>
			<ListItemText primary={name} />
		</ListItem>
	);
};
