import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageContainer from '../../common/components/PageContainer';
import { fetchConEvent } from '../../cons/services/ConService';
import ConEventSubNav from '../components/ConEventSubNav';
import PublicInfoSubNav from '../components/PublicInfoSubNav';
import RulesForm from '../components/RulesForm';
import HotelsForm from '../components/HotelsForm';
import FAQForm from '../components/FAQForm';
import SponsorsForm from '../components/SponsorsForm';

export default () => {
	const { conEventId, conBrandId } = useParams();
	const [conEvent, setConEvent] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const conEvent = await fetchConEvent(conEventId);
			setConEvent(conEvent);
		};
		fetchData().then();
	}, [conEventId]);

	const content = (
		<>
			<h1>Other Info</h1>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RulesForm conEvent={conEvent} />
				</Grid>
				<Grid item xs={12}>
					<HotelsForm conEvent={conEvent} />
				</Grid>
				<Grid item xs={12}>
					<FAQForm conEvent={conEvent} />
				</Grid>
				<Grid item xs={12}>
					<SponsorsForm conEvent={conEvent} />
				</Grid>
			</Grid>
		</>
	);

	return (
		<PageContainer title="Misc Info">
			<ConEventSubNav
				value="editPublicInfo"
				conBrandId={conBrandId}
				conEventId={conEventId}
			/>
			<PublicInfoSubNav
				value="misc"
				conBrandId={conBrandId}
				conEventId={conEventId}
			/>
			<Container>{content}</Container>
		</PageContainer>
	);
};
