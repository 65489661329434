import React from 'react';
import { Typography } from '@material-ui/core';

export default ({ children }) => {
	return (
		<Typography variant="h2" style={{ marginBottom: 10 }}>
			{children}
		</Typography>
	);
};
