import { Box, Container, Typography } from '@mui/material';

function Copyright() {
	return (
		<Typography variant="body2" color="text.secondary" align="center">
			{'Copyright © '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default () => {
	return (
		<Box
			component="footer"
			sx={{ bgcolor: 'background.paper', py: 6, mb: 4, mt: 6 }}>
			<Container maxWidth="lg">
				<Typography
					variant="subtitle1"
					align="center"
					color="text.secondary"
					component="p">
					Konvene
				</Typography>
				<Copyright />
			</Container>
		</Box>
	);
};
