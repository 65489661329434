import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import VendorSubNav from '../components/VendorSubNav';
import VendorExhibitingsCard from '../components/VendorExhibitingsCard';

export default () => {
	const { vendorId } = useParams();

	return (
		<PageContainer title="Exhibiting At">
			<VendorSubNav value="exhibiting" vendorId={vendorId} />
			<Container>
				<VendorExhibitingsCard vendorId={vendorId} />
			</Container>
		</PageContainer>
	);
};
