import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import {
	Box,
	Button,
	Card,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../components/ConEventSubNav';
import PublicInfoSubNav from '../components/PublicInfoSubNav';
import { deleteMapImage, getMapImages } from '../services/MapImagesService';
import { getConfig } from '../../../config/config';
import MapImageModal from '../components/maps/MapImageModal';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import DashCard from '../../common/components/DashCard';

const config = getConfig();

const cld = new Cloudinary({
	cloud: { cloudName: config.cloudinaryCloudName },
});

const MapImageItem = ({ mapImage, onSelect, onDelete }) => {
	const img = cld.image(mapImage.clImage.publicId).format('png');

	return (
		<Card
			sx={{
				height: 500,
				marginBottom: 2,
				display: 'flex',
				flexDirection: 'column',
			}}>
			<Box
				sx={{
					paddingLeft: 3,
					paddingTop: 1,
					paddingBottom: 3,
				}}>
				<Typography variant="h6">{mapImage.caption}</Typography>

				<Button
					variant="contained"
					color="error"
					sx={{
						marginTop: 2,
					}}
					onClick={() => onDelete(mapImage)}>
					Delete
				</Button>
			</Box>

			<AdvancedImage
				cldImg={img}
				style={{
					maxWidth: '100%',
					maxHeight: '100%',
					width: 'auto',
					height: 350,
					objectFit: 'cover',
				}}
				onClick={() => onSelect(mapImage)}
			/>
		</Card>
	);
};

export default () => {
	const { conEventId, conBrandId } = useParams();

	const [mapImages, setMapImages] = useState([]);
	const [selectedMapImage, setSelectedMapImage] = useState(null);
	const [deleteImage, setDeleteImage] = useState(null);
	const [loading, setLoading] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchMapImages = useCallback(async () => {
		const resp = await getMapImages({ conEventId });

		if (resp) {
			setMapImages(resp.mapImages);
		} else {
			showSnackbar({
				message: 'Something went wrong, please try again later',
				type: 'error',
			});
		}
	}, [conEventId, showSnackbar]);

	const items = mapImages.map(image => (
		<Grid key={image.key} item xs={6}>
			<MapImageItem
				mapImage={image}
				onSelect={mapImage => {
					setSelectedMapImage(mapImage);
				}}
				onDelete={mapImage => setDeleteImage(mapImage)}
			/>
		</Grid>
	));

	useEffect(() => {
		fetchMapImages();
	}, [fetchMapImages]);

	const handleDeleteMapImage = useCallback(async () => {
		setLoading(true);
		try {
			const resp = await deleteMapImage(deleteImage.id);
			if (resp.status === 'success') {
				setDeleteImage(null);
				fetchMapImages();
				showSnackbar({
					message: `Map Image deleted`,
					type: 'success',
				});
			} else {
				showSnackbar({
					message: `Failed deleting map image. Please try again later`,
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: `Failed deleting map image. Please try again later`,
				type: 'error',
			});
		}
		setLoading(false);
	}, [deleteImage, fetchMapImages, showSnackbar]);

	return (
		<PageContainer title="Map Images">
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="mapImages"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<DashCard
							title="Map Images"
							size="short"
							right={
								<Button
									variant="contained"
									component={Link}
									to={`/con-brand/${conBrandId}/con-event/${conEventId}/add-map-image`}>
									Add Map Image
								</Button>
							}></DashCard>
					</Grid>
					{items}
				</Grid>
			</Container>

			<MapImageModal
				mapImage={selectedMapImage}
				onClose={() => setSelectedMapImage(null)}
			/>
			<Dialog
				open={!!deleteImage}
				onClose={() => {
					setDeleteImage(null);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Delete Map Image</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this image
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loading}
						variant="outlined"
						onClick={() => setDeleteImage(null)}>
						Cancel
					</Button>
					<Button
						color="error"
						variant="contained"
						onClick={() => {
							handleDeleteMapImage();
						}}
						disabled={loading}>
						Remove
					</Button>
				</DialogActions>
			</Dialog>
		</PageContainer>
	);
};
