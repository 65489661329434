import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default () => {
	return (
		<Card>
			<CardContent
				sx={{
					padding: 3,
					flexDirection: 'column',
					display: 'flex',
				}}>
				<Typography variant="h3">Getting Started</Typography>
				<Typography variant="p">You have no Brands currently</Typography>
				<div>
					<Button component={Link} to="/create-con-brand" variant="outlined">
						Create your first brand
					</Button>
				</div>
			</CardContent>
		</Card>
	);
};
