import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	AuthInput,
	authBoxSmall,
	authForm,
	authButton,
} from './AuthForms.styles';
import { Button, Card, CardActions, Link } from '@mui/material';
import { postResetPassword } from '../services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import InputAdornment from '@mui/material/InputAdornment';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import * as Yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

const formSchema = Yup.object().shape({
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string().oneOf(
		[Yup.ref('password')],
		'Passwords do not match',
	),
});

export default () => {
	const [searchParams] = useSearchParams();

	const token = searchParams.get('token');

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			token,
		},
		resolver: yupResolver(formSchema),
	});

	const { showSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);

	if (!token) {
		showSnackbar({
			message: 'Error resetting password, please request another link',
			type: 'error',
		});
		navigate('/');
	}

	const handleRequest = async data => {
		try {
			setSubmitting(true);

			const result = await postResetPassword(data);

			if (result) {
				navigate('/login');
				showSnackbar({
					message: 'We will email you password reset instructions',
					type: 'success',
				});
			} else {
				showSnackbar({
					message: 'Error requesting reset, please try again later',
					type: 'error',
				});
			}
		} catch (e) {
			// TODO don't handle this by throwing
			console.error('login error', e);
			showSnackbar({ message: 'Login Failed', type: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<Grid item>
				<Card sx={authBoxSmall}>
					<h1>Set new password</h1>
					<form style={authForm} onSubmit={handleSubmit(handleRequest)}>
						<Grid item xs={12}>
							<AuthInput
								control={control}
								label="Password"
								name="password"
								type="password"
								error={!!errors.password}
								helperText={errors.password?.message}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LockIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<AuthInput
								control={control}
								label="Confirm Password"
								name="confirmPassword"
								type="password"
								error={!!errors.confirmPassword}
								helperText={errors.confirmPassword?.message}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LockIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Button
							sx={authButton}
							type="submit"
							variant="contained"
							disabled={submitting}>
							Send Password Reset
						</Button>
					</form>
					<CardActions>
						<Grid mt={4}>
							Back to{' '}
							<b>
								<Link
									href="."
									onClick={() => navigate('/login')}
									underline="none">
									Change Password
								</Link>
							</b>
							.
						</Grid>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};
