import { authGet, authPost, authPut } from '../../auth/helpers/AuthRequests';
import { getConfig } from '../../../config/config';

const config = getConfig();

export const createVendor = async data => {
	const resp = await authPost('/api/vendors/vendors', {
		vendor: data,
	});
	return resp;
};

export const createVendorApplication = async data => {
	return await authPost('/api/vendors/vendor_applications', {
		vendor_application: data,
	});
};

export const updateVendorInfo = async (vendorId, data) => {
	const vendor = {
		...data,
		phone: data.phone ? data.phone.replace(/\D/g, '') : '',
		profileImageId: undefined,
		createdAt: undefined,
		updatedAt: undefined,
		owner_id: undefined,
		id: undefined,
	};

	const resp = await authPut(`/api/vendors/vendors/${vendorId}`, {
		vendor,
	});

	return resp;
};

export const getVendor = async id => {
	return await authGet(`/api/vendors/vendors/${id}`);
};

export const fetchVendorClaim = async token => {
	return await authGet('/api/vendors/show_claim', {
		allowUnauthed: true,
		searchParams: {
			token,
		},
	});
};

export const putVendorClaim = async token => {
	return await authPut('/api/vendors/claim', {
		token,
	});
};

export const postSetProfileImage = async (vendorId, file) => {
	try {
		const url = `https://api.cloudinary.com/v1_1/${config.cloudinaryCloudName}/upload`;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('upload_preset', 'k1lbllr1');
		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});
		const json = await res.json();
		return await authPost(`/api/vendors/${vendorId}/set_profile_image`, {
			imageUrl: json.secure_url,
			publicId: json.public_id,
		});
	} catch (e) {
		console.log('error', e);
	}
};

export const fetchVendorApplication = async id => {
	return await authGet(`/api/vendors/vendor_applications/${id}`);
};

export const decideVendorApplication = async (id, status) => {
	return await authPost(`/api/vendors/vendor_applications/decide/${id}`, {
		status,
	});
};

export const postSendInvoice = async id => {
	return await authPost(`/api/vendors/vendor_applications/send_invoice/${id}`);
};

export const postSendStripeInvoice = async id => {
	return await authPost(
		`/api/vendors/vendor_applications/send_stripe_invoice/${id}`,
	);
};

export const fetchVendorApplicationOptions = async conEventId => {
	const params = new URLSearchParams({ con_event_id: conEventId });
	return await authGet(`/api/vendors/application_options?${params.toString()}`);
};

export const fetchVendorApplications = async vendorId => {
	const params = new URLSearchParams({ vendor_id: vendorId });
	return await authGet(`/api/vendors/applications?${params.toString()}`);
};
