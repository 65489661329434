import React, { useContext } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	IconButton,
	Modal,
	Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import FormTextField from '../../../form/FormTextField';
import FormSelect from '../../../form/FormSelect';
import { postAddQuickVendor } from '../../services/ConEventsService';
import { modalContainer } from '../../../form/components/ModalContainer.styles';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';

const formSchema = Yup.object().shape({
	vendorTypeId: Yup.string().required('Vendor Type is required'),
	quickVendor: Yup.object().shape({
		name: Yup.string().required('Name is required'),
		email: Yup.string().email().required('Email is required'),
		phone: Yup.string().required('Phone number is required'),
	}),
});
export default ({ open, onClose, vendorTypes, refetchExhibits }) => {
	const { id } = useParams();
	const { showSnackbar } = useContext(SnackbarContext);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});
	const vendorTypeOptions = vendorTypes.map(vt => ({
		label: vt.name,
		value: vt.id,
	}));

	const handleAddVendor = async data => {
		try {
			const { status } = await postAddQuickVendor(id, data);
			if (status === 'success') {
				refetchExhibits();
				showSnackbar({ message: 'Vendor added!', type: 'success' });
				onClose();
				reset();
			} else {
				showSnackbar({
					message: 'Something went wrong, Try again later',
					type: 'error',
				});
			}
		} catch (e) {
			showSnackbar({
				message: 'Something went wrong, Try again later',
				type: 'error',
			});
		}
	};

	return (
		<Modal open={open}>
			<Box sx={modalContainer}>
				<Card>
					<CardContent>
						<Box display="flex" justifyContent="space-between">
							<Typography variant="h4">Add Vendor</Typography>

							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2 }}
								onClick={onClose}>
								<Close />
							</IconButton>
						</Box>

						<Typography gutterBottom>
							Quickly add a vendor. No invoice will be generated
						</Typography>
						<form onSubmit={handleSubmit(handleAddVendor)}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<FormSelect
										control={control}
										id="vendorTypeId"
										name="vendorTypeId"
										label="Vendor Type"
										options={vendorTypeOptions}
										blankText="select Vendor Type *"
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										control={control}
										label="Name *"
										name="quickVendor.name"
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										control={control}
										label="Email *"
										name="quickVendor.email"
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										control={control}
										label="Phone *"
										name="quickVendor.phone"
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										control={control}
										label="Booth Number"
										name="boothNumber"
									/>
								</Grid>
								<Grid item xs={12}>
									<Button type="submit" variant="contained" disabled={false}>
										Add Vendor
									</Button>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
