import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import PageContainer from '../../common/components/PageContainer';
import AttractionForm from '../components/AttractionForm';
import PublicInfoSubNav from '../../con-events/components/PublicInfoSubNav';

export default () => {
	const { conEventId, conBrandId } = useParams();

	return (
		<PageContainer>
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="attractions"
				conEventId={conEventId}
				conBrandId={conBrandId}
			/>
			<Container>
				<AttractionForm conEventId={conEventId} />
			</Container>
		</PageContainer>
	);
};
