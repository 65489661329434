import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	InputLabel,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getConEventDecideInfo } from '../services/ConService';
import ApplicationNotes from '../../applications/components/ApplicationNotes';
import { StatusTypes } from '../../vendor-applications/Constants';

const CAN_DECIDE_STATUSES = ['pending', 'accepted', 'waitlisted', 'rejected'];

export default ({ vendorApplication, decideApplication, addNote, decider }) => {
	const {
		status,
		applicationInfo: {
			name,
			address,
			email,
			ein,
			boothPreference,
			itemDescription,
		},
		conEventVendorType: { id: vendorTypeId, name: typeName },
		conEventAddOns,
		conEventId,
	} = vendorApplication;

	const [loading, setLoading] = useState(false);
	const [decideInfo, setDecideInfo] = useState();
	useEffect(() => {
		if (decider) {
			getConEventDecideInfo(conEventId).then(data => {
				setDecideInfo(data);
			});
		}
	}, [decider, setDecideInfo, conEventId]);

	const statusType = StatusTypes[status];

	let actionContent = null;
	if (decider) {
		switch (status) {
			default:
				break;
		}
	}

	const handleDecide = async action => {
		setLoading(true);
		await decideApplication(action);
		setLoading(false);
	};

	const addOnContent = conEventAddOns.map(addon => {
		const addOnInfo = decideInfo?.addOns.find(ao => ao.id === addon.id);
		const outOf =
			addOnInfo &&
			` ${addOnInfo.amount} claimed ${
				addOnInfo.limit ? `out of ${addOnInfo.limit}` : ''
			}`;
		return (
			<Typography key={addon.id}>
				{addon.name} x {addon.amount}
				<Typography
					style={{ color: '#00ffb3', fontWeight: 'bold' }}
					component="span">
					{decider && outOf}
				</Typography>
			</Typography>
		);
	});

	const vendorTypeInfo = decideInfo?.exhibits.find(e => e.id === vendorTypeId);
	const claimedVendorType = vendorTypeInfo
		? ` ${vendorTypeInfo.count} out of ${vendorTypeInfo.limit} claimed`
		: '';

	return (
		<Card>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputLabel>Status</InputLabel>
						<Typography fontWeight="bold" color={statusType.textColor}>
							{statusType.label}
						</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Vendor Type</InputLabel>
						<Typography>
							{typeName}{' '}
							<Typography
								sx={{ color: '#00ffb3' }}
								fontWeight="bold"
								component="span">
								{claimedVendorType}
							</Typography>
						</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Name</InputLabel>
						<Typography>{name}</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Email</InputLabel>
						<Typography>{email}</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Address</InputLabel>
						<Typography>{address.street1}</Typography>
						<Typography>{address.street2}</Typography>
						<Typography>{address.city}</Typography>
						<Typography>
							{address.state} {address.postalCode}
						</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Item Description</InputLabel>
						<Typography>{itemDescription}</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Booth Preference</InputLabel>
						<Typography>{boothPreference}</Typography>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<InputLabel>EIN</InputLabel>
						<Typography>{ein}</Typography>
						<Divider />
					</Grid>

					{conEventAddOns.length > 0 && (
						<Grid item xs={12}>
							<InputLabel>Requested Addons</InputLabel>
							{addOnContent}
						</Grid>
					)}

					{decider && CAN_DECIDE_STATUSES.includes(status) && (
						<Grid item xs={12}>
							<Box
								sx={{
									marginLeft: 3,
									marginTop: 3,
								}}>
								<Button
									variant="contained"
									onClick={() => {
										handleDecide('accepted');
									}}
									disabled={loading}>
									Accept
								</Button>
								<Button
									color="error"
									variant="contained"
									sx={{
										marginLeft: 2,
										marginRight: 2,
									}}
									onClick={() => {
										handleDecide('rejected');
									}}
									disabled={loading}>
									Reject
								</Button>
								<Button
									color="warning"
									variant="contained"
									onClick={() => {
										handleDecide('waitlisted');
									}}
									disabled={loading}>
									Waitlist
								</Button>
							</Box>
						</Grid>
					)}

					{vendorApplication && (
						<Grid item xs={12}>
							<ApplicationNotes
								vendorApplication={vendorApplication}
								loading={loading}
								onAddNote={async message => {
									setLoading(true);
									await addNote(message);
									setLoading(false);
								}}
							/>
						</Grid>
					)}
				</Grid>
				{status === 'offsite_payment_submitted' && actionContent}
			</CardContent>
		</Card>
	);
};
