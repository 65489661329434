import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import DashCard from '../../common/components/DashCard';
import FormSwitch from '../../form/FormSwitch';
import FormTextField from '../../form/FormTextField';
import { conBrandButton } from '../../cons/components/ConBrandForm.styles';
import ImageInput from '../../form/components/ImageInput';
import SnackbarContext from '../../snackbar/context/SnackbarContext';
import { putUpdatePublicInfoSponsors } from '../../cons/services/ConEventsService';

const formSchema = Yup.object().shape({
	active: Yup.boolean(),
	sponsors: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Please entire sponsor name'),
			description: Yup.string(),
		}),
	),
});
export default ({ conEvent }) => {
	const { showSnackbar } = useContext(SnackbarContext);

	const [loading, setLoading] = useState(false);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});

	const {
		fields: sponsorsFields,
		append: sponsorsAppend,
		remove: sponsorsRemove,
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'sponsors', // unique name for your Field Array
	});

	useEffect(() => {
		reset(conEvent?.publicInfo?.sponsors);
	}, [conEvent, reset]);

	const handleUpdateSponsors = useCallback(
		async data => {
			setLoading(true);
			try {
				await putUpdatePublicInfoSponsors(conEvent.id, data);
				showSnackbar({
					message: 'Sponsors successfully saved!',
					type: 'success',
				});
			} catch (e) {
				showSnackbar({
					message: 'Something went wrong, please try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[conEvent, showSnackbar],
	);

	return (
		<DashCard title="Sponsors" size="fit">
			<form onSubmit={handleSubmit(handleUpdateSponsors)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormSwitch control={control} name="active" label="Active" />
					</Grid>
					<Grid item xs={12}>
						<Typography>Sponsor info will show on your landing page</Typography>
					</Grid>
					{sponsorsFields.map((sponsor, index) => (
						<Grid key={index} item xs={12}>
							<Box display="flex" alignItems="center">
								<ImageInput
									control={control}
									name={`sponsors.${index}.image`}
									size={200}
								/>
								<Box ml={2}>
									<FormTextField
										control={control}
										label="Name"
										name={`sponsors.${index}.name`}
									/>
									<FormTextField
										control={control}
										label="Description"
										name={`sponsors.${index}.description`}
										multiline
										minRows={3}
									/>
									<FormTextField
										control={control}
										label="Sponsor Link"
										name={`sponsors.${index}.link`}
									/>
									<Button
										variant="outlined"
										sx={{ marginBottom: 1 }}
										onClick={() => sponsorsRemove(index)}
										disabled={loading}>
										Remove
									</Button>
								</Box>
							</Box>
							<Divider />
						</Grid>
					))}
					<Grid item xs={12}>
						<Button
							variant="outlined"
							onClick={() =>
								sponsorsAppend({
									name: '',
									description: '',
								})
							}>
							Add another sponsor
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Button type="submit" variant="contained" sx={conBrandButton}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
