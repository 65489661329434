import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import ConEventApplicationsTable from '../components/ConEventApplicationsTable';

export default () => {
	const { id, conBrandId } = useParams();

	return (
		<PageContainer title="Applications">
			<ConEventSubNav
				value="applications"
				conEventId={id}
				conBrandId={conBrandId}
			/>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ConEventApplicationsTable conEventId={id} />
					</Grid>
				</Grid>
			</Container>
		</PageContainer>
	);
};
