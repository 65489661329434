import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import PageContainer from '../../common/components/PageContainer';
import ConEventSubNav from '../../con-events/components/ConEventSubNav';
import GalleryViewCard from '../components/gallery/GalleryViewCard';
import PublicInfoSubNav from '../components/PublicInfoSubNav';

export default () => {
	const { id, conBrandId } = useParams();

	return (
		<PageContainer title="Gallery">
			<ConEventSubNav
				value="editPublicInfo"
				conEventId={id}
				conBrandId={conBrandId}
			/>
			<PublicInfoSubNav
				value="gallery"
				conEventId={id}
				conBrandId={conBrandId}
			/>
			<Container>
				<GalleryViewCard conEventId={id} />
			</Container>
		</PageContainer>
	);
};
