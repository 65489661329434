import React from 'react';
import { Container } from '@mui/material';

import ConEventAddOnForm from '../components/ConEventAddOnForm';
import PageContainer from '../../common/components/PageContainer';

const CreateConEventAddOnPage = () => {
	return (
		<PageContainer title="Create Add On">
			<Container>
				<ConEventAddOnForm />
			</Container>
		</PageContainer>
	);
};

export default CreateConEventAddOnPage;
