import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import FormTextField from '../../../form/FormTextField';
import DashCard from '../../../common/components/DashCard';
import FormDatePicker from '../../../form/FormDatePicker';
import { postCreatePanel } from '../../../schedules/services/PanelsService';
import SnackbarContext from '../../../snackbar/context/SnackbarContext';
import { getConEventSchedule } from '../../../schedules/services/SchedulesService';

const formSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a panel name'),
	startTime: Yup.date()
		.typeError('Please input a valid date')
		.required('Please enter a start time'),
	endTime: Yup.date()
		.typeError('Please input a valid date')
		.required('Please enter a end time')
		.min(Yup.ref('startTime'), 'End time has to be after start time'),
});

export default ({ conEventId, conBrandId }) => {
	const { showSnackbar } = useContext(SnackbarContext);
	const navigate = useNavigate();

	const [schedule, setSchedule] = useState();

	const fetchConEvent = useCallback(async () => {
		const res = await getConEventSchedule({ conEventId });

		setSchedule(res.schedule);
	}, [conEventId]);

	useEffect(() => {
		fetchConEvent().then();
	}, [fetchConEvent]);

	const { handleSubmit, control, reset } = useForm({
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		if (schedule)
			reset({
				startTime: schedule.hours[0]?.startTime,
				endTime: schedule.hours[0]?.startTime,
			});
	}, [reset, schedule]);

	const minRaw = schedule?.hours?.[0].startTime;
	const minDateTime = minRaw && dayjs(minRaw);
	const maxRaw = schedule?.hours?.[schedule.hours.length - 1].endTime;
	const maxDateTime = maxRaw && dayjs(maxRaw);

	const handleCreatePanel = useCallback(
		async data => {
			try {
				const { status, panel } = await postCreatePanel({
					panel: {
						...data,
						scheduleId: schedule.id,
					},
					conEventId,
				});
				if (status === 'success') {
					showSnackbar({
						message: `${panel.name} successfully created!`,
						type: 'success',
					});
					navigate(
						`/con-brand/${conBrandId}/con-event/${conEventId}/schedules/`,
					);
				} else {
					showSnackbar({
						message: `Failed creating panel. Please try again later`,
						type: 'error',
					});
				}
			} catch (e) {
				showSnackbar({
					message: `Failed creating panel. Please try again later`,
					type: 'error',
				});
			}
		},
		[conBrandId, conEventId, navigate, showSnackbar, schedule],
	);

	return (
		<DashCard title="Add a Panel" size="max">
			<form onSubmit={handleSubmit(handleCreatePanel)}>
				<Grid container spacing={2} mt={2}>
					<Grid item xs={12}>
						<FormTextField control={control} label="Name *" name="name" />
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							control={control}
							label="Description"
							name="description"
							multiline
							minRows={3}
							maxRows={3}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDatePicker
							control={control}
							label="Start Time"
							name="startTime"
							inputId="startTime"
							type="datetime"
							pickerParams={{
								minDateTime,
								maxDateTime,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDatePicker
							control={control}
							label="End Time"
							name="endTime"
							inputId="endTime"
							type="datetime"
							pickerParams={{
								minDateTime,
								maxDateTime,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormTextField control={control} label="Location" name="location" />
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" variant="contained">
							Add Panel
						</Button>
					</Grid>
				</Grid>
			</form>
		</DashCard>
	);
};
