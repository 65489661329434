import { useContext } from 'react';
import { CircularProgress, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { MuiThemeProvider } from '@material-ui/core';

import UserContext, { UserProvider } from './modules/auth/contexts/UserContext';
import Router from './Router';
import { ConProvider } from './modules/cons/contexts/ConContext';
import { darkTheme } from './modules/themes/DarkTheme';
import { VendorProvider } from './modules/vendors/context/VendorContext';
import { SnackbarProvider } from './modules/snackbar/context/SnackbarContext';
import { MessageProvider } from './modules/messages/contexts/MessageContext';

const LoadingGate = ({ children }) => {
	const { ready } = useContext(UserContext);

	if (ready) return children;
	return <CircularProgress />;
};

function App() {
	return (
		<div className="App">
			{/*// lmao what is this shit*/}
			<MuiThemeProvider theme={darkTheme}>
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />
					<SnackbarProvider>
						<UserProvider>
							<LoadingGate>
								<MessageProvider>
									<ConProvider>
										<VendorProvider>
											<Router />
										</VendorProvider>
									</ConProvider>
								</MessageProvider>
							</LoadingGate>
						</UserProvider>
					</SnackbarProvider>
				</ThemeProvider>
			</MuiThemeProvider>
		</div>
	);
}

export default App;
