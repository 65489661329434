import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Box,
	Card,
	Link as MuiLink,
	CardContent,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Pagination,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { getVendorApplications } from '../../vendors/services/VendorApplicationsService';
import { getConEventVendorTypes } from '../../cons/services/ConEventsService';
import { StatusTypes } from '../../vendor-applications/Constants';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const LoadingRow = () => (
	<TableRow component="div">
		<TableCell component="div">
			<Skeleton variant="rectangular" width="100%" />
		</TableCell>
		<TableCell component="div">
			<Skeleton variant="rectangular" width="100%" />
		</TableCell>
		<TableCell component="div">
			<Skeleton variant="rectangular" width="100%" />
		</TableCell>
	</TableRow>
);

export default ({ conEventId }) => {
	const [applications, setApplications] = useState();
	const [pagData, setPagData] = useState({ pages: 1, page: 1 });

	const [loading, setLoading] = useState(false);

	const { showSnackbar } = useContext(SnackbarContext);

	const fetchData = useCallback(
		async page => {
			setLoading(true);
			const res = await getVendorApplications({
				conEventId,
				page,
				includeVendorName: true,
			});
			if (res.status === 'success') {
				setApplications(res.vendorApplications);
				setPagData({ page: res.page, pages: res.pages });
			} else {
				showSnackbar({
					message: 'Error fetching data, Try again later',
					type: 'error',
				});
			}
			setLoading(false);
		},
		[setLoading, conEventId, setApplications, setPagData, showSnackbar],
	);

	useEffect(() => {
		fetchData().then();
	}, [fetchData]);

	const [vendorTypes, setVendorTypes] = useState([]);
	const fetchVendorTypes = useCallback(async () => {
		const res = await getConEventVendorTypes(conEventId);
		setVendorTypes(res);
	}, [conEventId, setVendorTypes]);

	useEffect(() => {
		fetchVendorTypes().then();
	}, [fetchVendorTypes]);

	let tableContent;
	if (applications) {
		tableContent = applications.map(application => {
			const vendorType = vendorTypes.find(
				vt => vt.id === application.conEventVendorTypeId,
			);
			const statusType = StatusTypes[application.status];

			return (
				<TableRow
					key={application.id}
					hover
					component={Link}
					to={`${application.id}`}
					style={{ textDecoration: 'none' }}>
					<TableCell component="div">{application.vendor.name}</TableCell>
					<TableCell component="div">{vendorType?.name}</TableCell>
					<TableCell component="div">
						<Typography fontWeight="bold" color={statusType.textColor}>
							{statusType.label}
						</Typography>
					</TableCell>
				</TableRow>
			);
		});
	} else {
		tableContent = (
			<>
				<LoadingRow />
				<LoadingRow />
				<LoadingRow />
			</>
		);
	}

	const relativeLink = `/con-event/${conEventId}/application-landing`;
	const applicationLink = `${window.location.protocol}//${window.location.host}${relativeLink}`;

	return (
		<Card>
			<CardContent>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Typography variant="h4">Vendor Applications</Typography>
				</Box>
				<Box>
					<MuiLink href={applicationLink} target="_blank">
						{applicationLink}
					</MuiLink>
				</Box>
				<TableContainer>
					<Table component="div">
						<TableHead component="div">
							<TableRow component="div">
								<TableCell component="div">Vendor</TableCell>
								<TableCell component="div">Vendor Type</TableCell>
								<TableCell component="div">Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody component="div">{tableContent}</TableBody>
					</Table>
				</TableContainer>
				<Box display="flex" justifyContent="center" mt={2}>
					<Pagination
						count={pagData?.pages}
						page={pagData?.page}
						onChange={(_, value) => {
							if (value !== pagData?.page) fetchData(value).then();
						}}
						size="large"
						disabled={loading}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};
