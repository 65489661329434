import { authDelete, authGet, authPost } from '../../auth/helpers/AuthRequests';

export const getVendorExhibits = async searchParams => {
	return await authGet(`/api/cons/vendor_exhibits/`, {
		searchParams,
	});
};

export const getVendorExhibit = async exhibitId => {
	return await authGet(`/api/cons/vendor_exhibits/${exhibitId}`, {
		searchParams: { exhibitId },
	});
};

export const deleteVendorExhibit = async exhibitId => {
	return await authDelete(`/api/cons/vendor_exhibits/${exhibitId}`);
};

export const postChangeBooth = async (exhibitId, boothNumber) => {
	return await authPost(`/api/cons/vendor_exhibits/${exhibitId}/change_booth`, {
		boothNumber,
	});
};
