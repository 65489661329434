import React, { Fragment } from 'react';
import {
	Avatar,
	Button,
	Divider,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';

const AttractionItem = ({ attraction }) => {
	return (
		<Fragment key={attraction.id}>
			<ListItemButton sx={{ height: 80 }}>
				<ListItemAvatar>
					<Avatar alt={attraction.name} src={attraction.clImage?.url} />
				</ListItemAvatar>
				<ListItemText primary={attraction.name} />
			</ListItemButton>
			<Divider />
		</Fragment>
	);
};

export default ({ attractions }) => {
	const items = (attractions || []).map(attraction => (
		<AttractionItem attraction={attraction} />
	));

	return (
		<DashCard
			title="Attractions"
			size="large"
			right={
				<Button variant="contained" component={Link} to="add">
					Add Attraction
				</Button>
			}>
			{!attractions && (
				<>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={90}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={90}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={90}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={90}
						sx={{ marginBottom: 2 }}
					/>
				</>
			)}
			<List sx={{ overflowY: 'scroll' }}>{items}</List>
		</DashCard>
	);
};
