import React, { useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	CircularProgress,
	Container,
	Grid,
	Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import Navbar from '../../common/components/navbar/Navbar';
import { getPublicConEvent } from '../../cons/services/ConService';
import ApplyAsCard from '../components/ApplyAsCard';
import Footer from '../../common/components/Footer';
import LandingDateLocation from '../../landing/components/LandingDateLocation';

export default () => {
	const { id } = useParams();
	const [conEvent, setConEvent] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		document.title = `Apply to Exhibit at ${conEvent?.name || 'event'}`;
	}, [conEvent]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const { conEvent } = await getPublicConEvent(id);
			setConEvent(conEvent);
			setLoading(false);
		};
		fetchData().then();
	}, [id]);

	const applicationInfo =
		conEvent?.publicInfo?.applicationInfo || 'Apply to be a vendor!';

	return (
		<>
			<Navbar />
			<Container>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography marginBottom={2} variant="h3">
									{conEvent.name}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<LandingDateLocation conEvent={conEvent} />
							</Grid>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Typography style={{ whiteSpace: 'pre-line' }}>
											{applicationInfo}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<ApplyAsCard
									conEventId={conEvent.id}
									vendorTypes={conEvent.conEventVendorTypes}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Container>
			<Footer />
		</>
	);
};
