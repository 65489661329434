import React, { Fragment } from 'react';
import {
	Box,
	Card,
	CardContent,
	Divider,
	List,
	Modal,
	Typography,
} from '@mui/material';

import { modalContainer } from '../../form/components/ModalContainer.styles';
import VendorListItem from './VendorListItem';

export default ({ open, onClose, vendors }) => {
	const items = vendors.map(vendor => (
		<Fragment key={vendor.id}>
			<VendorListItem vendor={vendor} />
			<Divider />
		</Fragment>
	));

	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={modalContainer}>
				<Card sx={{ padding: 2 }}>
					<CardContent sx={{ height: 400 }}>
						<Typography variant="h4">Exhibiting</Typography>
						<List sx={{ overflow: 'auto', height: 330 }}>{items}</List>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
