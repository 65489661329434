import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { ContentCopy, Edit, Launch } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

import DashCard from '../../common/components/DashCard';
import SnackbarContext from '../../snackbar/context/SnackbarContext';

const STATUS_COPY = {
	pending: {
		label: 'Pending',
		color: '#ffcd5a',
	},
	active: {
		label: 'Live!',
		color: '#9cff99',
	},
	unlisted: {
		label: 'Hidden',
		color: '#9916ff',
	},
};

export default ({
	conEvent: { id, name, status, startTime, endTime, venue },
}) => {
	const navigate = useNavigate();

	const startText = dayjs(startTime).format('ddd MMM DD YYYY [at] h:mm A');
	const endText = dayjs(endTime).format('ddd MMM DD YYYY [at] h:mm A');

	const { showSnackbar } = useContext(SnackbarContext);

	const statusCopy = STATUS_COPY[status];

	return (
		<DashCard
			title="Your Event"
			size="short"
			right={
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={() => {
						navigate('edit');
					}}>
					<Edit />
				</IconButton>
			}>
			<Box
				mb={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h6">
					Status:
					<Typography
						style={{ color: statusCopy.color, fontWeight: 'bold' }}
						variant="h6"
						component="span">
						{' '}
						{statusCopy.label}
					</Typography>
				</Typography>
				{status === 'active' && (
					<Box display="flex" alignItems="center">
						<Typography variant="h6">Your page is live!</Typography>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							sx={{ marginLeft: 2 }}
							onClick={() => {
								const eventLink = `${window.location.protocol}//${window.location.host}/events/${id}/${name}`;
								try {
									navigator.clipboard.writeText(eventLink);
									showSnackbar({
										message: 'Event link copied to clipboard',
										type: 'success',
									});
								} catch (e) {
									showSnackbar({
										message: 'failed copying to clipboard',
										type: 'error',
									});
								}
							}}>
							<ContentCopy />
						</IconButton>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="open event page"
							component={Link}
							target="_blank"
							to={`/events/${id}/${name}`}>
							<Launch />
						</IconButton>
					</Box>
				)}
			</Box>
			<Box display="flex">
				<Box flex={1}>
					<Box>
						<Typography variant="h6" component="span">
							Starts{' '}
						</Typography>
						<Typography variant="h6" component="span">
							{startText}
						</Typography>
					</Box>
					<Box>
						<Typography variant="h6" component="span">
							Ends{' '}
						</Typography>
						<Typography variant="h6" component="span">
							{endText}
						</Typography>
					</Box>
				</Box>
				<Box flex={1}>
					<Typography variant="p">Venue</Typography>
					<Typography variant="h6">{venue.name}</Typography>
				</Box>
			</Box>
		</DashCard>
	);
};
