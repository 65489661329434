import React from 'react';
import {
	Card,
	CardContent,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Payment } from '@mui/icons-material';

import { formatUSD } from '../../utils/format';

const LoadingRow = () => (
	<TableRow>
		<TableCell>
			<Skeleton variant="rectangular" width="100%" height={20} />
		</TableCell>
		<TableCell>
			<Skeleton variant="rectangular" width="100%" height={20} />
		</TableCell>
		<TableCell>
			<Skeleton variant="rectangular" width="100%" height={20} />
		</TableCell>
		<TableCell>
			<Skeleton variant="rectangular" width="100%" height={20} />
		</TableCell>
		<TableCell>
			<Skeleton variant="rectangular" width="100%" height={20} />
		</TableCell>
	</TableRow>
);

export default ({ invoices }) => {
	const tableContent = invoices
		? invoices.map(invoice => {
				const createdDate = dayjs(invoice.createdAt).format('ddd MMM DD YYYY');
				return (
					<TableRow key={invoice.id} hover onClick={() => {}}>
						<TableCell>{invoice.name}</TableCell>
						<TableCell>{invoice.status}</TableCell>
						<TableCell>{createdDate}</TableCell>
						<TableCell>{formatUSD(invoice.amount)}</TableCell>
						<TableCell>
							<a href={invoice.url} target="_blank" rel="noreferrer">
								<Payment />
							</a>
						</TableCell>
					</TableRow>
				);
		  })
		: Array.from({ length: 3 }, (_, i) => <LoadingRow id={i} />);

	return (
		<Card>
			<CardContent>
				<Typography variant="h3">Invoices</Typography>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Created</TableCell>
								<TableCell>Total</TableCell>
								<TableCell>Link</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{tableContent}</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
};
